// DO NOT EDIT WITHOUT EDITING CORRESPONDING SERVER FILE.
export enum Font {
  Manrope = 1,
  RobotoSlab = 2,
  PTSerif = 3,
  Rubik = 4,
}

export const BRAND_FONT = Font.Manrope;

const DEFAULT_FONT = Font.Manrope;

export function parseFont(rawNumber: number | null | undefined) {
  if (!rawNumber) {
    return DEFAULT_FONT;
  }
  if (Object.values(Font).includes(rawNumber)) {
    return rawNumber;
  }
  return DEFAULT_FONT;
}
