import "./RestaurantMenuPageVideoPromo.css";

import { ForwardedRef, SyntheticEvent, forwardRef, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { Icon } from "@iconify/react";

const TRANSITION_DURATION = 250;

interface RestaurantMenuPageVideoPromoProps {
  shown: boolean;
  onClick: () => void;
}

export function RestaurantMenuPageVideoPromo(
  props: RestaurantMenuPageVideoPromoProps
) {
  function onClick(event: SyntheticEvent) {
    props.onClick();

    event.stopPropagation();
    event.preventDefault();
  }

  const backgroundRef = useRef(null);
  const promoRef = useRef(null);
  const highlightRef = useRef(null);

  return (
    <>
      <CSSTransition
        nodeRef={backgroundRef}
        in={props.shown}
        appear={true}
        mountOnEnter
        unmountOnExit
        timeout={TRANSITION_DURATION}
        classNames="fade-in"
      >
        <div
          ref={backgroundRef}
          className="restaurant-menu-page-video-promo-background"
          onClick={onClick}
        />
      </CSSTransition>
      <CSSTransition
        nodeRef={promoRef}
        in={props.shown}
        appear={true}
        mountOnEnter
        unmountOnExit
        timeout={TRANSITION_DURATION}
        classNames="fade-in"
      >
        <div ref={promoRef} className="restaurant-menu-page-video-promo">
          <Arrow />
          <p className="restaurant-menu-page-video-promo-title">
            Tap to view{" "}
            <span className="restaurant-menu-page-video-promo-video-text">
              videos
              <Underline />
            </span>{" "}
            of these items
          </p>
          <p className="restaurant-menu-page-video-promo-subtitle">
            Tap anywhere to dismiss
          </p>
        </div>
      </CSSTransition>
      <CSSTransition
        nodeRef={highlightRef}
        in={props.shown}
        appear={true}
        mountOnEnter
        unmountOnExit
        timeout={TRANSITION_DURATION}
        classNames="fade-in"
      >
        <Highlight ref={highlightRef} />
      </CSSTransition>
    </>
  );
}

function Arrow() {
  return (
    <svg
      className="restaurant-menu-page-video-promo-icon"
      width="94"
      height="43"
      viewBox="0 0 94 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M82.3839 16.8073C81.9534 17.5164 81.0302 17.7447 80.322 17.3172C79.6138 16.8897 79.3887 15.9683 79.8192 15.2591L82.3839 16.8073ZM91.3069 7.95864C92.0401 7.56995 92.9478 7.84756 93.3341 8.57869C93.7204 9.30983 93.4392 10.2176 92.7059 10.6063L91.3069 7.95864ZM79.8192 15.2591C80.5161 14.1114 82.6199 11.8124 86.09 10.3254L87.2645 13.0804C84.3621 14.3242 82.749 16.206 82.3839 16.8073L79.8192 15.2591ZM86.09 10.3254C89.2689 8.96308 90.9157 8.166 91.3069 7.95864L92.7059 10.6063C92.1996 10.8747 90.4582 11.7118 87.2645 13.0804L86.09 10.3254Z"
        fill="#E2FF6D"
      />
      <path
        d="M80.8901 3.23125C80.2112 2.7588 80.0462 1.82454 80.5217 1.14452C80.9971 0.464503 81.9329 0.296233 82.6118 0.76868L80.8901 3.23125ZM92.9687 7.97596C93.6476 8.44841 93.8126 9.38267 93.3371 10.0627C92.8617 10.7427 91.9259 10.911 91.247 10.4385L92.9687 7.97596ZM82.6118 0.76868L92.9687 7.97596L91.247 10.4385L80.8901 3.23125L82.6118 0.76868Z"
        fill="#E2FF6D"
      />
      <path
        d="M3.5 41C3.5 41.8284 2.82843 42.5 2 42.5C1.17157 42.5 0.5 41.8284 0.5 41H3.5ZM89 6.5C89.8284 6.5 90.5 7.17157 90.5 8C90.5 8.82843 89.8284 9.5 89 9.5V6.5ZM0.5 41C0.5 37.1053 0.860493 32.9095 2.54129 28.837C4.23456 24.7343 7.22742 20.8529 12.3325 17.524C22.4479 10.928 40.8614 6.5 74.1309 6.5V9.5C40.964 9.5 23.3121 13.946 13.9711 20.0369C9.34803 23.0515 6.76498 26.4668 5.31439 29.9815C3.85133 33.5264 3.5 37.2686 3.5 41H0.5ZM74.1309 6.5H89V9.5H74.1309V6.5Z"
        fill="#E2FF6D"
      />
    </svg>
  );
}

function Underline() {
  return (
    <svg
      className="restaurant-menu-page-video-promo-underline"
      width="65"
      height="14"
      viewBox="0 0 65 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 0.5C1.17157 0.5 0.5 1.17157 0.5 2C0.5 2.82843 1.17157 3.5 2 3.5V0.5ZM63 2L63.1424 3.49323C63.9395 3.41721 64.5364 2.72853 64.4983 1.92872C64.4603 1.12891 63.8007 0.5 63 0.5V2ZM10.5702 7L10.4278 5.50677C9.63744 5.58215 9.04269 6.26042 9.07122 7.0539C9.09975 7.84738 9.74168 8.48118 10.5355 8.4996L10.5702 7ZM34.291 11.7778L34.5054 13.2624L34.516 13.2608L34.291 11.7778ZM32.5377 10.5154C31.7178 10.6338 31.1491 11.3945 31.2675 12.2144C31.3859 13.0343 32.1465 13.603 32.9664 13.4846L32.5377 10.5154ZM44.4459 7.78592L44.4111 9.28551L44.4459 7.78592ZM2 3.5H63V0.5H2V3.5ZM62.8576 0.506775L10.4278 5.50677L10.7127 8.49323L63.1424 3.49323L62.8576 0.506775ZM10.5355 8.4996L44.4111 9.28551L44.4806 6.28632L10.605 5.5004L10.5355 8.4996ZM44.3757 8.73085L34.066 10.2947L34.516 13.2608L44.8256 11.6969L44.3757 8.73085ZM34.0766 10.2932L32.5377 10.5154L32.9664 13.4846L34.5054 13.2624L34.0766 10.2932ZM44.4111 9.28551C44.4135 9.28557 44.3846 9.28472 44.3385 9.26451C44.2928 9.24443 44.25 9.21389 44.2158 9.17764C44.1514 9.1093 44.1407 9.0492 44.1392 9.0244C44.1376 8.9996 44.1405 8.93863 44.1957 8.86266C44.225 8.82236 44.2636 8.78663 44.3064 8.7609C44.3495 8.73501 44.3781 8.73049 44.3757 8.73085L44.8256 11.6969C48.114 11.1981 47.8054 6.36346 44.4806 6.28632L44.4111 9.28551Z"
        fill="#E2FF6D"
      />
    </svg>
  );
}

const Highlight = forwardRef(function Highlight(
  props: {},
  ref: ForwardedRef<SVGSVGElement | null>
) {
  return (
    <svg
      ref={ref}
      className="restaurant-menu-page-video-promo-highlight"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.45791 9.65395C4.97441 10.1375 4.97441 10.9214 5.45791 11.4049C5.94142 11.8884 6.72534 11.8884 7.20884 11.4049L5.45791 9.65395ZM12.0239 6.58982C12.5074 6.10631 12.5074 5.3224 12.0239 4.83889C11.5404 4.35538 10.7565 4.35538 10.273 4.83889L12.0239 6.58982ZM7.20884 11.4049L12.0239 6.58982L10.273 4.83889L5.45791 9.65395L7.20884 11.4049Z"
        fill="#E2FF6D"
      />
      <path
        d="M0.761905 8.80952C0.761905 9.4933 1.31622 10.0476 2 10.0476C2.68378 10.0476 3.2381 9.4933 3.2381 8.80952H0.761905ZM3.2381 2C3.2381 1.31622 2.68378 0.761905 2 0.761905C1.31622 0.761905 0.761905 1.31622 0.761905 2H3.2381ZM3.2381 8.80952V2H0.761905L0.761905 8.80952H3.2381Z"
        fill="#E2FF6D"
      />
      <path
        d="M8.19048 13.7619C7.5067 13.7619 6.95238 14.3162 6.95238 15C6.95238 15.6838 7.5067 16.2381 8.19048 16.2381L8.19048 13.7619ZM15 16.2381C15.6838 16.2381 16.2381 15.6838 16.2381 15C16.2381 14.3162 15.6838 13.7619 15 13.7619L15 16.2381ZM8.19048 16.2381L15 16.2381L15 13.7619L8.19048 13.7619L8.19048 16.2381Z"
        fill="#E2FF6D"
      />
    </svg>
  );
});
