import { Font, parseFont } from "./Font";
import { MediaURL } from "./MediaURL";
import { Price } from "./Price";
import { RestaurantMenu } from "./RestaurantMenu";
import { RestaurantMenuFilter } from "./RestaurantMenuFilter";
import { RestaurantMenuItem } from "./RestaurantMenuItem";
import { RestaurantMenuSection } from "./RestaurantMenuSection";
import { RestaurantMenuSubsection } from "./RestaurantMenuSubsection";
import {
  RestaurantColor,
  RestaurantColorTheme,
  RestaurantMetadata,
} from "./RestaurantMetadata";
import { GQLColor, GQLRestaurantQuery } from "../generated/graphql";
import { filterNull } from "../utils/Array";

export class Restaurant {
  constructor(
    readonly id: string,
    readonly metadata: RestaurantMetadata | null,
    readonly title: string,
    readonly description: string,
    readonly blurb: string,
    readonly hours: string,
    readonly qrCodeURL: string,
    readonly videoURLs: MediaURL[],
    readonly menus: RestaurantMenu[],
    readonly filters: RestaurantMenuFilter[]
  ) {}

  static fromGraphQL(
    query: GQLRestaurantQuery,
    fullRestaurantID: string
  ): Restaurant | null {
    // TODO: Better validation.
    if (
      !query.restaurant?.id ||
      !query.restaurant?.title ||
      !query.restaurant?.description ||
      !query.restaurant?.blurb ||
      query.restaurant?.hours === undefined ||
      query.restaurant?.hours === null ||
      !query.restaurant?.qrCodeURL ||
      !query.restaurant?.menus
    ) {
      return null;
    }
    const restaurantVideoURLs = filterNull(
      (query.restaurant?.videoURLs ?? []).map((url) => {
        if (!url.source || !url.mimeType) {
          return null;
        }
        return {
          source: url.source,
          mimeType: url.mimeType,
        };
      })
    );
    const menus = filterNull(
      query.restaurant.menus.map((menu) => {
        if (!menu.id || !menu.title || !menu.sections) {
          return null;
        }
        const sections = filterNull(
          menu.sections.map((section) => {
            if (!section.id || !section.title || !section.subsections) {
              return null;
            }
            const subsections = filterNull(
              section.subsections.map((subsection) => {
                if (!subsection.id || !subsection.items) {
                  return null;
                }
                const items = filterNull(
                  subsection.items.map((item) => {
                    if (!item.id || !item.title) {
                      return null;
                    }
                    const price = item.price?.totalCents
                      ? Price.fromTotalCents(item.price.totalCents)
                      : null;
                    const thumbnail = (() => {
                      if (
                        !item.thumbnail?.source ||
                        !item.thumbnail?.mimeType
                      ) {
                        return null;
                      }
                      return {
                        source: item.thumbnail.source,
                        mimeType: item.thumbnail.mimeType,
                      };
                    })();
                    const videoFirstFrameURL = (() => {
                      if (
                        !item.videoFirstFrameURL?.source ||
                        !item.videoFirstFrameURL?.mimeType
                      ) {
                        return null;
                      }
                      return {
                        source: item.videoFirstFrameURL.source,
                        mimeType: item.videoFirstFrameURL.mimeType,
                      };
                    })();
                    const videoURLs = filterNull(
                      (item.videoURLs ?? []).map((url) => {
                        if (!url.source || !url.mimeType) {
                          return null;
                        }
                        return {
                          source: url.source,
                          mimeType: url.mimeType,
                        };
                      })
                    );
                    const matchingFilters = filterNull(
                      (item.matchingFilters ?? []).map((filter) => {
                        if (!filter.id || !filter.title) {
                          return null;
                        }
                        return {
                          id: filter.id,
                          title: filter.title,
                        };
                      })
                    );

                    // HACK: Hard-coded NYC-only item.
                    if (
                      item.id === "1173" &&
                      fullRestaurantID === "carrot-express-47db76e8"
                    ) {
                      return new RestaurantMenuItem(
                        item.id,
                        "Epic Tuna Melt",
                        "toasted sourdough bread spread with smashed avocado, topped with albacore white tuna salad, tomato & jack cheese",
                        price,
                        item.addonText ? item.addonText : null,
                        thumbnail,
                        videoFirstFrameURL,
                        videoURLs,
                        matchingFilters
                      );
                    }

                    return new RestaurantMenuItem(
                      item.id,
                      item.title,
                      item.subtitle ?? null,
                      price,
                      item.addonText ? item.addonText : null,
                      thumbnail,
                      videoFirstFrameURL,
                      videoURLs,
                      matchingFilters
                    );
                  })
                );
                if (items.length === 0) {
                  return null;
                }
                return new RestaurantMenuSubsection(
                  subsection.id,
                  subsection.title ?? null,
                  items
                );
              })
            );
            if (subsections.length === 0) {
              return null;
            }
            return new RestaurantMenuSection(
              section.id,
              section.title,
              subsections
            );
          })
        );
        if (sections.length === 0) {
          return null;
        }
        return new RestaurantMenu(menu.id, menu.title, sections);
      })
    );
    if (menus.length === 0) {
      return null;
    }
    const filters = filterNull(
      (query.restaurant?.filters ?? []).map((filter) => {
        if (!filter.id || !filter.title) {
          return null;
        }
        return {
          id: filter.id,
          title: filter.title,
        };
      })
    );
    const metadata: RestaurantMetadata | null = (() => {
      if (!query.restaurant.metadata) {
        return null;
      }
      const metadata = query.restaurant.metadata;
      const font = parseFont(metadata.font);

      const parseColor: (
        gqlColor: GQLColor | undefined | null
      ) => RestaurantColor | null = (color) => {
        if (!color) {
          return null;
        }
        if (color.red === undefined || color.red === null) {
          return null;
        }
        if (color.green === undefined || color.green === null) {
          return null;
        }
        if (color.blue === undefined || color.blue === null) {
          return null;
        }
        return new RestaurantColor(color.red, color.green, color.blue);
      };

      const backgroundColor = parseColor(metadata.backgroundColor);
      const surfaceColor = parseColor(metadata.surfaceColor);
      const borderColor = parseColor(metadata.borderColor);
      const headerBackgroundColor = parseColor(metadata.headerBackgroundColor);
      const restaurantTitleColor = parseColor(metadata.restaurantTitleColor);
      const searchFilterButtonColor = parseColor(
        metadata.searchFilterButtonColor
      );
      const sectionBarTextColor = parseColor(metadata.sectionBarTextColor);
      const sectionTitleColor = parseColor(metadata.sectionTitleColor);
      const subsectionTitleColor = parseColor(metadata.subsectionTitleColor);
      const itemTitleColor = parseColor(metadata.itemTitleColor);
      const itemPriceColor = parseColor(metadata.itemPriceColor);
      const itemDescriptionColor = parseColor(metadata.itemDescriptionColor);
      const playButtonBackgroundColor = parseColor(
        metadata.playButtonBackgroundColor
      );

      // All colors must parse or none will be applied!
      let colorTheme: RestaurantColorTheme | null;
      if (
        !backgroundColor ||
        !surfaceColor ||
        !borderColor ||
        !headerBackgroundColor ||
        !restaurantTitleColor ||
        !searchFilterButtonColor ||
        !sectionBarTextColor ||
        !sectionTitleColor ||
        !subsectionTitleColor ||
        !itemTitleColor ||
        !itemPriceColor ||
        !itemDescriptionColor ||
        !playButtonBackgroundColor
      ) {
        if (
          metadata.backgroundColor ||
          metadata.surfaceColor ||
          metadata.borderColor ||
          metadata.headerBackgroundColor ||
          metadata.restaurantTitleColor ||
          metadata.searchFilterButtonColor ||
          metadata.sectionBarTextColor ||
          metadata.sectionTitleColor ||
          metadata.subsectionTitleColor ||
          metadata.itemTitleColor ||
          metadata.itemPriceColor ||
          metadata.itemDescriptionColor ||
          metadata.playButtonBackgroundColor
        ) {
          console.error("Unable to parse all colors despite some being set!");
        }
        colorTheme = null;
      } else {
        colorTheme = new RestaurantColorTheme(
          backgroundColor,
          surfaceColor,
          borderColor,
          headerBackgroundColor,
          restaurantTitleColor,
          searchFilterButtonColor,
          sectionBarTextColor,
          sectionTitleColor,
          subsectionTitleColor,
          itemTitleColor,
          itemPriceColor,
          itemDescriptionColor,
          playButtonBackgroundColor
        );
      }

      return new RestaurantMetadata(font, colorTheme);
    })();
    return new Restaurant(
      query.restaurant.id,
      metadata,
      query.restaurant.title,
      query.restaurant.description,
      query.restaurant.blurb,
      query.restaurant.hours,
      query.restaurant.qrCodeURL,
      restaurantVideoURLs,
      menus,
      filters
    );
  }
}

export function dummyRestaurant(): Restaurant {
  return new Restaurant(
    "1",
    new RestaurantMetadata(
      /*font=*/ Font.Rubik,
      new RestaurantColorTheme(
        /*backgroundColor=*/ new RestaurantColor(255, 255, 255),
        /*surfaceColor=*/ new RestaurantColor(235, 235, 235),
        /*borderColor=*/ new RestaurantColor(40, 40, 40),
        /*headerBackgroundColor=*/ new RestaurantColor(100, 255, 100),
        /*restaurantTitleColor=*/ new RestaurantColor(10, 40, 40),
        /*searchFilterButtonColor=*/ new RestaurantColor(80, 80, 80),
        /*sectionBarTextColor=*/ new RestaurantColor(50, 50, 50),
        /*sectionTitleColor=*/ new RestaurantColor(0, 0, 0),
        /*subsectionTitleColor=*/ new RestaurantColor(20, 20, 20),
        /*itemTitleColor=*/ new RestaurantColor(40, 40, 40),
        /*itemPriceColor=*/ new RestaurantColor(60, 60, 60),
        /*itemDescriptionColor=*/ new RestaurantColor(80, 80, 80),
        /*playButtonBackgroundColor=*/ new RestaurantColor(255, 0, 0)
      )
    ),
    "Papito",
    "Greek · Mediterranean",
    "There’s a reason we put freshly baked flatbread at the center of the " +
      "menu, and keep it coming at every table at Ovelia. In Greece, bakeries " +
      "and flour are subsidized, so bread is available to the poor." +
      "\n\n" +
      "Welcome to our home!",
    "Mon-Tue: Closed\nWed-Thu: 5-11PM\nFriday: 5PM-12AM",
    "http://localhost:8080/qr/1",
    [],
    [
      new RestaurantMenu("1", "All-day", [
        new RestaurantMenuSection("1", "Appetizers", [
          new RestaurantMenuSubsection("1", null, [
            new RestaurantMenuItem(
              "1",
              "Chips",
              "Yummy tortilla chips",
              Price.fromTotalCents(700),
              "Add mozzarella cheese for $4",
              new MediaURL(
                "https://menutest1storage.blob.core.windows.net/firstframe/82dc21af-e593-4130-a984-0f563aed74cc/firstframe.jpg",
                "video/mp4; codecs=avc1"
              ),
              new MediaURL(
                "https://menutest1storage.blob.core.windows.net/firstframe/82dc21af-e593-4130-a984-0f563aed74cc/firstframe.jpg",
                "video/mp4; codecs=avc1"
              ),
              [
                new MediaURL(
                  "https://menutest1storage.blob.core.windows.net/transcoded/82dc21af-e593-4130-a984-0f563aed74cc/h264.mp4",
                  "video/mp4; codecs=avc1"
                ),
              ],
              []
            ),
            new RestaurantMenuItem(
              "2",
              "Beans",
              "Just some beans",
              Price.fromTotalCents(300),
              "Add mozzarella cheese for $4",
              null,
              null,
              [],
              []
            ),
            new RestaurantMenuItem(
              "3",
              "Beans",
              null,
              Price.fromTotalCents(300),
              "Add mozzarella cheese for $4",
              null,
              null,
              [],
              []
            ),
          ]),
          new RestaurantMenuSubsection("2", "Yummy ones", [
            new RestaurantMenuItem(
              "4",
              "Beans",
              null,
              Price.fromTotalCents(300),
              null,
              null,
              null,
              [],
              []
            ),
            new RestaurantMenuItem(
              "5",
              "Beans",
              "Just some beans",
              Price.fromTotalCents(300),
              null,
              null,
              null,
              [],
              []
            ),
            new RestaurantMenuItem(
              "6",
              "Beans",
              "Just some beans",
              Price.fromTotalCents(300),
              null,
              null,
              null,
              [],
              []
            ),
            new RestaurantMenuItem(
              "7",
              "Beans",
              "Just some beans",
              Price.fromTotalCents(300),
              null,
              null,
              null,
              [],
              []
            ),
          ]),
          new RestaurantMenuSubsection("3", "Not so yummy", [
            new RestaurantMenuItem(
              "8",
              "Beans",
              "Just some beans",
              Price.fromTotalCents(300),
              null,
              null,
              null,
              [],
              []
            ),
            new RestaurantMenuItem(
              "9",
              "Beans",
              "Just some beans",
              Price.fromTotalCents(300),
              null,
              null,
              null,
              [],
              []
            ),
            new RestaurantMenuItem(
              "10",
              "Beans",
              "Just some beans",
              Price.fromTotalCents(300),
              null,
              null,
              null,
              [],
              []
            ),
            new RestaurantMenuItem(
              "11",
              "Beans",
              "Just some beans",
              Price.fromTotalCents(300),
              null,
              null,
              null,
              [],
              []
            ),
          ]),
        ]),
        new RestaurantMenuSection("2", "Mains", [
          new RestaurantMenuSubsection("2", "Delicious", [
            new RestaurantMenuItem(
              "12",
              "Burrito",
              "Absolutely delicious",
              Price.fromTotalCents(900),
              null,
              null,
              null,
              [],
              []
            ),
            new RestaurantMenuItem(
              "13",
              "Quesadilla",
              "Who doesn't love cheese?",
              Price.fromTotalCents(800),
              null,
              null,
              null,
              [
                new MediaURL(
                  "https://menutest1storage.blob.core.windows.net/transcoded/82dc21af-e593-4130-a984-0f563aed74cc/h264.mp4",
                  "video/mp4; codecs=avc1"
                ),
              ],
              []
            ),
          ]),
        ]),
        new RestaurantMenuSection("3", "A Long Section Title", [
          new RestaurantMenuSubsection("3", null, [
            new RestaurantMenuItem(
              "14",
              "Burrito",
              "Absolutely delicious",
              Price.fromTotalCents(900),
              null,
              null,
              null,
              [],
              []
            ),
          ]),
        ]),
        new RestaurantMenuSection("4", "Section Four", [
          new RestaurantMenuSubsection("4", null, [
            new RestaurantMenuItem(
              "15",
              "Burrito",
              "Absolutely delicious",
              Price.fromTotalCents(900),
              null,
              null,
              null,
              [],
              []
            ),
          ]),
        ]),
        new RestaurantMenuSection("5", "Section Five", [
          new RestaurantMenuSubsection("5", null, [
            new RestaurantMenuItem(
              "16",
              "Burrito",
              "Absolutely delicious",
              Price.fromTotalCents(900),
              null,
              null,
              null,
              [],
              []
            ),
          ]),
        ]),
      ]),
      new RestaurantMenu("2", "Drinks", [
        new RestaurantMenuSection("6", "Wines", [
          new RestaurantMenuSubsection("6", null, [
            new RestaurantMenuItem(
              "17",
              "Red wine",
              "By bottle or not",
              Price.fromTotalCents(700),
              null,
              null,
              null,
              [],
              []
            ),
          ]),
        ]),
        new RestaurantMenuSection("7", "Beers", [
          new RestaurantMenuSubsection("7", null, [
            new RestaurantMenuItem(
              "18",
              "IPA",
              "Beloved by all",
              Price.fromTotalCents(700),
              null,
              null,
              null,
              [],
              []
            ),
          ]),
        ]),
      ]),
    ],
    [
      new RestaurantMenuFilter("1", "Vegan"),
      new RestaurantMenuFilter("2", "Vegetarian"),
    ]
  );
}
