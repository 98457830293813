import "./RestaurantMenuItemFooterCartButton.css";

import { Icon } from "@iconify/react";

import { Cart } from "../../../state/Cart";

export interface RestaurantMenuItemFooterProps {
  cart: Cart;
  onShowCart: () => void;
}

export function RestaurantMenuItemFooterCartButton(
  props: RestaurantMenuItemFooterProps
) {
  return (
    <div
      onClick={props.onShowCart}
      className="restaurant-menu-item-footer-cart-button"
    >
      <Icon
        className="restaurant-menu-item-footer-cart-button-icon"
        icon="icon-park-outline:transaction-order"
      />
      <div className="restaurant-menu-item-footer-cart-button-badge">
        {props.cart.totalQuantity}
      </div>
    </div>
  );
}
