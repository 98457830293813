import { RestaurantMenu } from "../../../models/RestaurantMenu";
import { RestaurantMenuItem } from "../../../models/RestaurantMenuItem";
import { RestaurantMenuSection } from "../../../models/RestaurantMenuSection";
import { devAssertionFailure } from "../../../utils/Assert";

const ITEM_ROW_PADDING = 33;
const ITEM_TITLE_HEIGHT = 18;
const ITEM_SUBTITLE_HEIGHT = 20;
const ITEM_SUBTITLE_PADDING = 12;
const ITEM_ADDON_BUTTON_HEIGHT = 20;
const DOCUMENT_PADDING = 130;
const TITLE_WIDTH_PER_CHARACTER = 10;
const SUBTITLE_WIDTH_PER_CHARACTER = 8;
const TITLE_SIDE_MARGINS = 98;
const SUBTITLE_SIDE_MARGINS = 44;
const SUBTITLE_SIDE_MARGINS_WITH_ADDON = 106;

export class RestaurantMenuItemPageViewModel {
  constructor(readonly sections: RestaurantMenuSectionViewModels[]) {}

  findItemIndex(item: RestaurantMenuItem | null): [number, number] {
    if (!item) {
      return [0, 0];
    }
    for (let i = 0; i < this.sections.length; i++) {
      const sectionViewModels = this.sections[i];
      for (let j = 0; j < sectionViewModels.viewModels.length; j++) {
        const viewModel = sectionViewModels.viewModels[j];
        switch (viewModel.type) {
          case "video":
            if (viewModel.item.id === item.id) {
              return [i, j];
            }
            break;
          case "items":
            if (
              viewModel
                .items()
                .map((nextItem) => nextItem.id)
                .includes(item.id)
            ) {
              return [i, j];
            }
            break;
        }
      }
    }
    return [0, 0];
  }

  findBestItemIndexForSection(sectionIndex: number): [number, number] {
    if (sectionIndex < 0) {
      devAssertionFailure(`Unexpected section index ${sectionIndex}`);
      return this.findBestItemIndexForSection(0);
    }
    for (let i = sectionIndex; i < this.sections.length; i++) {
      if (this.sections[i].viewModels.length > 0) {
        return [i, 0];
      }
    }
    for (let i = sectionIndex - 1; i >= 0; i--) {
      if (this.sections[i].viewModels.length > 0) {
        return [i, 0];
      }
    }
    devAssertionFailure(
      `Failed to find best item index for section ${sectionIndex}`
    );
    return [0, 0];
  }

  findNextItemIndex(
    sectionIndex: number,
    itemIndex: number
  ): [number, number] | null {
    if (itemIndex < this.sections[sectionIndex].viewModels.length - 1) {
      return [sectionIndex, itemIndex + 1];
    }
    for (let i = sectionIndex + 1; i < this.sections.length; i++) {
      if (this.sections[i].viewModels.length > 0) {
        return [i, 0];
      }
    }
    return null;
  }

  findPreviousItemIndex(
    sectionIndex: number,
    itemIndex: number
  ): [number, number] | null {
    const currentSectionLength = this.sections[sectionIndex].viewModels.length;
    if (itemIndex > 0 && itemIndex < currentSectionLength) {
      return [sectionIndex, itemIndex - 1];
    } else if (itemIndex >= currentSectionLength) {
      return [sectionIndex, currentSectionLength - 1];
    }
    for (let i = sectionIndex - 1; i >= 0; i--) {
      const sectionLength = this.sections[i].viewModels.length;
      if (sectionLength > 0) {
        return [i, sectionLength - 1];
      }
    }
    return null;
  }

  findNextSectionItemIndex(
    sectionIndex: number,
    itemIndex: number,
    lastViewedItemsMap: Map<number, number>
  ): [number, number] | null {
    for (let i = sectionIndex + 1; i < this.sections.length; i++) {
      if (this.sections[i].viewModels.length > 0) {
        const index = lastViewedItemsMap.get(i);
        if (index) {
          return [i, index];
        } else {
          return [i, 0];
        }
      }
    }
    return null;
  }

  findPreviousSectionItemIndex(
    sectionIndex: number,
    itemIndex: number,
    lastViewedItemsMap: Map<number, number>
  ): [number, number] | null {
    for (let i = sectionIndex - 1; i >= 0; i--) {
      if (this.sections[i].viewModels.length > 0) {
        const index = lastViewedItemsMap.get(i);
        if (index) {
          return [i, index];
        } else {
          return [i, 0];
        }
      }
    }
    return null;
  }
}

export class RestaurantMenuSectionViewModels {
  constructor(readonly viewModels: RestaurantMenuItemViewModel[]) {}
}

export type RestaurantMenuItemViewModel =
  | RestaurantMenuItemViewModelVideo
  | RestaurantMenuItemViewModelItems;

export class RestaurantMenuItemViewModelVideo {
  type: "video" = "video";

  constructor(readonly item: RestaurantMenuItem) {}

  get firstItem(): RestaurantMenuItem {
    return this.item;
  }
}

export class RestaurantMenuItemViewModelItems {
  type: "items" = "items";

  constructor(
    readonly itemOne: RestaurantMenuItem,
    readonly nextItems: RestaurantMenuItem[],
    readonly headerText: string | null,
    readonly footerText: string | null
  ) {}

  items(): RestaurantMenuItem[] {
    return [this.itemOne].concat(this.nextItems);
  }

  get firstItem(): RestaurantMenuItem {
    return this.itemOne;
  }
}

interface IntermediateSectionResult {
  index: number;
  title: string;
  videoItems: RestaurantMenuItem[];
  nonVideoItems: RestaurantMenuItem[];
}

export function generateViewModel(
  menu: RestaurantMenu,
  searchedItems: RestaurantMenuItem[] | null
): RestaurantMenuItemPageViewModel {
  if (searchedItems) {
    const videoItems = searchedItems.filter(
      (item) => item.videoURLs.length > 0
    );
    const nonVideoItems = searchedItems.filter(
      (item) => item.videoURLs.length === 0
    );
    const videoViewModels: RestaurantMenuItemViewModel[] = videoItems.map(
      (item) => new RestaurantMenuItemViewModelVideo(item)
    );
    const nonVideoIntermediateResults = partitionNonVideoItems(nonVideoItems);
    const nonVideoViewModels: RestaurantMenuItemViewModelItems[] = [];
    for (let i = 0; i < nonVideoIntermediateResults.length; i++) {
      const footerText =
        i === nonVideoIntermediateResults.length - 1 ? null : "Swipe for more";
      const viewModel = new RestaurantMenuItemViewModelItems(
        nonVideoIntermediateResults[i].itemOne,
        nonVideoIntermediateResults[i].nextItems,
        null,
        footerText
      );
      nonVideoViewModels.push(viewModel);
    }

    const section = new RestaurantMenuSectionViewModels(
      videoViewModels.concat(nonVideoViewModels)
    );
    return new RestaurantMenuItemPageViewModel([section]);
  }

  const intermediateResults: IntermediateSectionResult[] = menu.sections.map(
    (section, index) => {
      const videoItems = section.items.filter(
        (item) => item.videoURLs.length > 0
      );
      const nonVideoItems = section.items.filter(
        (item) => item.videoURLs.length === 0
      );
      return {
        index,
        title: section.title,
        videoItems,
        nonVideoItems,
      };
    }
  );

  const finalResults: RestaurantMenuSectionViewModels[] =
    intermediateResults.map((result, index) => {
      const videoViewModels: RestaurantMenuItemViewModel[] =
        result.videoItems.map(
          (item) => new RestaurantMenuItemViewModelVideo(item)
        );

      const nonVideoIntermediateResults = partitionNonVideoItems(
        result.nonVideoItems
      );
      const nonVideoViewModels: RestaurantMenuItemViewModelItems[] = [];
      for (let i = 0; i < nonVideoIntermediateResults.length; i++) {
        const headerText =
          i === 0
            ? `${result.title} Without Videos`
            : `${result.title} Without Videos Cont.`;

        let footerText = null;
        if (i < nonVideoIntermediateResults.length - 1) {
          footerText = "Swipe for more";
        } else {
          for (let j = index + 1; j < intermediateResults.length; j++) {
            const nextResult = intermediateResults[j];
            if (
              nextResult.videoItems.length > 0 ||
              nextResult.nonVideoItems.length > 0
            ) {
              footerText = `Swipe for ${nextResult.title.toLocaleLowerCase()}`;
              break;
            }
          }
        }

        const viewModel = new RestaurantMenuItemViewModelItems(
          nonVideoIntermediateResults[i].itemOne,
          nonVideoIntermediateResults[i].nextItems,
          headerText,
          footerText
        );
        nonVideoViewModels.push(viewModel);
      }

      return new RestaurantMenuSectionViewModels(
        videoViewModels.concat(nonVideoViewModels)
      );
    });

  return new RestaurantMenuItemPageViewModel(finalResults);
}

interface IntermediateNonVideoPartitionResult {
  itemOne: RestaurantMenuItem;
  nextItems: RestaurantMenuItem[];
}

function partitionNonVideoItems(
  nonVideoItems: RestaurantMenuItem[]
): IntermediateNonVideoPartitionResult[] {
  const results: IntermediateNonVideoPartitionResult[] = [];
  let nonVideoStartIndex = 0;
  while (nonVideoStartIndex < nonVideoItems.length) {
    const itemOne = nonVideoItems[nonVideoStartIndex];
    nonVideoStartIndex++;

    // TODO: Avoid doing manual math on size calculation.
    const availableHeight =
      document.documentElement.clientHeight - DOCUMENT_PADDING;
    const itemOneHeight = itemHeight(itemOne);
    let currentHeight = itemOneHeight;

    const nextItems: RestaurantMenuItem[] = [];
    while (nonVideoStartIndex < nonVideoItems.length) {
      const item = nonVideoItems[nonVideoStartIndex];

      let height = itemHeight(item);
      const requiredHeight = currentHeight + height;
      if (requiredHeight > availableHeight) {
        break;
      }

      currentHeight = requiredHeight;
      nextItems.push(item);
      nonVideoStartIndex++;
    }
    const result = {
      itemOne,
      nextItems,
    };
    results.push(result);
  }
  return results;
}

/** Returns the full height and then the collapsed height if necessary. */
function itemHeight(item: RestaurantMenuItem): number {
  let height = ITEM_ROW_PADDING;

  // Add title height.
  const titleWidth = document.documentElement.clientWidth - TITLE_SIDE_MARGINS;
  const numberOfTitleLines = Math.ceil(
    (item.title.length * TITLE_WIDTH_PER_CHARACTER) / titleWidth
  );
  height += ITEM_TITLE_HEIGHT * numberOfTitleLines;

  // Add subtitle height.
  height += ITEM_SUBTITLE_PADDING;
  const hasSubtitle = item.subtitle && item.subtitle.length > 0;
  const hasAddons = item.addonText && item.addonText.length > 0;
  if (hasSubtitle) {
    const subtitleWidth =
      document.documentElement.clientWidth -
      (hasAddons ? SUBTITLE_SIDE_MARGINS_WITH_ADDON : SUBTITLE_SIDE_MARGINS);
    const numberOfSubitleLines = Math.ceil(
      (item.subtitle.length * SUBTITLE_WIDTH_PER_CHARACTER) / subtitleWidth
    );
    height += ITEM_SUBTITLE_HEIGHT * numberOfSubitleLines;
  } else if (hasAddons) {
    height += ITEM_ADDON_BUTTON_HEIGHT;
  }

  return height;
}
