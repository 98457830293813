import { Suspense, lazy, useCallback, useEffect, useState } from "react";
import {
  Route,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { useRestaurantStyle } from "./RestaurantStyle";
import { RestaurantMenuItemPage } from "./item/RestaurantMenuItemPage";
import { RestaurantLandingPage } from "./landing/RestaurantLandingPage";
import { RestaurantMenuPage } from "./menu/RestaurantMenuPage";
import { RestaurantPageChildParams } from "./RestaurantPageChildParams";
import { LoadingPage } from "../loading/LoadingPage";
import { AnimatedRoutes } from "../../components/animated_routes/AnimatedRoutes";
import { ImmediateTransition } from "../../components/animated_routes/ImmediateTransition";
import { ErrorView } from "../../components/error_view/ErrorView";
import { BRAND_FONT } from "../../models/Font";
import { Price } from "../../models/Price";
import { Restaurant } from "../../models/Restaurant";
import { RestaurantMenu } from "../../models/RestaurantMenu";
import { RestaurantMenuFilter } from "../../models/RestaurantMenuFilter";
import { RestaurantMenuItem } from "../../models/RestaurantMenuItem";
import { RestaurantMenuSection } from "../../models/RestaurantMenuSection";
import { NotFoundPage } from "../not_found/NotFoundPage";
import { RestaurantService } from "../../services/RestaurantService";
import {
  AnalyticsEvent,
  AnalyticsCartEventSource,
} from "../../services/analytics/AnalyticsEvent";
import { useAnalyticsService } from "../../services/analytics/AnalyticsServiceContext";
import { SearchService } from "../../services/SearchService";
import { useCart } from "../../state/Cart";
import { savedFilterIDsSetting } from "../../state/local_storage/FilterStorage";
import {
  INFINITE_SCROLL_FLAG,
  useFeatureFlag,
} from "../../state/feature_flags/FeatureFlags";
import { devAssertionFailure } from "../../utils/Assert";
import { useFont } from "../../utils/font/LoadFont";

// Lazily load unnecessary and large routes.
const QRPage = lazy(() => import("./qr/QRPage"));

const ITEM_ID_SEARCH_PARAM_KEY = "itemID";

const SEARCH_SEARCH_URL_PARAM_KEY = "search";

const restaurantService = new RestaurantService();

const searchService = new SearchService();

export function RestaurantPage(props: {}) {
  const [isInfiniteScroll] = useFeatureFlag(INFINITE_SCROLL_FLAG);

  const { restaurantID } = useParams<"restaurantID">();
  if (!restaurantID) {
    throw new Error("Required route param restaurantID not set!");
  }

  const analyticsService = useAnalyticsService();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(
    () => {
      analyticsService.logEvent(AnalyticsEvent.viewRestaurant(restaurantID));
    },
    [restaurantID] // Only log a view for each new restaurant.
  );

  const [cart, addCartItem, removeCartItem] = useCart();
  const [restaurant, setRestaurant] = useState<Restaurant | null>(null);
  const [menuIndex, setMenuIndex] = useState(0);
  const [sectionIndex, setSectionIndex] = useState(0);
  const [filters, setFilters] = useState<RestaurantMenuFilter[]>([]);
  const [error, setError] = useState<Error | null>(null);

  // Track last viewed item for video promo purposes.
  const [currentItemSectionID, setCurrentItemSectionID] = useState<
    string | null
  >(null);
  const [lastViewedItemSectionID, setLastViewedItemSectionID] = useState<
    string | null
  >(null);

  // Always update the analytics service with the restaurant ID and title as soon as possible.
  useEffect(() => {
    analyticsService.setRestaurant(
      restaurant?.id ?? null,
      restaurant?.title ?? null
    );
    return () => analyticsService.setRestaurant(null, null);
  }, [analyticsService, restaurant?.id, restaurant?.title]);

  const menu: RestaurantMenu | null =
    restaurant !== null
      ? restaurant.menus[menuIndex].applyingFilters(
          filters,
          /*filterMenuSections=*/ isInfiniteScroll
        )
      : null;
  function setMenu(menu: RestaurantMenu, isFirstFetch: boolean = false) {
    if (!restaurant) {
      return;
    }
    const newMenuIndex = restaurant.menus.findIndex(
      (restaurantMenu) => restaurantMenu.id === menu.id
    );
    setMenuIndex(newMenuIndex >= 0 ? newMenuIndex : 0);
    setSectionIndex(0);

    if (!isFirstFetch) {
      analyticsService.logEvent(AnalyticsEvent.updateMenu(menu.id));
    }
  }

  const section: RestaurantMenuSection | null =
    menu !== null ? menu.sections[sectionIndex] : null;
  function setSection(section: RestaurantMenuSection) {
    if (!menu) {
      return;
    }
    const newSectionIndex = menu.sections.findIndex(
      (menuSection) => menuSection.id === section.id
    );
    setSectionIndex(newSectionIndex >= 0 ? newSectionIndex : 0);
  }

  const search = searchParams.get(SEARCH_SEARCH_URL_PARAM_KEY);
  const searchedItems =
    menu !== null && search !== null
      ? searchService.searchMenu(menu, search)
      : null;

  function updateSearch(newSearch: string, replace: boolean = true) {
    setSearchParams({ search: newSearch }, { replace });
  }

  function exitSearch() {
    setSearchParams({});
  }

  function updateFilter(filter: RestaurantMenuFilter) {
    const [savedFilterIDs, storeSavedFilterIDs] = savedFilterIDsSetting();
    const isIncluded =
      filters.findIndex((currentFilter) => currentFilter.id === filter.id) !==
      -1;
    if (isIncluded) {
      storeSavedFilterIDs(
        savedFilterIDs.filter((filterID) => filterID !== filter.id)
      );
      setFilters(
        filters.filter((currentFilter) => currentFilter.id !== filter.id)
      );

      analyticsService.logEvent(
        AnalyticsEvent.removeFilter(filter.id, filter.title)
      );
    } else {
      storeSavedFilterIDs(savedFilterIDs.concat(filter.id));
      setFilters(filters.concat(filter));

      analyticsService.logEvent(
        AnalyticsEvent.addFilter(filter.id, filter.title)
      );
    }
  }

  function setItem(itemToSet: RestaurantMenuItem | null, replace: boolean) {
    const params = new URLSearchParams();
    if (search !== null) {
      params.set(SEARCH_SEARCH_URL_PARAM_KEY, search);
    }
    if (!itemToSet) {
      navigate(`menu?${params.toString()}`, { replace });
      return;
    }
    params.set(ITEM_ID_SEARCH_PARAM_KEY, itemToSet.id);
    navigate(`menu/item?${params.toString()}`, { replace });
  }

  useEffect(() => {
    restaurantService
      .fetchRestaurant(restaurantID)
      .then((fetchedRestaurant) => {
        setRestaurant(fetchedRestaurant);
        if (fetchedRestaurant.menus.length > 0) {
          const firstMenu = fetchedRestaurant.menus[0];
          setMenu(firstMenu, true);
        }

        // Retrieve and set the filters from storage.
        const [savedFilterIDs] = savedFilterIDsSetting();
        setFilters(
          fetchedRestaurant.filters.filter((filter) =>
            savedFilterIDs.includes(filter.id)
          )
        );

        document.title = `${fetchedRestaurant.title} Menu`;
      })
      .catch((error) => {
        console.error(error);
        setError(error);
      });

    // Cleanup by nil-ing all relevant variables.
    return () => {
      setRestaurant(null);
      setMenuIndex(0);
      setSectionIndex(0);
      setItem(null, false);
      setFilters([]);

      document.title = "Restaurant Menu";
      // TODO: Cancel the request if necessary.
    };
  }, [restaurantID]);

  // Apply the restaurant's colors.
  useRestaurantStyle(restaurant?.metadata);

  // Apply the restaurant's font.
  useFont(restaurant?.metadata?.font ?? BRAND_FONT);

  const onAddCartItem = useCallback(
    (item: RestaurantMenuItem, source: AnalyticsCartEventSource) => {
      addCartItem(item);

      const cartTotal = cart.totalPrice.add(
        item.price ?? Price.fromTotalCents(0)
      ).totalCents;
      analyticsService.logEvent(
        AnalyticsEvent.addToCart(item, cartTotal, source)
      );
    },
    [cart]
  );

  const onRemoveCartItem = useCallback(
    (item: RestaurantMenuItem, source: AnalyticsCartEventSource) => {
      removeCartItem(item);

      const cartTotal = cart.totalPrice.subtract(
        item.price ?? Price.fromTotalCents(0)
      ).totalCents;
      analyticsService.logEvent(
        AnalyticsEvent.removeFromCart(item, cartTotal, source)
      );
    },
    [cart]
  );

  const [menuPageScroll, setMenuPageScroll] = useState(0);

  const itemID = searchParams.get(ITEM_ID_SEARCH_PARAM_KEY);
  let item: RestaurantMenuItem | null = null;
  if (itemID && menu) {
    const itemIndex = findItemIndex(itemID, menu);
    if (itemIndex !== null) {
      const newSection = menu.sections[itemIndex[0]];
      item = newSection.items[itemIndex[1]];

      // if (itemIndex[0] !== sectionIndex) {
      //   TODO: Navigate to appropriate item.
      //   setSection(newSection);
      // }
    } else {
      devAssertionFailure(`Unable to find item with ID: ${itemID}`);
    }
  }

  useEffect(() => {
    if (item && menu) {
      const itemIndex = findItemIndex(item.id, menu);
      if (itemIndex !== null) {
        const newSection = menu.sections[itemIndex[0]];

        // Track the current section ID and set the previous section ID.
        setLastViewedItemSectionID(currentItemSectionID);
        setCurrentItemSectionID(newSection.id);
      } else {
        devAssertionFailure(`Unable to find item with ID: ${itemID}`);
      }
    }
  }, [item]);

  if (error) {
    return (
      <ErrorView errorMessage="An error occurred fetching the restaurant's details." />
    );
  }

  if (!restaurant || !menu) {
    return <LoadingPage />;
  }

  const childParams: RestaurantPageChildParams = {
    cart,
    addCartItem: onAddCartItem,
    removeCartItem: onRemoveCartItem,
    restaurant,
    menu,
    setMenu,
    section,
    setSection,
    item,
    setItem,
    filters,
    updateFilter,
    search,
    searchedItems,
    updateSearch,
    exitSearch,
  };

  return (
    <AnimatedRoutes>
      <Route
        path=""
        element={
          <ImmediateTransition fullPage={false}>
            <RestaurantLandingPage {...childParams} />
          </ImmediateTransition>
        }
      />
      <Route
        path="menu"
        element={
          <ImmediateTransition fullPage={!isInfiniteScroll}>
            <RestaurantMenuPage
              {...childParams}
              scroll={menuPageScroll}
              setScroll={setMenuPageScroll}
              canSelectItem={(item) => item.videoURLs.length > 0}
            />
          </ImmediateTransition>
        }
      />
      <Route
        path="menu/item"
        element={
          <ImmediateTransition fullPage={true}>
            <RestaurantMenuItemPage
              {...childParams}
              shouldShownSwipeLeftPrompt={
                lastViewedItemSectionID !== null &&
                lastViewedItemSectionID !== currentItemSectionID
              }
            />
          </ImmediateTransition>
        }
      />
      <Route
        path="qr"
        element={
          <ImmediateTransition fullPage={false}>
            <Suspense fallback={<LoadingPage />}>
              <QRPage {...childParams} />
            </Suspense>
          </ImmediateTransition>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </AnimatedRoutes>
  );
}

function findItemIndex(
  itemID: string,
  menu: RestaurantMenu
): [number, number] | null {
  for (
    let sectionIndex = 0;
    sectionIndex < menu.sections.length;
    sectionIndex++
  ) {
    const section = menu.sections[sectionIndex];
    for (let itemIndex = 0; itemIndex < section.items.length; itemIndex++) {
      const sectionItem = section.items[itemIndex];
      if (itemID === sectionItem.id) {
        return [sectionIndex, itemIndex];
      }
    }
  }
  return null;
}
