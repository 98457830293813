import { ReactNode } from "react";
import { Routes, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

export interface AnimatedRoutesProps {
  children: ReactNode;
}

export function AnimatedRoutes(props: AnimatedRoutesProps) {
  const location = useLocation();

  return <Routes location={location}>{props.children}</Routes>;
}
