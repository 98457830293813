// Doesn't matter, we'll just check for existence.
const BOOLEAN_TRUE = "true";
const BOOLEAN_FALSE = "false";

export function booleanLocalStorageSetting(
  name: string,
  defaultValue: boolean = false
): [boolean, (newValue: boolean) => void] {
  const [value, setValue] = booleanOrUndefinedLocalStorageSetting(name);
  return [value === undefined ? defaultValue : value, setValue];
}

export function booleanOrUndefinedLocalStorageSetting(
  name: string
): [boolean | undefined, (newValue: boolean | undefined) => void] {
  const storage = retrieveLocalStorage();
  if (storage === null) {
    return [undefined, (newValue) => {}];
  }

  function setValue(newValue: boolean | undefined) {
    const storage = retrieveLocalStorage();
    if (storage === null) {
      return;
    }
    if (newValue === true) {
      storage.setItem(name, BOOLEAN_TRUE);
    } else if (newValue === false) {
      storage.setItem(name, BOOLEAN_FALSE);
    } else {
      storage.removeItem(name);
    }
  }
  const value = storage.getItem(name);
  return [
    value === BOOLEAN_TRUE ? true : value === BOOLEAN_FALSE ? false : undefined,
    setValue,
  ];
}

export function objectLocalStorageSetting<T>(
  name: string,
  defaultValue: T
): [T, (newValue: T) => void] {
  const storage = retrieveLocalStorage();
  if (storage === null) {
    return [defaultValue, (newValue) => {}];
  }

  const value = storage.getItem(name);
  function setValue(newValue: T) {
    const storage = retrieveLocalStorage();
    if (storage === null) {
      return;
    }
    if (newValue !== null && newValue !== undefined) {
      storage.setItem(name, JSON.stringify(newValue));
    } else {
      storage.removeItem(name);
    }
  }
  let returnValue = defaultValue;
  try {
    if (value) {
      returnValue = JSON.parse(value);
    }
  } catch {}

  return [returnValue, setValue];
}

export function retrieveLocalStorage(): Storage | null {
  return localStorageAvailable() ? window.localStorage : null;
}

export function localStorageAvailable() {
  return storageAvailable("localStorage");
}

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API.
function storageAvailable(type: string): boolean {
  var storage;
  try {
    storage = window[type as any] as unknown as Storage;
    var x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      (e instanceof DOMException &&
        // everything except Firefox
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === "QuotaExceededError" ||
          // Firefox
          e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage &&
        storage.length !== 0) ??
      true
    );
  }
}
