import "./ScrollingSectionSelectorBar.css";

import classNames from "classnames";
import { ReactNode, useLayoutEffect, useRef, useState } from "react";
import { useSpring, animated } from "@react-spring/web";

import { useScrollFade } from "../scroll_fade/ScrollFade";
import { RestaurantMenuSection } from "../../models/RestaurantMenuSection";
import {
  INFINITE_SCROLL_FLAG,
  useFeatureFlag,
} from "../../state/feature_flags/FeatureFlags";

export interface ScrollingSectionSelectorBarProps {
  sections: RestaurantMenuSection[];
  selectedSection: RestaurantMenuSection;
  onSectionSelected: (item: RestaurantMenuSection) => void;
  // From -1 to 1, how far offset (by number of items) the bar should be laid out.
  offset?: number;
  showScrollFade: boolean;
  widthReduction?: number;
}

export function ScrollingSectionSelectorBar(
  props: ScrollingSectionSelectorBarProps
) {
  const [infiniteScrollOn] = useFeatureFlag(INFINITE_SCROLL_FLAG);

  const [hasAnimated, setHasAnimated] = useState(false);
  const [animation, api] = useSpring(() => ({ left: "0px", width: "0px" }));

  const previousTargetRef = useRef<HTMLButtonElement | null>(null);
  const selectedTargetRef = useRef<HTMLButtonElement | null>(null);
  const nextTargetRef = useRef<HTMLButtonElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    const current = selectedTargetRef.current;
    if (!current) {
      return;
    }

    // Fall back to current if there is no previous ref, and averaging will leave the bar in position.
    const previous = previousTargetRef.current ?? current;
    const next = nextTargetRef.current ?? current;

    const offset = props.offset ?? 0;
    const left =
      offset >= 0
        ? current.offsetLeft + offset * (next.offsetLeft - current.offsetLeft)
        : current.offsetLeft +
          offset * (current.offsetLeft - previous.offsetLeft);
    const width =
      offset >= 0
        ? current.offsetWidth +
          offset * (next.offsetWidth - current.offsetWidth)
        : current.offsetWidth +
          offset * (current.offsetWidth - previous.offsetWidth);

    // Animate the bar.
    api.start({
      left: `${left}px`,
      width: `${width}px`,
      immediate: !hasAnimated,
      onChange: ({ value }) => {
        // On animating the bar, also scroll the container smoothly if needed.
        const left = parseFloat(value.left);
        const width = parseFloat(value.width);

        // Also scroll such that the selected section and bar are in view.
        const container = containerRef.current;
        if (!container) {
          return;
        }
        const paddingToAdd = 60;
        const currentScrollBounds = {
          left: container.scrollLeft,
          right: container.scrollLeft + container.offsetWidth,
        };
        // offset <= 0 and >= checks prevent "bouncing" back and forth if the width is larger than the screen.
        if (offset <= 0 && left < currentScrollBounds.left + paddingToAdd) {
          const scrollLeft = Math.round(left - paddingToAdd);
          container.scrollLeft = scrollLeft;
        } else if (infiniteScrollOn) {
          const scrollLeft = Math.round(left - paddingToAdd);
          container.scrollLeft = scrollLeft;
        } else if (
          !infiniteScrollOn &&
          offset >= 0 &&
          left + width > currentScrollBounds.right - paddingToAdd
        ) {
          const scrollLeft = Math.round(
            left + width - container.offsetWidth + paddingToAdd
          );
          container.scrollLeft = scrollLeft;
        }
      },
    });
    setHasAnimated(true);
  }, [
    props.selectedSection,
    props.offset,
    ...props.sections.map((section) => section.id),
  ]);

  const selectedSectionIndex = props.sections.findIndex(
    (section) => section.id === props.selectedSection.id
  );

  const [onScroll, , rightFade, , leftFade] = useScrollFade(
    containerRef,
    true,
    props.sections.map((section) => section.id)
  );

  const widthReduction = props.widthReduction ?? 0;
  return (
    <div
      className="scrolling-menu-bar-container-container"
      style={{ width: `calc(100% - ${widthReduction}px` }}
    >
      <div
        ref={containerRef}
        className="scrolling-menu-bar-container"
        onScroll={props.showScrollFade ? onScroll : undefined}
      >
        <div className="scrolling-menu-bar">
          {props.sections.map((section, index) => (
            <div
              key={section.id}
              className="scrolling-menu-bar-button-container"
            >
              <button
                ref={
                  index === selectedSectionIndex
                    ? selectedTargetRef
                    : index === selectedSectionIndex - 1
                    ? previousTargetRef
                    : index === selectedSectionIndex + 1
                    ? nextTargetRef
                    : null
                }
                className={classNames("scrolling-menu-bar-button", {
                  selected: section.id === props.selectedSection.id,
                })}
                onClick={() => props.onSectionSelected(section)}
              >
                {section.title}
              </button>
            </div>
          ))}
        </div>
        <animated.div
          className="scrolling-menu-bar-indicator"
          style={animation}
        />
      </div>

      {props.showScrollFade && leftFade}
      {props.showScrollFade && rightFade}
    </div>
  );
}
