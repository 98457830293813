import { Navigate, useParams } from "react-router";

import { AnalyticsEvent } from "../../services/analytics/AnalyticsEvent";
import { useAnalyticsService } from "../../services/analytics/AnalyticsServiceContext";

export function QRRedirectPage(props: {}) {
  const { restaurantID } = useParams<"restaurantID">();

  const analyticsService = useAnalyticsService();
  if (restaurantID) {
    analyticsService.logEvent(AnalyticsEvent.openQR(restaurantID));
  }

  return <Navigate to={`/restaurant/${restaurantID}/menu`} />;
}
