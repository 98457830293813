import { RestaurantMenu } from "../models/RestaurantMenu";
import { RestaurantMenuItem } from "../models/RestaurantMenuItem";

export const ITEM_DISPLAY_THRESHOLD = 0.5;

// Simple - only allow fuzzy skips over 2 characters total.
export const MAX_FUZZY_SKIPS = 2;

export class SearchService {
  searchMenu(menu: RestaurantMenu, searchString: string): RestaurantMenuItem[] {
    const allItems: RestaurantMenuItem[] = menu.allItems();

    // Special case: empty string matches everything.
    if (searchString === "") {
      return allItems;
    }

    return allItems
      .map(
        (item) =>
          [
            item,
            textMatcher(
              item.title.toLocaleLowerCase(),
              searchString.toLocaleLowerCase()
            ),
            textMatcher(
              item.subtitle?.toLocaleLowerCase(),
              searchString.toLocaleLowerCase()
            ),
          ] as [RestaurantMenuItem, number, number]
      )
      .filter(
        (tuple) =>
          tuple[1] > ITEM_DISPLAY_THRESHOLD || tuple[2] > ITEM_DISPLAY_THRESHOLD
      )
      .sort(function (first, second) {
        const firstMax = Math.max(first[1], first[2]);
        const secondMax = Math.max(second[1], second[2]);
        if (firstMax > secondMax) {
          return -1;
        }
        if (secondMax > firstMax) {
          return 1;
        }

        // If maxes are equal, prioritize title scores.
        if (first[1] === second[1]) {
          // If titles match, compare subtitles.
          return first[2] > second[2] ? -1 : 1;
        }
        // Otherwise either the subtitles match, or one has a title score that matches the other's subtitle score.
        // In either case, prefer the higher title score.
        const titleDiff = first[1] - second[1];
        if (titleDiff === 0) {
          return 0;
        }
        return titleDiff > 0 ? -1 : 1;
      })
      .map((tuple) => tuple[0]);
  }
}

export function textMatcher(text: string | undefined, search: string): number {
  if (!text) {
    return 0;
  }

  const match = fuzzySearch(text, search);
  if (match === undefined) {
    return 0;
  }

  // Ensure all matches match, sorted by skips.
  return (
    1 - (match.skips / (MAX_FUZZY_SKIPS + 1)) * (1 - ITEM_DISPLAY_THRESHOLD)
  );
}

export interface FuzzySearchResult {
  skips: number;
}

interface IntermediateMatch {
  beginning: number;
  numberMatched: number;
}

interface CompleteMatch {
  beginning: number;
  skips: number;
}

/**
 * Fuzzy search for `search` in `text`.
 *
 * Returns whether there was a match, and how
 */
export function fuzzySearch(
  text: string,
  search: string
): FuzzySearchResult | undefined {
  const searchLength = search.length;
  if (searchLength === 0) {
    return { skips: 0 };
  }

  let matches: IntermediateMatch[] = [];
  let completeMatches: CompleteMatch[] = [];

  const textLength = text.length;
  for (let textIndex = 0; textIndex < textLength; textIndex++) {
    const matchesLength = matches.length;
    // Iterate in reverse to allow deletion by index during.
    for (let matchIndex = matchesLength - 1; matchIndex >= 0; matchIndex--) {
      const previousMatch = matches[matchIndex];

      // Extend the old match if possible.
      if (text[textIndex] === search[previousMatch.numberMatched]) {
        matches[matchIndex] = {
          beginning: previousMatch.beginning,
          numberMatched: previousMatch.numberMatched + 1,
        };
      }

      // Check if it's done, or exceeds the max skips and remove it if so.
      const newMatch = matches[matchIndex];
      if (newMatch.numberMatched === searchLength) {
        const skips = textIndex - newMatch.beginning - searchLength + 1;
        const completeMatch = { beginning: newMatch.beginning, skips };
        completeMatches.push(completeMatch);
        matches.splice(matchIndex, 1);
      } else if (
        textIndex - newMatch.beginning - newMatch.numberMatched >=
        MAX_FUZZY_SKIPS
      ) {
        matches.splice(matchIndex, 1);
      }
    }

    // Check for the beginning of a new match.
    if (text[textIndex] === search[0]) {
      // Special case - return immediately if search length is 1.
      if (searchLength === 1) {
        return { skips: 0 };
      }

      // New match, append it.
      const match: IntermediateMatch = {
        beginning: textIndex,
        numberMatched: 1,
      };
      matches.push(match);
    }
  }

  const completeMatchesLength = completeMatches.length;
  if (completeMatchesLength === 0) {
    return undefined;
  }

  let minimumSkips = completeMatches[0].skips;
  for (let matchIndex = 1; matchIndex < completeMatchesLength; matchIndex++) {
    if (completeMatches[matchIndex].skips < minimumSkips) {
      minimumSkips = completeMatches[matchIndex].skips;
    }
  }
  return { skips: minimumSkips };
}
