// https://stackoverflow.com/a/46700791
function notNull<T>(value: T | null | undefined): value is T {
  if (value === null || value === undefined) {
    return false;
  }
  // Used to verify object is type.
  // eslint-disable-next-line
  const newValue: T = value;
  return true;
}

export function filterNull<T>(array: (T | null | undefined)[]): T[] {
  return array.filter(notNull);
}

export function removeDuplicates<T, U>(
  array: T[],
  keyFunction: (element: T) => U
): T[] {
  const keySet = new Set<U>();
  const outArray = [];
  for (const element of array) {
    const key = keyFunction(element);
    if (keySet.has(key)) {
      continue;
    }
    keySet.add(key);
    outArray.push(element);
  }
  return outArray;
}
