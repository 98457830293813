import "./RestaurantMenuItemNonVideoRow.css";

import { Icon } from "@iconify/react";

import { RestaurantMenuItem } from "../../../models/RestaurantMenuItem";

interface RestaurantMenuItemNonVideoRowProps {
  item: RestaurantMenuItem;
  onAddonsTapped: ((item: RestaurantMenuItem) => void) | null;
}

export function RestaurantMenuItemNonVideoRow(
  props: RestaurantMenuItemNonVideoRowProps
) {
  const showsSubtitle = props.item.subtitle && props.item.subtitle.length > 0;
  const showsAddons = props.onAddonsTapped !== null;
  return (
    <div className="restaurant-menu-item-nonvideo-row">
      <div className="restaurant-menu-item-nonvideo-title-container">
        <div className="restaurant-menu-item-nonvideo-title">
          {props.item.title}
        </div>
        {props.item.price && (
          <div className="restaurant-menu-item-nonvideo-price">
            {props.item.price.formattedText}
          </div>
        )}
      </div>
      {(showsSubtitle || showsAddons) && (
        <div className="restaurant-menu-item-nonvideo-subtitle-container">
          <div className="restaurant-menu-item-nonvideo-subtitle lowercase">
            {props.item.subtitle}
          </div>
          {showsAddons && (
            <button onClick={() => props.onAddonsTapped?.(props.item)}>
              <Icon
                className="restaurant-menu-item-nonvideo-addon-button"
                icon="bi:three-dots"
              />
            </button>
          )}
        </div>
      )}
    </div>
  );
}
