import "./BottomSheet.css";

import classNames from "classnames";
import { ReactNode, useRef } from "react";
import { CSSTransition } from "react-transition-group";

import { Scrim } from "./Scrim";

const MODAL_TRANSITION_DURATION = 500;

export interface BottomSheetProps {
  in: boolean;
  expanded?: boolean;
  onExpand?: () => void;
  dismiss: () => void;
  children: ReactNode;
}

export function BottomSheet(props: BottomSheetProps) {
  const expanded = props.expanded === undefined ? true : props.expanded;
  const scrimNodeRef = useRef(null);
  const bottomSheetNodeRef = useRef(null);
  return (
    <>
      <CSSTransition
        nodeRef={scrimNodeRef}
        in={props.in && expanded}
        mountOnEnter
        unmountOnExit
        timeout={MODAL_TRANSITION_DURATION}
        classNames="fade-in"
      >
        <Scrim
          ref={scrimNodeRef}
          className="bottom-sheet-scrim"
          onClick={props.dismiss}
        />
      </CSSTransition>
      <CSSTransition
        nodeRef={bottomSheetNodeRef}
        in={props.in}
        mountOnEnter
        unmountOnExit
        timeout={MODAL_TRANSITION_DURATION}
        classNames="bottom-sheet-slide-up"
      >
        <div
          ref={bottomSheetNodeRef}
          onClick={() => props.onExpand?.()}
          className={classNames("bottom-sheet-modal", {
            "bottom-sheet-modal-expanded": expanded,
          })}
        >
          <div className="bottom-sheet-modal-grab-bar" />
          {props.children}
        </div>
      </CSSTransition>
    </>
  );
}
