import "./VideoIcon.css";

import classNames from "classnames";
import { Icon } from "@iconify/react";

export interface VideoIconProps {
  useHighZIndex?: boolean;
}

export function VideoIcon(props: VideoIconProps) {
  return (
    <Icon
      icon="bi:play-fill"
      className={classNames("item-video-icon", {
        "item-video-icon-elevated": props.useHighZIndex ?? false,
      })}
    />
  );
}
