import "./RestaurantMenuItemListSubsectionHeader.css";

interface RestaurantMenuItemListSubsectionHeaderProps {
  title: string;
  paddingTopOverride: number | null;
}

export function RestaurantMenuItemListSubsectionHeader(
  props: RestaurantMenuItemListSubsectionHeaderProps
) {
  const style = {
    ...(props.paddingTopOverride !== null
      ? { paddingTop: props.paddingTopOverride }
      : {}),
  };
  return (
    <div className="restaurant-menu-item-list-subsection-header" style={style}>
      {props.title}
    </div>
  );
}
