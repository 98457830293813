import "./RestaurantMenuSectionHeader.css";

import classNames from "classnames";
import { ForwardedRef, forwardRef } from "react";

interface RestaurantMenuSectionHeaderProps {
  title: string;
  usesBottomPadding: boolean;
}

export const RestaurantMenuSectionHeader = forwardRef(
  function RestaurantMenuSectionHeader(
    props: RestaurantMenuSectionHeaderProps,
    ref: ForwardedRef<HTMLDivElement>
  ) {
    return (
      <h2
        ref={ref}
        className={classNames("restaurant-menu-section-header", {
          "no-bottom-padding": !props.usesBottomPadding,
        })}
      >
        {props.title}
      </h2>
    );
  }
);
