import { Font } from "./Font";
import { devAssertionFailure } from "../utils/Assert";

export class RestaurantColor {
  /* From 0 to 255 assumed. */
  constructor(
    readonly red: number,
    readonly green: number,
    readonly blue: number
  ) {
    if (red < 0 || red > 255) {
      devAssertionFailure(`Unexpected out of bounds red color! ${red}`);
      this.red = 255;
    }
    if (green < 0 || green > 255) {
      devAssertionFailure(`Unexpected out of bounds green color! ${green}`);
      this.green = 255;
    }
    if (blue < 0 || blue > 255) {
      devAssertionFailure(`Unexpected out of bounds blue color! ${blue}`);
      this.red = 255;
    }
  }

  get cssPropertyString(): string {
    return `${this.red}, ${this.green}, ${this.blue}`;
  }
}

export class RestaurantColorTheme {
  constructor(
    readonly backgroundColor: RestaurantColor,
    readonly surfaceColor: RestaurantColor,
    readonly borderColor: RestaurantColor,
    readonly headerBackgroundColor: RestaurantColor,
    readonly restaurantTitleColor: RestaurantColor,
    readonly searchFilterButtonColor: RestaurantColor,
    readonly sectionBarTextColor: RestaurantColor,
    readonly sectionTitleColor: RestaurantColor,
    readonly subsectionTitleColor: RestaurantColor,
    readonly itemTitleColor: RestaurantColor,
    readonly itemPriceColor: RestaurantColor,
    readonly itemDescriptionColor: RestaurantColor,
    readonly playButtonBackgroundColor: RestaurantColor
  ) {}
}

export class RestaurantMetadata {
  constructor(
    readonly font: Font,
    readonly colorTheme: RestaurantColorTheme | null
  ) {}
}
