import { Price } from "./Price";
import { RestaurantMenuFilter } from "./RestaurantMenuFilter";
import { MediaURL } from "./MediaURL";

export class RestaurantMenuItem {
  constructor(
    readonly id: string,
    readonly title: string,
    readonly subtitle: string | null,
    readonly price: Price | null,
    readonly addonText: string | null,
    readonly thumbnail: MediaURL | null,
    readonly videoFirstFrameURL: MediaURL | null,
    readonly videoURLs: MediaURL[],
    readonly matchingFilters: RestaurantMenuFilter[]
  ) {}
}
