import "./ImmediateTransition.css";

import classNames from "classnames";
import { ReactNode } from "react";

export interface ImmediateTransitionProps {
  fullPage: boolean;
  children: ReactNode;
}

export function ImmediateTransition(props: ImmediateTransitionProps) {
  return (
    <div
      className={classNames("immediate-transition", {
        "full-page": props.fullPage,
      })}
    >
      {props.children}
    </div>
  );
}
