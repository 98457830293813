import { RestaurantMenuItem } from "./RestaurantMenuItem";

export class RestaurantMenuFilter {
  constructor(readonly id: string, readonly title: string) {}
}

export function filterMenuItems(
  filters: RestaurantMenuFilter[],
  items: RestaurantMenuItem[]
) {
  if (filters.length === 0) {
    // If there are no filters, always return all objects.
    return items;
  }

  const vegetarianFilterIndex = filters.findIndex(
    (filter) => filter.title == "Vegetarian"
  );
  const vegetarianFilterID =
    vegetarianFilterIndex !== -1 ? filters[vegetarianFilterIndex].id : null;

  return items.filter((item) => {
    let itemFilterIDsSet = new Set<string>();
    for (const itemFilter of item.matchingFilters) {
      itemFilterIDsSet.add(itemFilter.id);

      // TODO: HACK: Properly include vegetarian in vegan items instead of
      // hard-coding.
      if (itemFilter.title === "Vegan" && vegetarianFilterID) {
        itemFilterIDsSet.add(vegetarianFilterID);
      }
    }
    for (const filter of filters) {
      if (!itemFilterIDsSet.has(filter.id)) {
        return false;
      }
    }
    return true;
  });
}
