import { AnalyticsEvent } from "./AnalyticsEvent";
import { AnalyticsService } from "./AnalyticsService";

export class GoogleAnalyticsService implements AnalyticsService {
  restaurantID: string | null = null;
  restaurantTitle: string | null = null;

  initialize() {}

  logEvent(event: AnalyticsEvent) {
    const properties: { [key: string]: any } = { ...event.properties };
    if (this.restaurantID) {
      properties.restaurantID = this.restaurantID;
    }
    if (this.restaurantTitle) {
      properties.restaurantTitle = this.restaurantTitle;
    }

    gtag("event", event.action, properties);

    // TODO: Hook up recommended events.
    // See https://developers.google.com/tag-platform/gtagjs/reference/.
  }

  setRestaurant(id: string | null, title: string | null) {
    this.restaurantID = id;
    this.restaurantTitle = title;
  }
}
