import "./RestaurantFilterPage.css";

import { RestaurantFilterItemRow } from "./RestaurantFilterItemRow";
import { RestaurantPageChildParams } from "../RestaurantPageChildParams";
import { RestaurantMenuFilter } from "../../../models/RestaurantMenuFilter";

export interface RestaurantFilterPageProps extends RestaurantPageChildParams {
  onFilterClick: (filter: RestaurantMenuFilter) => void;
  onDismissFilters: () => void;
}

export function RestaurantFilterPage(props: RestaurantFilterPageProps) {
  return (
    <>
      <div className="restaurant-filters-header-container">
        <div className="restaurant-filters-title">Filters</div>
        <button
          className="restaurant-filters-close"
          onClick={props.onDismissFilters}
        >
          {props.filters.length > 0 ? "Done" : "Close"}
        </button>
      </div>
      <div className="restaurant-filters-container">
        {props.restaurant.filters.map((filter) => (
          <RestaurantFilterItemRow
            key={filter.id}
            filter={filter}
            onFilterClick={props.onFilterClick}
            isSelected={props.filters.some(
              (appliedFilter) => appliedFilter.id === filter.id
            )}
          />
        ))}
      </div>
    </>
  );
}
