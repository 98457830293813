import { useEffect } from "react";

import { setCSSVars } from "../css_var/SetCSSVars";
import { Font } from "../../models/Font";

export function useFont(font: Font) {
  useEffect(() => {
    loadFont(font);
    return setCSSVars(
      new Map([
        ["--font-family", fontFamily(font)],
        ...Array.from(extraVars(font).entries()),
      ])
    );
  }, [font]);
}

function fontFamily(font: Font): string {
  switch (font) {
    case Font.Manrope:
      return '"Manrope", sans-serif';
    case Font.RobotoSlab:
      return '"Roboto Slab", sans-serif';
    case Font.PTSerif:
      return '"PT Serif", sans-serif';
    case Font.Rubik:
      return '"Rubik", sans-serif';
  }
}

function extraVars(font: Font): Map<string, string> {
  // Default: --header-weight: bold, --price-weight: 600.
  switch (font) {
    case Font.Manrope:
      return new Map();
    case Font.RobotoSlab:
      return new Map();
    case Font.PTSerif:
      return new Map([["--price-weight", "600"]]);
    case Font.Rubik:
      return new Map([["--header-weight", "600"]]);
  }
}

export function loadFont(font: Font) {
  // Handle pre-loaded CSS.
  // See https://github.com/filamentgroup/loadCSS/issues/312.
  setTimeout(() => {
    const elementID = fontElementID(font);
    const link = document.getElementById(elementID);
    if (!link) {
      console.error("Unable to find font link to load!");
      return;
    }

    if ((link as HTMLLinkElement).media === "print") {
      (link as HTMLLinkElement).media = "all";
      (link as HTMLLinkElement).onload = null;
    }
  }, 0);
}

function fontElementID(font: Font): string {
  switch (font) {
    case Font.Manrope:
      return "manrope-font-css-preload";
    case Font.RobotoSlab:
      return "roboto-slab-font-css-preload";
    case Font.PTSerif:
      return "pt-serif-font-css-preload";
    case Font.Rubik:
      return "rubik-font-css-preload";
  }
}
