import { useSpring, config } from "@react-spring/web";

export function useSmoothScroll(): (top: number) => void {
  // Polyfill smooth scrolling, basically.
  const [, api] = useSpring(() => ({
    top: 0,
    // Experimentally determined to provide a fast but smooth experience on iOS.
    config: {
      mass: 1,
      tension: 240,
      friction: 40,
    },
  }));

  const isSupported = supportsSmoothScrolling();
  if (isSupported) {
    return (top) => {
      document.documentElement.scrollTo({
        top,
        behavior: "smooth",
      });
    };
  }

  return (top) => {
    api.start({
      top,
      onChange: ({ value }) => {
        document.documentElement.scrollTo({ top: value.top });
      },
    });
  };
}

function supportsSmoothScrolling() {
  const body = document.body;
  const scrollSave = body.style.scrollBehavior;
  body.style.scrollBehavior = "smooth";
  const hasSmooth = getComputedStyle(body).scrollBehavior === "smooth";
  body.style.scrollBehavior = scrollSave;
  return hasSmooth;
}
