import "./RestaurantMenuItemListFooter.css";

import { Icon } from "@iconify/react";

export interface RestaurantMenuItemListFooterProps {
  footerText: string;
}

export function RestaurantMenuItemListFooter(
  props: RestaurantMenuItemListFooterProps
) {
  return (
    <div className="restaurant-menu-item-list-footer">
      <div className="restaurant-menu-item-list-footer-icon-container">
        <Icon
          icon="bi:chevron-compact-up"
          className="restaurant-menu-item-list-footer-icon"
        />
      </div>
      <p className="restaurant-menu-item-list-footer-text">
        {props.footerText}
      </p>
    </div>
  );
}
