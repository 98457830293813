import { RestaurantMenuFilter } from "./RestaurantMenuFilter";
import { RestaurantMenuSubsection } from "./RestaurantMenuSubsection";

export class RestaurantMenuSection {
  readonly items = this.subsections.flatMap((subsection) => subsection.items);

  constructor(
    readonly id: string,
    readonly title: string,
    readonly subsections: RestaurantMenuSubsection[]
  ) {}

  applyingFilters(
    filters: RestaurantMenuFilter[],
    filterMenuSections: boolean
  ): RestaurantMenuSection {
    return new RestaurantMenuSection(
      this.id,
      this.title,
      this.subsections
        .map((subsection) => subsection.applyingFilters(filters))
        .filter(
          (subsection) => !filterMenuSections || subsection.items.length > 0
        )
    );
  }
}
