import { GraphQLClient } from "graphql-request";

import { getSdk as getRestaurantSdk } from "../generated/graphql";
import { Restaurant, dummyRestaurant } from "../models/Restaurant";

export class RestaurantService {
  async fetchRestaurant(restaurantID: string): Promise<Restaurant> {
    if (process.env.REACT_APP_USE_DEMO_DATA === "1") {
      return dummyRestaurant();
    }

    const apiURL = process.env.REACT_APP_GRAPHQL_API_URL;
    if (!apiURL) {
      throw new Error("Required configuration not set!");
    }
    const client = new GraphQLClient(apiURL);
    const menuSdk = getRestaurantSdk(client);
    const query = await menuSdk.restaurant({ id: restaurantID });
    const restaurant = Restaurant.fromGraphQL(query, restaurantID);
    if (!restaurant) {
      throw new Error(`Null restaurant in response: ${query.restaurant}`);
    }
    return restaurant;
  }
}
