import "./VideoPlayer.css";

import { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";

import { MediaURL } from "../../models/MediaURL";
import { RestaurantMenuItem } from "../../models/RestaurantMenuItem";
import { AnalyticsEvent } from "../../services/analytics/AnalyticsEvent";
import { useAnalyticsService } from "../../services/analytics/AnalyticsServiceContext";
import { VideoService } from "../../services/video/VideoService";
import { isSafari } from "../../utils/Safari";

class PromiseManager {
  currentPromise = Promise.resolve();

  perform(work: () => Promise<void>) {
    this.currentPromise = this.currentPromise.then(async () => {
      await work();
    });
  }
}

// Overkill, but sync _all_ video work.
const PROMISE_MANAGER = new PromiseManager();

function safelySetMute(videoElement: HTMLVideoElement) {
  // On iOS, it appears as though we're being blocked from playing video
  // even though it's muted. `defaultMuted` sets the muted HTML property.
  // Hopefully this works.
  videoElement.muted = true;
  videoElement.defaultMuted = true;
  videoElement.setAttribute("muted", "");
}

export interface VideoPlayerProps {
  className: string;
  playing: boolean;
  videoURLs: MediaURL[];
  item: RestaurantMenuItem;

  // Note: Callers should assume loading at first.
  onLoaded: () => void;
  onError: () => void;
  onLoading: () => void;

  thumbnail: boolean;
}

export function VideoPlayer(props: VideoPlayerProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  const analyticsService = useAnalyticsService();

  const [hasLoggedVideoError, setHasLoggedVideoError] = useState(false);
  const [hasLoggedVideoManualPlayError, setHasLoggedVideoManualPlayError] =
    useState(false);
  const [hasAutoplayVideoError, setHasAutoplayVideoError] = useState(false);

  function safelyPlay(
    videoElement: HTMLVideoElement,
    fromManualTap: boolean = false
  ) {
    if (hasAutoplayVideoError && fromManualTap) {
      setHasAutoplayVideoError(false);
    }

    PROMISE_MANAGER.perform(async () => {
      try {
        if (videoElement.paused && videoElement.readyState === 4) {
          await videoElement.play();
        }
      } catch (error) {
        if (fromManualTap) {
          // If we attempted to fix it from a manual tap and still got an
          // error, inform the caller.
          props.onError();
        } else {
          setHasAutoplayVideoError(true);
        }

        console.error(error);
        console.error("Error when playing:", {
          itemID: props.item.id,
          itemTitle: props.item.title,
          intendedToBePlaying: props.playing,
          offsetLeft: videoElement.offsetLeft,
          offsetTop: videoElement.offsetTop,
          paused: videoElement.paused,
          readyState: videoElement.readyState,
          src: videoElement.src,
          muted: videoElement.muted,
        });

        if (fromManualTap) {
          if (!hasLoggedVideoManualPlayError) {
            setHasLoggedVideoManualPlayError(true);

            analyticsService.logEvent(
              AnalyticsEvent.videoManualPlayError({
                itemID: props.item.id,
                itemTitle: props.item.title,
                intendedToBePlaying: props.playing,
                offsetLeft: videoElement.offsetLeft,
                offsetTop: videoElement.offsetTop,
                paused: videoElement.paused,
                readyState: videoElement.readyState,
                src: videoElement.src,
                muted: videoElement.muted,
                userAgent: navigator.userAgent,
                errorMessage: `${error}`,
              })
            );
          }
        } else if (!hasLoggedVideoError) {
          setHasLoggedVideoError(true);

          analyticsService.logEvent(
            AnalyticsEvent.videoPromiseError({
              itemID: props.item.id,
              itemTitle: props.item.title,
              intendedToBePlaying: props.playing,
              offsetLeft: videoElement.offsetLeft,
              offsetTop: videoElement.offsetTop,
              paused: videoElement.paused,
              readyState: videoElement.readyState,
              src: videoElement.src,
              muted: videoElement.muted,
              userAgent: navigator.userAgent,
              errorMessage: `${error}`,
            })
          );
        }
      }
    });
  }

  function safelyPause(videoElement: HTMLVideoElement) {
    PROMISE_MANAGER.perform(async () => {
      try {
        if (!videoElement.paused) {
          videoElement.currentTime = 0;
          videoElement.pause();
        }
      } catch (error) {
        // TODO: Verify this is never called.
        // setHasAutoplayVideoError(true);

        console.error(error);
        console.error("Error when pausing:", {
          itemID: props.item.id,
          itemTitle: props.item.title,
          intendedToBePlaying: props.playing,
          offsetLeft: videoElement.offsetLeft,
          offsetTop: videoElement.offsetTop,
          paused: videoElement.paused,
          readyState: videoElement.readyState,
          src: videoElement.src,
          muted: videoElement.muted,
        });

        if (!hasLoggedVideoError) {
          setHasLoggedVideoError(true);

          analyticsService.logEvent(
            AnalyticsEvent.videoPromiseError({
              itemID: props.item.id,
              itemTitle: props.item.title,
              intendedToBePlaying: props.playing,
              offsetLeft: videoElement.offsetLeft,
              offsetTop: videoElement.offsetTop,
              paused: videoElement.paused,
              readyState: videoElement.readyState,
              src: videoElement.src,
              muted: videoElement.muted,
              userAgent: navigator.userAgent,
              errorMessage: `${error}`,
            })
          );
        }
      }
    });
  }

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) {
      return;
    }

    safelySetMute(videoElement);

    if (props.playing) {
      safelyPlay(videoElement);
    } else {
      safelyPause(videoElement);
    }
  }, [props.playing, analyticsService]);

  const [videoBlob, setVideoBlob] = useState<Blob | undefined>(undefined);
  const [videoBlobURL, setVideoBlobURL] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    VideoService.shared
      .loadVideo(
        props.videoURLs,
        analyticsService,
        props.item.id,
        props.item.title,
        props.thumbnail
      )
      .then((response) => {
        const videoElement = videoRef.current;
        if (!videoElement) {
          return;
        }

        props.onLoaded();

        setVideoBlobURL(response[0]);
      })
      .catch((error) => {
        props.onError();
      });

    return () => {
      VideoService.shared.revokeVideoBlob(props.videoURLs, props.thumbnail);
    };
  }, [props.videoURLs]);

  useEffect(() => {
    if (!videoBlobURL) {
      return;
    }
    const videoElement = videoRef.current;
    if (!videoElement) {
      return;
    }
    videoElement.src = videoBlobURL;
    videoElement.load();

    if (props.playing) {
      safelyPlay(videoElement);
    } else {
      safelyPause(videoElement);
    }
  }, [videoBlobURL]);

  return (
    <div className="video-player-container">
      <video
        ref={videoRef}
        playsInline
        preload="auto"
        muted
        loop
        disableRemotePlayback
        className={props.className}
        onClick={() => {
          if (!hasAutoplayVideoError) {
            return;
          }
          const videoElement = videoRef.current;
          if (videoElement) {
            safelyPlay(videoElement, /*fromManualTap=*/ true);
          }
        }}
        onCanPlayThrough={() => {
          const videoElement = videoRef.current;
          if (videoElement && props.playing) {
            safelyPlay(videoElement);
          }
        }}
        onError={(errorEvent) => {
          props.onError();

          if (!hasLoggedVideoError) {
            setHasLoggedVideoError(true);

            analyticsService.logEvent(
              AnalyticsEvent.videoError({
                itemID: props.item.id,
                itemTitle: props.item.title,
                userAgent: navigator.userAgent,
                errorMessage: `${errorEvent}`,
              })
            );
          }
        }}
        // TODO: How to handle onAbort - not an error?
        onPlaying={props.onLoaded}
        // TODO: Verify onStalled, onWaiting, are not necessary now that videos are entirely loaded.
        // TODO: Verify onSuspend is not necessary.
      />
      {hasAutoplayVideoError && (
        <Icon
          className="video-player-play-button-overlay"
          icon="bi:play-circle-fill"
        />
      )}
    </div>
  );
}
