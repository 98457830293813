import { GraphQLClient } from "graphql-request";
import * as Dom from "graphql-request/dist/types.dom";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type GQLColor = {
  __typename?: "Color";
  blue?: Maybe<Scalars["Int"]>;
  green?: Maybe<Scalars["Int"]>;
  red?: Maybe<Scalars["Int"]>;
};

export type GQLItemVideo = {
  __typename?: "ItemVideo";
  itemID?: Maybe<Scalars["ID"]>;
  itemTitle?: Maybe<Scalars["String"]>;
  uniqueKey?: Maybe<Scalars["String"]>;
};

export type GQLMediaUrl = {
  __typename?: "MediaURL";
  mimeType?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
};

export type GQLMenu = {
  __typename?: "Menu";
  id?: Maybe<Scalars["ID"]>;
  sections?: Maybe<Array<GQLMenuSection>>;
  title?: Maybe<Scalars["String"]>;
};

export type GQLMenuFilter = {
  __typename?: "MenuFilter";
  id?: Maybe<Scalars["ID"]>;
  title?: Maybe<Scalars["String"]>;
};

export type GQLMenuItem = {
  __typename?: "MenuItem";
  addonText?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  matchingFilters?: Maybe<Array<GQLMenuFilter>>;
  price?: Maybe<GQLPrice>;
  subtitle?: Maybe<Scalars["String"]>;
  thumbnail?: Maybe<GQLMediaUrl>;
  title?: Maybe<Scalars["String"]>;
  videoFirstFrameURL?: Maybe<GQLMediaUrl>;
  videoURLs?: Maybe<Array<GQLMediaUrl>>;
};

export type GQLMenuSection = {
  __typename?: "MenuSection";
  id?: Maybe<Scalars["ID"]>;
  subsections?: Maybe<Array<GQLMenuSubsection>>;
  title?: Maybe<Scalars["String"]>;
};

export type GQLMenuSubsection = {
  __typename?: "MenuSubsection";
  id?: Maybe<Scalars["ID"]>;
  items?: Maybe<Array<GQLMenuItem>>;
  title?: Maybe<Scalars["String"]>;
};

export type GQLPrice = {
  __typename?: "Price";
  totalCents?: Maybe<Scalars["Int"]>;
};

export type GQLQuery = {
  __typename?: "Query";
  itemVideos?: Maybe<Array<GQLItemVideo>>;
  restaurant?: Maybe<GQLRestaurant>;
};

export type GQLQueryRestaurantArgs = {
  id: Scalars["ID"];
};

export type GQLRestaurant = {
  __typename?: "Restaurant";
  blurb?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  filters?: Maybe<Array<GQLMenuFilter>>;
  hours?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  menus?: Maybe<Array<GQLMenu>>;
  metadata?: Maybe<GQLRestaurantMetadata>;
  qrCodeURL?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  videoURLs?: Maybe<Array<GQLMediaUrl>>;
};

export type GQLRestaurantMetadata = {
  __typename?: "RestaurantMetadata";
  backgroundColor?: Maybe<GQLColor>;
  borderColor?: Maybe<GQLColor>;
  font?: Maybe<Scalars["Int"]>;
  headerBackgroundColor?: Maybe<GQLColor>;
  itemDescriptionColor?: Maybe<GQLColor>;
  itemPriceColor?: Maybe<GQLColor>;
  itemTitleColor?: Maybe<GQLColor>;
  playButtonBackgroundColor?: Maybe<GQLColor>;
  restaurantTitleColor?: Maybe<GQLColor>;
  searchFilterButtonColor?: Maybe<GQLColor>;
  sectionBarTextColor?: Maybe<GQLColor>;
  sectionTitleColor?: Maybe<GQLColor>;
  subsectionTitleColor?: Maybe<GQLColor>;
  surfaceColor?: Maybe<GQLColor>;
};

export type GQLItemVideosQueryVariables = Exact<{ [key: string]: never }>;

export type GQLItemVideosQuery = {
  __typename?: "Query";
  itemVideos?:
    | Array<{
        __typename?: "ItemVideo";
        itemID?: string | null | undefined;
        itemTitle?: string | null | undefined;
        uniqueKey?: string | null | undefined;
      }>
    | null
    | undefined;
};

export type GQLRestaurantQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GQLRestaurantQuery = {
  __typename?: "Query";
  restaurant?:
    | {
        __typename?: "Restaurant";
        id?: string | null | undefined;
        title?: string | null | undefined;
        description?: string | null | undefined;
        blurb?: string | null | undefined;
        hours?: string | null | undefined;
        qrCodeURL?: string | null | undefined;
        videoURLs?:
          | Array<{
              __typename?: "MediaURL";
              source?: string | null | undefined;
              mimeType?: string | null | undefined;
            }>
          | null
          | undefined;
        filters?:
          | Array<{
              __typename?: "MenuFilter";
              id?: string | null | undefined;
              title?: string | null | undefined;
            }>
          | null
          | undefined;
        menus?:
          | Array<{
              __typename?: "Menu";
              id?: string | null | undefined;
              title?: string | null | undefined;
              sections?:
                | Array<{
                    __typename?: "MenuSection";
                    id?: string | null | undefined;
                    title?: string | null | undefined;
                    subsections?:
                      | Array<{
                          __typename?: "MenuSubsection";
                          id?: string | null | undefined;
                          title?: string | null | undefined;
                          items?:
                            | Array<{
                                __typename?: "MenuItem";
                                id?: string | null | undefined;
                                title?: string | null | undefined;
                                subtitle?: string | null | undefined;
                                addonText?: string | null | undefined;
                                price?:
                                  | {
                                      __typename?: "Price";
                                      totalCents?: number | null | undefined;
                                    }
                                  | null
                                  | undefined;
                                thumbnail?:
                                  | {
                                      __typename?: "MediaURL";
                                      source?: string | null | undefined;
                                      mimeType?: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                                videoFirstFrameURL?:
                                  | {
                                      __typename?: "MediaURL";
                                      source?: string | null | undefined;
                                      mimeType?: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                                videoURLs?:
                                  | Array<{
                                      __typename?: "MediaURL";
                                      source?: string | null | undefined;
                                      mimeType?: string | null | undefined;
                                    }>
                                  | null
                                  | undefined;
                                matchingFilters?:
                                  | Array<{
                                      __typename?: "MenuFilter";
                                      id?: string | null | undefined;
                                      title?: string | null | undefined;
                                    }>
                                  | null
                                  | undefined;
                              }>
                            | null
                            | undefined;
                        }>
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
        metadata?:
          | {
              __typename?: "RestaurantMetadata";
              font?: number | null | undefined;
              backgroundColor?:
                | {
                    __typename?: "Color";
                    red?: number | null | undefined;
                    green?: number | null | undefined;
                    blue?: number | null | undefined;
                  }
                | null
                | undefined;
              surfaceColor?:
                | {
                    __typename?: "Color";
                    red?: number | null | undefined;
                    green?: number | null | undefined;
                    blue?: number | null | undefined;
                  }
                | null
                | undefined;
              borderColor?:
                | {
                    __typename?: "Color";
                    red?: number | null | undefined;
                    green?: number | null | undefined;
                    blue?: number | null | undefined;
                  }
                | null
                | undefined;
              headerBackgroundColor?:
                | {
                    __typename?: "Color";
                    red?: number | null | undefined;
                    green?: number | null | undefined;
                    blue?: number | null | undefined;
                  }
                | null
                | undefined;
              restaurantTitleColor?:
                | {
                    __typename?: "Color";
                    red?: number | null | undefined;
                    green?: number | null | undefined;
                    blue?: number | null | undefined;
                  }
                | null
                | undefined;
              searchFilterButtonColor?:
                | {
                    __typename?: "Color";
                    red?: number | null | undefined;
                    green?: number | null | undefined;
                    blue?: number | null | undefined;
                  }
                | null
                | undefined;
              sectionBarTextColor?:
                | {
                    __typename?: "Color";
                    red?: number | null | undefined;
                    green?: number | null | undefined;
                    blue?: number | null | undefined;
                  }
                | null
                | undefined;
              sectionTitleColor?:
                | {
                    __typename?: "Color";
                    red?: number | null | undefined;
                    green?: number | null | undefined;
                    blue?: number | null | undefined;
                  }
                | null
                | undefined;
              subsectionTitleColor?:
                | {
                    __typename?: "Color";
                    red?: number | null | undefined;
                    green?: number | null | undefined;
                    blue?: number | null | undefined;
                  }
                | null
                | undefined;
              itemTitleColor?:
                | {
                    __typename?: "Color";
                    red?: number | null | undefined;
                    green?: number | null | undefined;
                    blue?: number | null | undefined;
                  }
                | null
                | undefined;
              itemPriceColor?:
                | {
                    __typename?: "Color";
                    red?: number | null | undefined;
                    green?: number | null | undefined;
                    blue?: number | null | undefined;
                  }
                | null
                | undefined;
              itemDescriptionColor?:
                | {
                    __typename?: "Color";
                    red?: number | null | undefined;
                    green?: number | null | undefined;
                    blue?: number | null | undefined;
                  }
                | null
                | undefined;
              playButtonBackgroundColor?:
                | {
                    __typename?: "Color";
                    red?: number | null | undefined;
                    green?: number | null | undefined;
                    blue?: number | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GQLAllColorsFragment = {
  __typename?: "Color";
  red?: number | null | undefined;
  green?: number | null | undefined;
  blue?: number | null | undefined;
};

export type GQLMediaInfoFragment = {
  __typename?: "MediaURL";
  source?: string | null | undefined;
  mimeType?: string | null | undefined;
};

export type GQLAllFieldsFragment = {
  __typename?: "MenuItem";
  id?: string | null | undefined;
  title?: string | null | undefined;
  subtitle?: string | null | undefined;
  addonText?: string | null | undefined;
  price?:
    | { __typename?: "Price"; totalCents?: number | null | undefined }
    | null
    | undefined;
  thumbnail?:
    | {
        __typename?: "MediaURL";
        source?: string | null | undefined;
        mimeType?: string | null | undefined;
      }
    | null
    | undefined;
  videoFirstFrameURL?:
    | {
        __typename?: "MediaURL";
        source?: string | null | undefined;
        mimeType?: string | null | undefined;
      }
    | null
    | undefined;
  videoURLs?:
    | Array<{
        __typename?: "MediaURL";
        source?: string | null | undefined;
        mimeType?: string | null | undefined;
      }>
    | null
    | undefined;
  matchingFilters?:
    | Array<{
        __typename?: "MenuFilter";
        id?: string | null | undefined;
        title?: string | null | undefined;
      }>
    | null
    | undefined;
};

export const AllColorsFragmentDoc = gql`
  fragment allColors on Color {
    red
    green
    blue
  }
`;
export const MediaInfoFragmentDoc = gql`
  fragment mediaInfo on MediaURL {
    source
    mimeType
  }
`;
export const AllFieldsFragmentDoc = gql`
  fragment allFields on MenuItem {
    id
    title
    subtitle
    price {
      totalCents
    }
    addonText
    thumbnail {
      ...mediaInfo
    }
    videoFirstFrameURL {
      ...mediaInfo
    }
    videoURLs {
      ...mediaInfo
    }
    matchingFilters {
      id
      title
    }
  }
  ${MediaInfoFragmentDoc}
`;
export const ItemVideosDocument = gql`
  query itemVideos {
    itemVideos {
      itemID
      itemTitle
      uniqueKey
    }
  }
`;
export const RestaurantDocument = gql`
  query restaurant($id: ID!) {
    restaurant(id: $id) {
      id
      title
      description
      blurb
      hours
      qrCodeURL
      videoURLs {
        ...mediaInfo
      }
      filters {
        id
        title
      }
      menus {
        id
        title
        sections {
          id
          title
          subsections {
            id
            title
            items {
              ...allFields
            }
          }
        }
      }
      metadata {
        font
        backgroundColor {
          ...allColors
        }
        surfaceColor {
          ...allColors
        }
        borderColor {
          ...allColors
        }
        headerBackgroundColor {
          ...allColors
        }
        restaurantTitleColor {
          ...allColors
        }
        searchFilterButtonColor {
          ...allColors
        }
        sectionBarTextColor {
          ...allColors
        }
        sectionTitleColor {
          ...allColors
        }
        subsectionTitleColor {
          ...allColors
        }
        itemTitleColor {
          ...allColors
        }
        itemPriceColor {
          ...allColors
        }
        itemDescriptionColor {
          ...allColors
        }
        playButtonBackgroundColor {
          ...allColors
        }
      }
    }
  }
  ${MediaInfoFragmentDoc}
  ${AllFieldsFragmentDoc}
  ${AllColorsFragmentDoc}
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    itemVideos(
      variables?: GQLItemVideosQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<GQLItemVideosQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GQLItemVideosQuery>(ItemVideosDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "itemVideos"
      );
    },
    restaurant(
      variables: GQLRestaurantQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<GQLRestaurantQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GQLRestaurantQuery>(RestaurantDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "restaurant"
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
