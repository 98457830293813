import { AnalyticsEvent } from "./AnalyticsEvent";
import { AnalyticsService } from "./AnalyticsService";

export class AnalyticsServiceMultiDecorator implements AnalyticsService {
  constructor(readonly services: AnalyticsService[]) {}

  initialize() {
    this.services.forEach((service) => service.initialize());
  }

  logEvent(event: AnalyticsEvent) {
    this.services.forEach((service) => service.logEvent(event));
  }

  setRestaurant(id: string | null, title: string | null) {
    this.services.forEach((service) => service.setRestaurant(id, title));
  }
}
