import "./ErrorView.css";

interface ErrorViewProps {
  errorMessage?: string;
  errorDetails?: string;
}

export function ErrorView(props: ErrorViewProps) {
  function reload() {
    window.location.reload();
  }

  return (
    <div className="error-container">
      <h1 className="error-title">Oops! An error occurred.</h1>
      {props.errorMessage && (
        <p className="error-message">{props.errorMessage}</p>
      )}
      {props.errorDetails && (
        <>
          <p className="error-details-label">Details for nerds:</p>
          <p className="error-details">{props.errorDetails}</p>
        </>
      )}
      <button className="error-reload-button" onClick={reload}>
        Reload page
      </button>
    </div>
  );
}
