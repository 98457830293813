import { RestaurantMenuItem } from "../../models/RestaurantMenuItem";

export enum AnalyticsEventAction {
  ViewPage = "VIEW_PAGE",

  OpenQR = "OPEN_QR",
  ViewRestaurant = "VIEW_RESTAURANT",
  LandingPageClick = "LANDING_PAGE_CLICK",
  ReachOutClick = "REACH_OUT_CLICK",

  ViewItem = "VIEW_ITEM",
  SwipeItem = "SWIPE_ITEM",
  ExitItem = "EXIT_ITEM",

  AddToCart = "ADD_TO_CART",
  RemoveFromCart = "REMOVE_FROM_CART",

  UpdateMenu = "UPDATE_MENU",

  Search = "SEARCH",

  AddFilter = "ADD_FILTER",
  RemoveFilter = "REMOVE_FILTER",

  VideoFrameLoaded = "VIDEO_FRAME_LOADED",
  VideoLoaded = "VIDEO_LOADED",

  ShownSwipeUpPromo = "SHOWN_SWIPE_UP_PROMO",
  ShownSwipeLeftPromo = "SHOWN_SWIPE_UP_PROMO",
  ShownVideoPromo = "SHOWN_VIDEO_PROMO",

  FirstFrameImageFetchError = "FIRST_FRAME_IMAGE_FETCH_ERROR",
  RenderError = "RENDER_ERROR",
  VideoError = "VIDEO_ERROR",
  VideoManualPlayError = "VIDEO_MANUAL_PLAY_ERROR",
  VideoPromiseError = "VIDEO_PROMISE_ERROR",
  VideoFetchError = "VIDEO_FETCH_ERROR",
}

export enum AnalyticsCartEventSource {
  ListView = "LIST_VIEW",
  ItemView = "ITEM_VIEW",
  Cart = "CART",
  Search = "SEARCH",
}

export interface AnalyticsCartEventProperties {
  itemID: string;
  itemTitle: string;
  itemQuantity: number;
  itemPrice: number;
  totalCartValue: number;
  source: AnalyticsCartEventSource;
}

export interface AnalyticsErrorEventProperties {
  errorMessage: string;
  componentStack: string;
}

export interface AnalyticsFilterEventProperties {
  filterID: string;
  filterTitle: string;
}

export enum AnalyticsItemEventSwipeDirection {
  Up = "UP",
  Down = "DOWN",
  Left = "LEFT",
  Right = "RIGHT",
}

export interface AnalyticsItemEventProperties {
  swipeDirection?: AnalyticsItemEventSwipeDirection;

  // Either item ID or item IDs will be populated depending whether it was a video or items page.
  fromItemID?: string;
  fromItemTitle?: string;
  fromItemIDs?: string[];
  fromItemTitles?: string[];
  toItemID?: string;
  toItemTitle?: string;
  toItemIDs?: string[];
  toItemTitles?: string[];

  pageViewTime?: number;
}

export interface AnalyticsLandingPageClickEventProperties {
  destination: string;
}

export interface AnalyticsMenuEventProperties {
  menuID: string;
}

export interface AnalyticsPageEventProperties {
  pathname: string;
  search: string;
  domain: string;
}

export interface AnalyticsPromoEventProperties {}

export interface AnalyticsRestaurantEventProperties {
  restaurantID: string;
}

export interface AnalyticsSearchEventProperties {
  search: string;
}

export interface AnalyticsVideoErrorEventProperties {
  itemID: string;
  itemTitle: string;
  userAgent: string;
  errorMessage: string;
}

export interface AnalyticsVideoEventProperties {
  fromCache: boolean;
  loadTime: number;
  itemID: string;
  itemTitle: string;
}

export interface AnalyticsVideoFetchErrorEventProperties {
  itemID: string;
  itemTitle: string;
  source: string;
}

export interface AnalyticsVideoPromiseErrorEventProperties {
  itemID: string;
  itemTitle: string;
  intendedToBePlaying: boolean;
  offsetLeft: number;
  offsetTop: number;
  paused: boolean;
  readyState: number;
  src: string;
  muted: boolean;
  userAgent: string;
  errorMessage: string;
}

export type AnalyticsEventProperties =
  | AnalyticsCartEventProperties
  | AnalyticsErrorEventProperties
  | AnalyticsFilterEventProperties
  | AnalyticsItemEventProperties
  | AnalyticsMenuEventProperties
  | AnalyticsPageEventProperties
  | AnalyticsPromoEventProperties
  | AnalyticsRestaurantEventProperties
  | AnalyticsSearchEventProperties
  | AnalyticsVideoErrorEventProperties
  | AnalyticsVideoEventProperties
  | AnalyticsVideoFetchErrorEventProperties
  | AnalyticsVideoPromiseErrorEventProperties;

export class AnalyticsEvent {
  constructor(
    readonly action: AnalyticsEventAction,
    readonly properties: AnalyticsEventProperties | undefined
  ) {}

  static viewPage(
    pathname: string,
    search: string,
    domain: string
  ): AnalyticsEvent {
    const properties: AnalyticsPageEventProperties = {
      pathname,
      search,
      domain,
    };
    return new AnalyticsEvent(AnalyticsEventAction.ViewPage, properties);
  }

  static openQR(restaurantID: string): AnalyticsEvent {
    const properties: AnalyticsRestaurantEventProperties = { restaurantID };
    return new AnalyticsEvent(AnalyticsEventAction.OpenQR, properties);
  }

  static viewRestaurant(restaurantID: string): AnalyticsEvent {
    const properties: AnalyticsRestaurantEventProperties = { restaurantID };
    return new AnalyticsEvent(AnalyticsEventAction.ViewRestaurant, properties);
  }

  static landingPageClick(destination: string): AnalyticsEvent {
    const properties: AnalyticsLandingPageClickEventProperties = {
      destination,
    };
    return new AnalyticsEvent(
      AnalyticsEventAction.LandingPageClick,
      properties
    );
  }

  static reachOutClick(): AnalyticsEvent {
    return new AnalyticsEvent(AnalyticsEventAction.ReachOutClick, undefined);
  }

  static viewItem(
    toItemID: string | undefined,
    toItemTitle: string | undefined,
    toItemIDs: string[] | undefined,
    toItemTitles: string[] | undefined
  ): AnalyticsEvent {
    const properties: AnalyticsItemEventProperties = {
      toItemID,
      toItemTitle,
      toItemIDs,
      toItemTitles,
    };
    return new AnalyticsEvent(AnalyticsEventAction.ViewItem, properties);
  }

  static swipeItem(
    swipeDirection: AnalyticsItemEventSwipeDirection,
    pageViewTime: number,
    fromItemID: string | undefined,
    fromItemTitle: string | undefined,
    fromItemIDs: string[] | undefined,
    fromItemTitles: string[] | undefined,
    toItemID: string | undefined,
    toItemTitle: string | undefined,
    toItemIDs: string[] | undefined,
    toItemTitles: string[] | undefined
  ): AnalyticsEvent {
    const properties: AnalyticsItemEventProperties = {
      swipeDirection,
      pageViewTime,
      fromItemID,
      fromItemTitle,
      fromItemTitles,
      fromItemIDs,
      toItemID,
      toItemTitle,
      toItemIDs,
      toItemTitles,
    };
    return new AnalyticsEvent(AnalyticsEventAction.SwipeItem, properties);
  }

  static exitItem(
    pageViewTime: number,
    fromItemID: string | undefined,
    fromItemTitle: string | undefined,
    fromItemIDs: string[] | undefined,
    fromItemTitles: string[] | undefined
  ): AnalyticsEvent {
    const properties: AnalyticsItemEventProperties = {
      pageViewTime,
      fromItemID,
      fromItemTitle,
      fromItemIDs,
      fromItemTitles,
    };
    return new AnalyticsEvent(AnalyticsEventAction.ExitItem, properties);
  }

  static addToCart(
    item: RestaurantMenuItem,
    totalCartValue: number,
    source: AnalyticsCartEventSource
  ): AnalyticsEvent {
    const properties: AnalyticsCartEventProperties = {
      itemID: item.id,
      itemTitle: item.title,
      itemQuantity: 1,
      itemPrice: (item.price?.totalCents ?? 0) / 100,
      totalCartValue,
      source,
    };
    return new AnalyticsEvent(AnalyticsEventAction.AddToCart, properties);
  }

  static removeFromCart(
    item: RestaurantMenuItem,
    totalCartValue: number,
    source: AnalyticsCartEventSource
  ): AnalyticsEvent {
    const properties: AnalyticsCartEventProperties = {
      itemID: item.id,
      itemTitle: item.title,
      itemQuantity: 1,
      itemPrice: (item.price?.totalCents ?? 0) / 100,
      totalCartValue,
      source,
    };
    return new AnalyticsEvent(AnalyticsEventAction.RemoveFromCart, properties);
  }

  static updateMenu(menuID: string): AnalyticsEvent {
    const properties: AnalyticsMenuEventProperties = { menuID };
    return new AnalyticsEvent(AnalyticsEventAction.UpdateMenu, properties);
  }

  static search(search: string): AnalyticsEvent {
    const properties: AnalyticsSearchEventProperties = { search };
    return new AnalyticsEvent(AnalyticsEventAction.Search, properties);
  }

  static addFilter(filterID: string, filterTitle: string): AnalyticsEvent {
    const properties: AnalyticsFilterEventProperties = {
      filterID,
      filterTitle,
    };
    return new AnalyticsEvent(AnalyticsEventAction.AddFilter, properties);
  }

  static removeFilter(filterID: string, filterTitle: string): AnalyticsEvent {
    const properties: AnalyticsFilterEventProperties = {
      filterID,
      filterTitle,
    };
    return new AnalyticsEvent(AnalyticsEventAction.RemoveFilter, properties);
  }

  static videoFrameLoaded(
    fromCache: boolean,
    loadTime: number,
    itemID: string,
    itemTitle: string
  ): AnalyticsEvent {
    const properties: AnalyticsVideoEventProperties = {
      fromCache,
      loadTime,
      itemID,
      itemTitle,
    };
    return new AnalyticsEvent(
      AnalyticsEventAction.VideoFrameLoaded,
      properties
    );
  }

  static videoLoaded(
    fromCache: boolean,
    loadTime: number,
    itemID: string,
    itemTitle: string
  ): AnalyticsEvent {
    const properties: AnalyticsVideoEventProperties = {
      fromCache,
      loadTime,
      itemID,
      itemTitle,
    };
    return new AnalyticsEvent(AnalyticsEventAction.VideoLoaded, properties);
  }

  static shownSwipeUpPromo(): AnalyticsEvent {
    return new AnalyticsEvent(AnalyticsEventAction.ShownSwipeUpPromo, {});
  }

  static shownSwipeLeftPromo(): AnalyticsEvent {
    return new AnalyticsEvent(AnalyticsEventAction.ShownSwipeLeftPromo, {});
  }

  static shownVideoPromo(): AnalyticsEvent {
    return new AnalyticsEvent(AnalyticsEventAction.ShownVideoPromo, {});
  }

  static renderError(
    errorMessage: string,
    componentStack: string
  ): AnalyticsEvent {
    const properties: AnalyticsErrorEventProperties = {
      errorMessage,
      componentStack,
    };
    return new AnalyticsEvent(AnalyticsEventAction.RenderError, properties);
  }

  static videoError(
    properties: AnalyticsVideoErrorEventProperties
  ): AnalyticsEvent {
    return new AnalyticsEvent(AnalyticsEventAction.VideoError, properties);
  }

  static videoManualPlayError(
    properties: AnalyticsVideoPromiseErrorEventProperties
  ): AnalyticsEvent {
    return new AnalyticsEvent(
      AnalyticsEventAction.VideoManualPlayError,
      properties
    );
  }

  static videoPromiseError(
    properties: AnalyticsVideoPromiseErrorEventProperties
  ): AnalyticsEvent {
    return new AnalyticsEvent(
      AnalyticsEventAction.VideoPromiseError,
      properties
    );
  }

  static videoFetchError(
    itemID: string,
    itemTitle: string,
    source: string
  ): AnalyticsEvent {
    const properties: AnalyticsVideoFetchErrorEventProperties = {
      itemID,
      itemTitle,
      source,
    };
    return new AnalyticsEvent(AnalyticsEventAction.VideoFetchError, properties);
  }

  static firstFrameImageFetchError(
    itemID: string,
    itemTitle: string,
    source: string
  ): AnalyticsEvent {
    const properties: AnalyticsVideoFetchErrorEventProperties = {
      itemID,
      itemTitle,
      source,
    };
    return new AnalyticsEvent(
      AnalyticsEventAction.FirstFrameImageFetchError,
      properties
    );
  }
}
