import { MediaURL } from "../../models/MediaURL";
import { AnalyticsEvent } from "../../services/analytics/AnalyticsEvent";
import { AnalyticsService } from "../../services/analytics/AnalyticsService";

// The server's time is slightly out of sync. Allow items to be marked as not
// cached if they were from less than this amount of time ago.
const CACHE_CHECK_FUDGE = 3000;

export class VideoService {
  static videoElement: HTMLVideoElement | null;

  private readonly videoUrlMap: Map<string, string>;

  private readonly imageUrlMap: Map<string, string>;

  static shared = new VideoService();

  constructor() {
    this.videoUrlMap = new Map();
    this.imageUrlMap = new Map();
  }

  /**
   * Loads the video and return the object URL to be assigned to the src element of the video.
   *
   * Returns [videoBlobURL, videoMimeType, rawBlob].
   */
  async loadVideo(
    urls: MediaURL[],
    analyticsService: AnalyticsService | null,
    itemID: string | null,
    itemTitle: string | null,
    thumbnail: boolean
  ): Promise<[string, string]> {
    if (!VideoService.videoElement) {
      VideoService.videoElement = document.createElement("video");
    }
    const element = VideoService.videoElement;
    const adjustedURLs = this.thumbnailAdjustedURLs(urls, thumbnail);
    for (const url of adjustedURLs) {
      if (element.canPlayType(url.mimeType) === "") {
        continue;
      }

      const cachedURL = this.videoUrlMap.get(url.source);
      if (cachedURL) {
        return [cachedURL, url.mimeType];
      }

      const startDate = new Date();
      let response;
      try {
        response = await fetch(url.source);
      } catch (error) {
        // TODO: Remove.
        response = await fetch(url.source, { cache: "no-cache" });
      }

      if (!response.ok) {
        if (analyticsService && itemID && itemTitle && !thumbnail) {
          analyticsService?.logEvent(
            AnalyticsEvent.videoFetchError(itemID, itemTitle, url.source)
          );
        }

        throw new Error(`Failed to fetch video: ${url.source}`);
      }

      const finalDate = new Date(response.headers.get("Date") || "");

      if (analyticsService && itemID && itemTitle && !thumbnail) {
        analyticsService.logEvent(
          AnalyticsEvent.videoLoaded(
            finalDate.getTime() + CACHE_CHECK_FUDGE < startDate.getTime(),
            Date.now() - startDate.getTime(),
            itemID,
            itemTitle
          )
        );
      }

      const blob = await response.blob();
      const blobURL = window.URL.createObjectURL(blob);
      this.videoUrlMap.set(url.source, blobURL);
      return [blobURL, url.mimeType];
    }
    throw new Error("No suitable media for URLs!");
  }

  revokeVideoBlob(urls: MediaURL[], thumbnail: boolean) {
    const adjustedURLs = this.thumbnailAdjustedURLs(urls, thumbnail);
    for (const url of adjustedURLs) {
      const cachedURL = this.videoUrlMap.get(url.source);
      if (!cachedURL) {
        continue;
      }
      this.videoUrlMap.delete(url.source);
      URL.revokeObjectURL(cachedURL);
    }
  }

  async loadFirstFrameImage(
    url: MediaURL,
    analyticsService: AnalyticsService,
    itemID: string,
    itemTitle: string
  ): Promise<string> {
    const cachedURL = this.imageUrlMap.get(url.source);
    if (cachedURL) {
      return cachedURL;
    }

    const startDate = new Date();
    let response;
    try {
      response = await fetch(url.source);
    } catch (error) {
      // TODO: Remove.
      response = await fetch(url.source, { cache: "no-cache" });
    }
    if (!response.ok) {
      analyticsService.logEvent(
        AnalyticsEvent.firstFrameImageFetchError(itemID, itemTitle, url.source)
      );

      throw new Error(`Failed to fetch first frame image: ${url.source}`);
    }

    const finalDate = new Date(response.headers.get("Date") || "");

    analyticsService.logEvent(
      AnalyticsEvent.videoFrameLoaded(
        finalDate.getTime() + CACHE_CHECK_FUDGE < startDate.getTime(),
        Date.now() - startDate.getTime(),
        itemID,
        itemTitle
      )
    );

    const blob = await response.blob();
    const blobURL = window.URL.createObjectURL(blob);
    this.imageUrlMap.set(url.source, blobURL);
    return blobURL;
  }

  revokeFirstFrameImageBlob(itemID: string, url: MediaURL) {
    const cachedURL = this.imageUrlMap.get(url.source);
    if (!cachedURL) {
      return;
    }
    this.imageUrlMap.delete(url.source);
    URL.revokeObjectURL(cachedURL);
  }

  async setThumbnailTimestamp(uniqueKey: string, timestamp: number) {
    if (!process.env.REACT_APP_EDIT_THUMBNAIL_URL) {
      throw new Error(
        "Required env variable REACT_APP_EDIT_THUMBNAIL_URL not set!"
      );
    }
    const response = await fetch(process.env.REACT_APP_EDIT_THUMBNAIL_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uniqueKey, timestamp }),
    });
    if (!response.ok) {
      console.error("Failed to set timestamp!");
      alert("Failed to set timestamp!");
    }
  }

  private thumbnailAdjustedURLs(urls: MediaURL[], thumbnail: boolean) {
    if (!thumbnail) {
      return urls;
    }
    // TODO(#197): Remove hacky replacement of video URLs.
    return urls.map(
      (url) =>
        new MediaURL(
          url.source.replace("/transcoded2/", "/transcodedthumbnail/"),
          url.mimeType
        )
    );
  }
}
