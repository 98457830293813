import "./BottomSheetSelect.css";

export interface BottomSheetSelectProps {
  title: string;
  isOn: boolean;
  onClick?: () => void;
}

export function BottomSheetSelect(props: BottomSheetSelectProps) {
  return (
    <button className="bottom-sheet-select" onClick={props.onClick}>
      <div className="bottom-sheet-select-icon-container">
        <div className="bottom-sheet-select-icon" />
        {props.isOn && <div className="bottom-sheet-select-selected-icon" />}
      </div>
      <div className="bottom-sheet-select-title">{props.title}</div>
    </button>
  );
}
