import "./VerticalScrollingContainer.css";

import {
  ForwardedRef,
  ReactNode,
  UIEvent,
  forwardRef,
  useEffect,
  useRef,
} from "react";

import { useScrollFade } from "../scroll_fade/ScrollFade";
import { setForwardedRef } from "../../utils/react/ForwardedRef";

interface VerticalScrollingContainerProps {
  className?: string;
  /**
   * Padding applied to the inner scroll view.
   * Necessary to allow the scroll to continue out of the inner container
   * bounds.
   * If padding should be applied to the entire thing (clipping scroll as
   * well), apply it to the outer className.
   */
  scrollPadding: string;
  onScroll?: (yOffset: number) => void;
  children: ReactNode;
}

export const VerticalScrollingContainer = forwardRef(
  function VerticalScrollingContainer(
    props: VerticalScrollingContainerProps,
    ref: ForwardedRef<HTMLDivElement | null>
  ) {
    const listRef = useRef(null);
    const [fadeOnScroll, topFade, , bottomFade] = useScrollFade(listRef, true);

    // Forward listRef to the passed ref, if it exists.
    useEffect(() => {
      if (ref) {
        setForwardedRef<HTMLDivElement | null>(ref, listRef.current);
      }
    }, [listRef.current]);

    function onScroll(event: UIEvent) {
      fadeOnScroll(event);

      if (props.onScroll) {
        const target = event.target as HTMLElement;
        props.onScroll?.(target.scrollTop);
      }
    }

    return (
      <div className={`${props.className ?? ""}`}>
        {topFade}
        <div
          ref={listRef}
          style={{ padding: props.scrollPadding }}
          className="vertical-scrolling-container"
          onScroll={onScroll}
        >
          {props.children}
        </div>
        {bottomFade}
      </div>
    );
  }
);
