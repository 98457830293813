import "./RestaurantMenuItemDetailsPage.css";

import { RestaurantMenuItem } from "../../../models/RestaurantMenuItem";

export interface RestaurantMenuItemDetailsPageProps {
  item: RestaurantMenuItem;
}

export function RestaurantMenuItemDetailsPage(
  props: RestaurantMenuItemDetailsPageProps
) {
  return (
    <div className="restaurant-menu-item-details-page">
      <h2 className="restaurant-menu-item-details-title">{props.item.title}</h2>
      <p className="restaurant-menu-item-details-subtitle">
        {props.item.subtitle}
      </p>
      <h3 className="restaurant-menu-item-details-add-text">add:</h3>
      {props.item.addonText && (
        <p className="restaurant-menu-item-details-addon-text">
          {props.item.addonText}
        </p>
      )}
    </div>
  );
}
