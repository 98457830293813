import "./RestaurantLandingPage.css";

import { Link } from "react-router-dom";

import { RestaurantPageChildParams } from "../RestaurantPageChildParams";
import { NoErrorImage } from "../../../components/no_error_image/NoErrorImage";

export function RestaurantLandingPage(props: RestaurantPageChildParams) {
  return (
    <div className="restaurant-landing-page">
      <NoErrorImage
        className="restaurant-landing-page-image"
        src={`https://menutest1storage.blob.core.windows.net/restaurantlogos/${props.restaurant.id}.jpg`}
        alt={`${props.restaurant.title} restaurant logo`}
        fallback={<div className="restaurant-landing-page-image-fallback" />}
      />
      <h1 className="restaurant-landing-page-title">
        {props.restaurant.title}
      </h1>
      <p className="restaurant-landing-page-description">
        {props.restaurant.description}
      </p>
      <p className="restaurant-landing-page-blurb render-line-breaks">
        {props.restaurant.blurb}
      </p>
      <Link to="menu">
        <div className="restaurant-landing-page-menu-button-container">
          <NoErrorImage
            className="restaurant-landing-page-menu-button-image"
            src={`https://menutest1storage.blob.core.windows.net/restaurantpreviews/${props.restaurant.id}.jpg`}
            alt={`${props.restaurant.title} preview dish`}
            fallback={
              <div className="restaurant-landing-page-menu-button-image-fallback" />
            }
          />
          <p className="restaurant-landing-page-menu-button-text">
            Explore the menu
          </p>
        </div>
      </Link>
      <p className="restaurant-landing-page-hours render-line-breaks">
        {props.restaurant.hours}
      </p>
    </div>
  );
}
