export class DocumentSizeUtil {
  observeDocumentHeight() {
    document.documentElement.style.setProperty(
      "--full-height",
      `${window.innerHeight}px`
    );

    function onResize() {
      document.documentElement.style.setProperty(
        "--full-height",
        `${window.innerHeight}px`
      );
    }
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }
}
