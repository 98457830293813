import "./App.css";

import { Suspense, lazy, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Outlet } from "react-router";

import { ErrorBoundary } from "./components/error_boundary/ErrorBoundary";
import { PageViewAnalyticsPage } from "./pages/analytics/PageViewAnalyticsPage";
import { LoadingPage } from "./pages/loading/LoadingPage";
import { NotFoundPage } from "./pages/not_found/NotFoundPage";
import { QRRedirectPage } from "./pages/qr_redirect/QRRedirectPage";
import { RestaurantPage } from "./pages/restaurant/RestaurantPage";
import { AnalyticsEvent } from "./services/analytics/AnalyticsEvent";
import { AnalyticsService } from "./services/analytics/AnalyticsService";
import { AnalyticsServiceContext } from "./services/analytics/AnalyticsServiceContext";
import { DocumentSizeUtil } from "./utils/DocumentSizeUtil";

// Lazily load unnecessary and large routes.
const CreateRestaurantPage = lazy(
  () => import("./pages/create_restaurant/CreateRestaurantPage")
);
const EditThumbnailSelectionPage = lazy(
  () => import("./pages/edit_thumbnail/EditThumbnailSelectionPage")
);
const HomePage = lazy(() => import("./pages/home/HomePage"));
const TestSettingsPage = lazy(
  () => import("./pages/test_settings/TestSettingsPage")
);

export interface AppProps {
  analyticsService: AnalyticsService;
}

export function App(props: AppProps) {
  useEffect(() => {
    const isInIframe = (() => {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    })();
    if (isInIframe) {
      props.analyticsService.logEvent(
        AnalyticsEvent.landingPageClick(window.location.href)
      );
      window.parent.location.href = window.location.href;
    }
  }, [props.analyticsService]);

  useEffect(() => {
    const documentSizeUtil = new DocumentSizeUtil();
    return documentSizeUtil.observeDocumentHeight();
  }, []);

  return (
    <AnalyticsServiceContext.Provider value={props.analyticsService}>
      <ErrorBoundary>
        <div className="App">
          <Routes>
            <Route
              path="/"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <HomePage />
                </Suspense>
              }
            />
            <Route path="about" element={<h1>About</h1>} />
            <Route
              path="restaurant/create"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <CreateRestaurantPage />
                </Suspense>
              }
            />
            <Route
              path="restaurant/editthumbnail/*"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <EditThumbnailSelectionPage />
                </Suspense>
              }
            />
            <Route
              path="restaurant/:restaurantID/*"
              element={<RestaurantPage />}
            />
            <Route path="qr/:restaurantID" element={<QRRedirectPage />} />
            <Route
              path="testsettings"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <TestSettingsPage />
                </Suspense>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>

          <PageViewAnalyticsPage />
        </div>
      </ErrorBoundary>
    </AnalyticsServiceContext.Provider>
  );
}

export default App;
