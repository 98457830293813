import "./RestaurantMenuSelectorModal.css";

import { BottomSheetHeader } from "../../../components/bottom_sheet/BottomSheetHeader";
import { BottomSheetSelect } from "../../../components/bottom_sheet/BottomSheetSelect";
import { RestaurantMenu } from "../../../models/RestaurantMenu";

interface RestaurantMenuSelectorModalProps {
  currentMenu: RestaurantMenu;
  menus: RestaurantMenu[];
  onMenuSelected: (menu: RestaurantMenu) => void;
}

export function RestaurantMenuSelectorModal(
  props: RestaurantMenuSelectorModalProps
) {
  return (
    <div>
      <BottomSheetHeader title="Menu" />
      <div className="restaurant-menu-selector-modal-menu-container">
        {props.menus.map((menu) => (
          <BottomSheetSelect
            key={menu.id}
            title={menu.title}
            isOn={menu.id === props.currentMenu.id}
            onClick={() => props.onMenuSelected(menu)}
          />
        ))}
      </div>
    </div>
  );
}
