import "./RestaurantMenuItemRow.css";

import classNames from "classnames";
import { MouseEvent, ReactNode, useState } from "react";
import { Link } from "react-router-dom";

import { AddToCartButton } from "../add_to_cart_button/AddToCartButton";
import { NoErrorImage } from "../no_error_image/NoErrorImage";
import { OptionsButton } from "../options_button/OptionsButton";
import { VideoIcon } from "../video_icon/VideoIcon";
import { RestaurantMenuItem } from "../../models/RestaurantMenuItem";
import { cartEnabled } from "../../state/feature_flags/Cart";
import { VideoPlayer } from "../video_player/VideoPlayer";

export interface RestaurantMenuItemRowProps {
  item: RestaurantMenuItem;
  onOptionsSelected: ((item: RestaurantMenuItem) => void) | null;
  isInCart: boolean;
  onItemSelected: ((item: RestaurantMenuItem) => void) | null;
  onAddToCart: (item: RestaurantMenuItem) => void;
  isCompact: boolean;
  promo?: [ReactNode, boolean];
  showVideoThumbnail: boolean;
}

export function RestaurantMenuItemRow(props: RestaurantMenuItemRowProps) {
  const showVideoIcon = props.item.videoURLs.length > 0;
  const showOptions = props.onOptionsSelected !== null;

  const [videoPending, setVideoPending] = useState(true);

  function onOptionsClick(event: MouseEvent) {
    props.onOptionsSelected?.(props.item);

    event.stopPropagation();
  }

  return (
    <div
      className={classNames("restaurant-menu-item-row-button", {
        clickable: props.onItemSelected !== null,
      })}
      onClick={() => props.onItemSelected?.(props.item)}
    >
      <div className="restaurant-menu-item-row">
        <div className="restaurant-menu-item-row-text-container">
          <span className="restaurant-menu-item-row-title">
            {props.item.title}
          </span>
          {props.item.price && (
            <span className="restaurant-menu-item-row-price">
              {props.item.price.formattedText}
            </span>
          )}
          <div
            className={classNames(
              "restaurant-menu-item-row-subtitle-container",
              {
                "restaurant-menu-item-row-subtitle-container-margin":
                  props.item.subtitle !== null &&
                  props.item.subtitle.length > 0,
              }
            )}
          >
            <div
              className={classNames("restaurant-menu-item-row-subtitle", {
                "restaurant-menu-item-row-subtitle-line-limit": props.isCompact,
              })}
            >
              {props.item.subtitle}
            </div>
          </div>
          {showOptions && (
            <button onClick={onOptionsClick}>
              <div className="restaurant-menu-item-row-addons-text">
                View Add-Ons
              </div>
            </button>
          )}
        </div>
        {cartEnabled() && (
          <AddToCartButton onClick={() => props.onAddToCart(props.item)} />
        )}
        {props.item.thumbnail && (
          <NoErrorImage
            className="restaurant-menu-item-row-thumbnail"
            src={props.item.thumbnail.source}
            container={(image, hidden) => {
              if (hidden) {
                return null;
              }

              return (
                <div className="restaurant-menu-item-row-thumbnail-container">
                  {videoPending && image}
                  {props.showVideoThumbnail && (
                    <VideoPlayer
                      className={classNames(
                        "restaurant-menu-item-row-thumbnail",
                        { "very-hidden": videoPending }
                      )}
                      playing={true}
                      videoURLs={props.item.videoURLs}
                      item={props.item}
                      onLoaded={() => setVideoPending(false)}
                      onError={() => setVideoPending(true)}
                      onLoading={() => {}}
                      thumbnail={true}
                    />
                  )}
                  {showVideoIcon && (
                    <div
                      className={classNames(
                        "restaurant-menu-item-video-icon-container",
                        {
                          "restaurant-menu-item-video-icon-container-expanded":
                            props.promo?.[1] === true,
                        }
                      )}
                    >
                      <VideoIcon useHighZIndex={props.promo?.[1] === true} />
                      {props.promo?.[0]}
                    </div>
                  )}
                </div>
              );
            }}
          />
        )}
      </div>
    </div>
  );
}
