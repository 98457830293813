import "./CollapsingHeader.css";

import classNames from "classnames";
import {
  ForwardedRef,
  ReactNode,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSpring, animated } from "@react-spring/web";

import { setForwardedRef } from "../../utils/react/ForwardedRef";

interface CollapsingHeaderProps {
  enabled: boolean;
  maximumScrollOffset: number;
  children: ReactNode;
}

export const CollapsingHeader = forwardRef(function CollapsingHeader(
  props: CollapsingHeaderProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const divRef = useRef<HTMLDivElement>(null);
  // Duration experimentally determined to provide a fast but smooth experience on iOS.
  const [animation, api] = useSpring(() => ({
    top: "0",
    config: { duration: 80 },
  }));

  // Forward divRef to the passed ref, if it exists.
  useEffect(() => {
    if (divRef) {
      setForwardedRef<HTMLDivElement | null>(ref, divRef.current);
    }
  }, [divRef.current]);

  useEffect(() => {
    if (!props.enabled) {
      return;
    }

    const element = divRef.current;
    if (!element) {
      return;
    }

    let previousScroll = document.documentElement.scrollTop;
    let currentPosition = parseInt(element.style.top);
    if (isNaN(currentPosition)) {
      currentPosition = 0;
    }
    currentPosition = -currentPosition;

    function onScroll() {
      const element = divRef.current;
      if (!element) {
        return;
      }
      const diff = document.documentElement.scrollTop - previousScroll;
      previousScroll = document.documentElement.scrollTop;

      const newMargin = Math.min(
        Math.max(0, currentPosition + diff),
        element.offsetHeight - props.maximumScrollOffset
      );
      currentPosition = newMargin;
      api.start({ top: `-${newMargin}px` });
    }

    document.addEventListener("scroll", onScroll);
    return () => document.removeEventListener("scroll", onScroll);
  }, [divRef.current, props.enabled]);

  if (!props.enabled) {
    return <>{props.children}</>;
  }

  return (
    <animated.div
      ref={divRef}
      className={classNames({ "collapsing-header": props.enabled })}
      style={animation}
    >
      {props.children}
    </animated.div>
  );
});
