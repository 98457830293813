import "./RestaurantMenuItemVideo.css";

import {
  ForwardedRef,
  MutableRefObject,
  forwardRef,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";
import { Icon } from "@iconify/react";

import { NoErrorImage } from "../../../components/no_error_image/NoErrorImage";
import { VideoPlayer } from "../../../components/video_player/VideoPlayer";
import { MediaURL } from "../../../models/MediaURL";
import { RestaurantMenuItem } from "../../../models/RestaurantMenuItem";
import { AnalyticsEvent } from "../../../services/analytics/AnalyticsEvent";
import { useAnalyticsService } from "../../../services/analytics/AnalyticsServiceContext";
import { VideoService } from "../../../services/video/VideoService";
import { isSafari } from "../../../utils/Safari";

enum VideoState {
  Loading = 1,
  Loaded = 2,
  Error = 3,
}

export interface RestaurantMenuItemVideoProps {
  item: RestaurantMenuItem;
  firstFrameURL: MediaURL | null;
  videoURLs: MediaURL[];
  playing: boolean;
}

export function RestaurantMenuItemVideo(props: RestaurantMenuItemVideoProps) {
  const analyticsService = useAnalyticsService();

  const [videoState, setVideoState] = useState<VideoState>(VideoState.Loading);

  // onLoadStart not firing for img, just use first render as start time.
  const [frameLoadStartTime, setFrameLoadStartTime] = useState(Date.now());

  const videoStateOverlay = (() => {
    switch (videoState) {
      case VideoState.Loading:
        return (
          <div className="video-overlay">
            <Icon
              aria-label="Loading"
              className="video-overlay-loading-icon"
              icon="eos-icons:three-dots-loading"
            />
          </div>
        );
      case VideoState.Loaded:
        return null;
      case VideoState.Error:
        return (
          <button onClick={() => window.location.reload()}>
            <div className="video-overlay">
              <div className="video-error-overlay">
                <Icon className="video-error-overlay-icon" icon="bx:error" />
                <p className="video-error-overlay-text">An error occurred</p>
                <p className="video-error-overlay-button">Reload page</p>
              </div>
            </div>
          </button>
        );
    }
  })();

  return (
    <div className="restaurant-menu-item-video-container">
      <FirstFrameImage item={props.item} firstFrameURL={props.firstFrameURL} />

      <VideoPlayer
        className="restaurant-menu-item-video"
        playing={props.playing}
        videoURLs={props.videoURLs}
        item={props.item}
        onLoaded={() => setVideoState(VideoState.Loaded)}
        onError={() => setVideoState(VideoState.Error)}
        onLoading={() => setVideoState(VideoState.Loading)}
        thumbnail={false}
      />

      {videoStateOverlay}
    </div>
  );
}

interface FirstFrameImageProps {
  item: RestaurantMenuItem;
  firstFrameURL: MediaURL | null;
}

function FirstFrameImage(props: FirstFrameImageProps) {
  const analyticsService = useAnalyticsService();

  const [imageBlobURL, setImageBlobURL] = useState<string | undefined>(
    undefined
  );
  useEffect(() => {
    if (!props.firstFrameURL) {
      return;
    }
    VideoService.shared
      .loadFirstFrameImage(
        props.firstFrameURL,
        analyticsService,
        props.item.id,
        props.item.title
      )
      .then((response) => {
        setImageBlobURL(response);
      })
      .catch((error) => {
        console.error(`Failed to fetch first frame image! ${props.item.id}`);
      });

    return () => {
      if (!props.firstFrameURL) {
        return;
      }
      VideoService.shared.revokeFirstFrameImageBlob(
        props.item.id,
        props.firstFrameURL
      );
    };
  }, [props.firstFrameURL]);

  return (
    <NoErrorImage
      className="restaurant-menu-item-video-frame"
      src={imageBlobURL}
      alt="" // Ignore alt for the placeholder image.
    />
  );
}
