import "./RestaurantMenuItemPage.css";

import { ReactNode, useState } from "react";
import { useSpring, animated } from "@react-spring/web";

import { RestaurantMenuItemListFooter } from "./RestaurantMenuItemListFooter";
import { RestaurantMenuItemNonVideoList } from "./RestaurantMenuItemNonVideoList";
import { RestaurantMenuItemViewModel } from "./RestaurantMenuItemPageViewModel";
import { RestaurantMenuItemList } from "../../../components/restaurant_menu_item_list/RestaurantMenuItemList";
import { RestaurantMenuItem } from "../../../models/RestaurantMenuItem";
import { RestaurantMenuSubsection } from "../../../models/RestaurantMenuSubsection";
import { RestaurantMenuItemFooter } from "./RestaurantMenuItemFooter";
import { RestaurantMenuItemFooterCartButton } from "./RestaurantMenuItemFooterCartButton";
import { RestaurantMenuItemVideo } from "./RestaurantMenuItemVideo";
import {
  SwipeablePage,
  SwipeablePageChild,
} from "../../../components/swipeable_page/SwipeablePage";
import { Cart } from "../../../state/Cart";

const CONTAINER_ID = "restaurant-menu-item-container";

export interface RestaurantMenuItemPageContentsProps {
  item: RestaurantMenuItemViewModel;
  nextItem: RestaurantMenuItemViewModel | null;
  previousItem: RestaurantMenuItemViewModel | null;
  nextSectionItem: RestaurantMenuItemViewModel | null;
  previousSectionItem: RestaurantMenuItemViewModel | null;

  onNextItem: () => void;
  onPreviousItem: () => void;
  onNextSectionItem: () => void;
  onPreviousSectionItem: () => void;

  // Each is normalized from -1 to 1.
  onItemOffset: (leftRightOffset: number, upDownOffset: number) => void;

  cart: Cart;
  addCartItem: (item: RestaurantMenuItem) => void;
  onShowCart: () => void;

  displaysOptions: (item: RestaurantMenuItem) => boolean;
  onOptionsSelected: ((item: RestaurantMenuItem) => void) | null;

  totalUserNumberOfSwipes: number;

  overlayElement: ReactNode;
}

export function RestaurantMenuItemPageContents(
  props: RestaurantMenuItemPageContentsProps
) {
  function keyFromViewModel(viewModel: RestaurantMenuItemViewModel): string {
    switch (viewModel.type) {
      case "video":
        return viewModel.item.id;
      case "items":
        return viewModel.itemOne.id;
    }
  }

  function viewFromViewModel(
    viewModel: RestaurantMenuItemViewModel,
    playing: boolean,
    footerText: string | null
  ): ReactNode {
    switch (viewModel.type) {
      case "video":
        return (
          <div className="restaurant-menu-item-container">
            <RestaurantMenuItemVideo
              item={viewModel.item}
              videoURLs={viewModel.item.videoURLs}
              firstFrameURL={viewModel.item.videoFirstFrameURL}
              playing={playing}
            />

            <RestaurantMenuItemFooter
              item={viewModel.item}
              cart={props.cart}
              addCartItem={props.addCartItem}
              onShowCart={props.onShowCart}
              onOptionsSelected={
                props.displaysOptions(viewModel.item) && props.onOptionsSelected
                  ? () => props.onOptionsSelected?.(viewModel.item)
                  : null
              }
              chevronText={footerText}
            />
          </div>
        );
      case "items":
        return (
          <div className="restaurant-menu-item-items-container restaurant-menu-item-container">
            {viewModel.headerText && (
              <div className="restaurant-menu-item-items-header">
                {viewModel.headerText}
              </div>
            )}
            <RestaurantMenuItemNonVideoList
              items={viewModel.items()}
              hasAddons={props.displaysOptions}
              onAddonsTapped={props.onOptionsSelected}
            />
            {props.cart.totalQuantity > 0 && (
              <div className="restaurant-menu-item-footer-cart-button-container">
                <RestaurantMenuItemFooterCartButton
                  cart={props.cart}
                  onShowCart={props.onShowCart}
                />
              </div>
            )}
            {viewModel.footerText && (
              <RestaurantMenuItemListFooter footerText={viewModel.footerText} />
            )}
          </div>
        );
    }
  }

  function convertToSwipeablePageChild(
    viewModel: RestaurantMenuItemViewModel,
    playing: boolean,
    footerText: string | null
  ): SwipeablePageChild {
    return {
      key: keyFromViewModel(viewModel),
      component: viewFromViewModel(viewModel, playing, footerText),
    };
  }

  function footerTextForNumberOfSwipes(
    numberOfSwipes: number,
    isNext: boolean
  ): string | null {
    const numberOfSwipesToReach = numberOfSwipes + (isNext ? 1 : 0);
    switch (numberOfSwipesToReach) {
      case 0:
        return "swipe for more";
      case 1:
        return "keep swiping for more";
      default:
        return null;
    }
  }

  return (
    <SwipeablePage
      item={convertToSwipeablePageChild(
        props.item,
        true,
        footerTextForNumberOfSwipes(props.totalUserNumberOfSwipes, false)
      )}
      bottomItem={
        props.nextItem
          ? convertToSwipeablePageChild(
              props.nextItem,
              false,
              footerTextForNumberOfSwipes(props.totalUserNumberOfSwipes, true)
            )
          : null
      }
      topItem={
        props.previousItem
          ? convertToSwipeablePageChild(
              props.previousItem,
              false,
              footerTextForNumberOfSwipes(props.totalUserNumberOfSwipes, true)
            )
          : null
      }
      rightItem={
        props.nextSectionItem
          ? convertToSwipeablePageChild(
              props.nextSectionItem,
              false,
              footerTextForNumberOfSwipes(props.totalUserNumberOfSwipes, true)
            )
          : null
      }
      leftItem={
        props.previousSectionItem
          ? convertToSwipeablePageChild(
              props.previousSectionItem,
              false,
              footerTextForNumberOfSwipes(props.totalUserNumberOfSwipes, true)
            )
          : null
      }
      onBottomItem={props.onNextItem}
      onTopItem={props.onPreviousItem}
      onRightItem={props.onNextSectionItem}
      onLeftItem={props.onPreviousSectionItem}
      onItemOffset={props.onItemOffset}
      sortByIntKey={true}
      overlayElement={props.overlayElement}
    />
  );
}
