import "./RestaurantMenuItemScrollIndicator.css";

import classNames from "classnames";

interface RestaurantMenuItemScrollIndicatorProps {
  selectedIndex: number;
  numberOfItems: number;
}

export function RestaurantMenuItemScrollIndicator(
  props: RestaurantMenuItemScrollIndicatorProps
) {
  const range = Array(props.numberOfItems).fill(0);

  return (
    <div className="restaurant-menu-item-scroll-indicator-container">
      {range.map((_, index) => (
        <div
          key={index}
          className={classNames("restaurant-menu-item-scroll-indicator-dot", {
            "restaurant-menu-item-scroll-indicator-selected":
              index === props.selectedIndex,
          })}
        />
      ))}
    </div>
  );
}
