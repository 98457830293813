import { RestaurantMenuFilter } from "./RestaurantMenuFilter";
import { RestaurantMenuItem } from "./RestaurantMenuItem";
import { RestaurantMenuSection } from "./RestaurantMenuSection";

export class RestaurantMenu {
  constructor(
    readonly id: string,
    readonly title: string,
    readonly sections: RestaurantMenuSection[]
  ) {}

  applyingFilters(
    filters: RestaurantMenuFilter[],
    filterMenuSections: boolean
  ): RestaurantMenu {
    return new RestaurantMenu(
      this.id,
      this.title,
      this.sections
        .map((section) => section.applyingFilters(filters, filterMenuSections))
        .filter(
          (section) => !filterMenuSections || section.subsections.length > 0
        )
    );
  }

  allItems(): RestaurantMenuItem[] {
    return this.sections.flatMap((section) => section.items);
  }
}
