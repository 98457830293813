import "./RestaurantSearchBar.css";

import { Icon } from "@iconify/react";

export interface RestaurantSearchBarProps {
  search: string;
  onSearchChanged: (newSearch: string) => void;
}

export function RestaurantSearchBar(props: RestaurantSearchBarProps) {
  return (
    <div className="search-bar">
      <Icon icon="bi:search" className="search-bar-search-icon" />
      <input
        className="search-bar-input"
        type="search"
        autoFocus
        placeholder="Search menu items or ingredients"
        value={props.search}
        onChange={(event) => props.onSearchChanged(event.target.value)}
      />
    </div>
  );
}
