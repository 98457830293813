import { ForwardedRef } from "react";

export function setForwardedRef<T>(ref: ForwardedRef<T>, current: T) {
  if (!ref) {
    return;
  }
  if (ref instanceof Function) {
    ref(current);
  } else {
    ref.current = current;
  }
}
