import { devAssert } from "../utils/Assert";

export class Price {
  readonly formattedText =
    "$" + (this.totalCents / 100).toFixed(2).replace(/\.00$/, "");

  private constructor(readonly totalCents: number) {}

  static fromTotalCents(totalCents: number) {
    devAssert(() => totalCents >= 0, "totalCents must be greater than 0");
    return new Price(totalCents);
  }

  static fromDollarsAndCents(dollars: number, cents: number) {
    devAssert(
      () => cents >= 0 && cents < 100,
      "Cents must be between 0 and 99"
    );
    devAssert(() => dollars >= 0, "Dollars must be greater than 0");
    return new Price(dollars * 100 + cents);
  }

  add(price: Price): Price {
    return Price.fromTotalCents(this.totalCents + price.totalCents);
  }

  subtract(price: Price): Price {
    return Price.fromTotalCents(this.totalCents - price.totalCents);
  }

  multiply(multiplier: number): Price {
    return Price.fromTotalCents(this.totalCents * multiplier);
  }
}
