import "./CartPage.css";

import { useSpring, animated } from "@react-spring/web";

import { AddToCartButton } from "../../../components/add_to_cart_button/AddToCartButton";
import {
  SwipeHandler,
  SwipeHandlerBehavior,
} from "../../../components/swipe_handler/SwipeHandler";
import { RestaurantMenuItem } from "../../../models/RestaurantMenuItem";
import { AnalyticsCartEventSource } from "../../../services/analytics/AnalyticsEvent";
import { Cart } from "../../../state/Cart";

export interface CartPageProps {
  cart: Cart;
  addCartItem: (
    item: RestaurantMenuItem,
    source: AnalyticsCartEventSource
  ) => void;
  removeCartItem: (
    item: RestaurantMenuItem,
    source: AnalyticsCartEventSource
  ) => void;
  expanded: boolean;
}

export function CartPage(props: CartPageProps) {
  const [swipeAnimation, swipeAPI] = useSpring(() => ({
    transform: "translateY(0px)",
    immediate: true,
  }));

  return (
    <SwipeHandler
      onSwipeStart={() => {}}
      onSwipeMove={(x, y) => {
        // TODO: Fix swiping.
        // swipeAPI.start({ transform: `translateY(${y}px)` });
      }}
      onSwipeEnd={(x, y, velocityX, velocityY) => {
        // swipeAPI.start({ transform: `translateY(100px)`, immediate: true})
      }}
      behavior={SwipeHandlerBehavior.OneDirection}
    >
      <animated.div
        className="single-column-flex-container size-fill"
        style={swipeAnimation}
      >
        <div className="cart-header-container">
          <h2 className="cart-header">My order</h2>
          {!props.expanded && (
            <div className="cart-header-amount">{props.cart.totalQuantity}</div>
          )}
        </div>
        <div className="flex-fill">
          {props.cart.items
            .slice(0, props.expanded ? props.cart.items.length : 1)
            .map((cartItem, index) => (
              <div key={index} className="cart-item-container">
                <div className="flex-fill cart-item-title-container">
                  <p className="cart-item-title">{cartItem.item.title}</p>
                  {props.expanded && (
                    <p className="cart-item-price">
                      {cartItem.price.formattedText}
                    </p>
                  )}
                </div>
                <div className="single-row-flex-container quantity-container">
                  <AddToCartButton
                    onClick={() =>
                      props.removeCartItem(
                        cartItem.item,
                        AnalyticsCartEventSource.Cart
                      )
                    }
                    remove={true}
                  />
                  <p className="cart-item-quantity">{cartItem.quantity}</p>
                  <AddToCartButton
                    onClick={() =>
                      props.addCartItem(
                        cartItem.item,
                        AnalyticsCartEventSource.Cart
                      )
                    }
                  />
                </div>
              </div>
            ))}
          {props.cart.items.length === 0 && (
            <div className="cart-no-items-text">No items in cart</div>
          )}
        </div>
        {props.expanded && (
          <>
            <div className="cart-total-divider" />
            <div className="cart-total-container">
              <div className="cart-total-title-container">
                <p>Total</p>
                <p className="cart-total-amount">
                  {props.cart.totalPrice.formattedText}
                </p>
              </div>
              <p className="cart-total-subtitle">
                Place your order with server when you’re ready
              </p>
            </div>
          </>
        )}
      </animated.div>
    </SwipeHandler>
  );
}
