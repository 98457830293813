import { useEffect, useState } from "react";

import { FeatureFlagOverrideStorage } from "../local_storage/FeatureFlagOverrideStorage";
import { currentEnvironment, Environment } from "../../utils/Environment";

export const FEATURE_FLAGS: FeatureFlag[] = [];

enum FeatureFlagID {}

enum FeatureFlagStage {
  Off = 0,
  Development = 1,
  Prod = 2,
}

export class FeatureFlag {
  defaultValue: boolean;

  constructor(readonly id: string, readonly stage: FeatureFlagStage) {
    switch (currentEnvironment()) {
      case Environment.Development:
      case Environment.Testing:
        this.defaultValue = stage !== FeatureFlagStage.Off;
        break;
      case Environment.Production:
        this.defaultValue = stage === FeatureFlagStage.Prod;
        break;
    }

    FEATURE_FLAGS.push(this);
  }
}

// Returns the feature flag's value, and whether it has been overridden, and the function to override it.
export function useFeatureFlag(
  flag: FeatureFlag
): [boolean, (newValue: boolean | undefined) => void, boolean | undefined] {
  const overrideStorage = new FeatureFlagOverrideStorage();
  const [currentOverride, setCurrentOverride] = overrideStorage.overrideForFlag(
    flag.id
  );

  const [overrideState, setOverrideState] = useState(currentOverride);
  useEffect(() => {
    setCurrentOverride(overrideState);
  }, [overrideState]);

  return [
    overrideState !== undefined ? overrideState : flag.defaultValue,
    setOverrideState,
    overrideState,
  ];
}

// Flags, alphabetically organized.

export const EXPAND_HEADER_ON_SCROLL = new FeatureFlag(
  "EXPAND_HEADER_ON_SCROLL",
  FeatureFlagStage.Off
);

export const INFINITE_SCROLL_FLAG = new FeatureFlag(
  "INFINITE_SCROLL_FLAG",
  FeatureFlagStage.Prod
);

export const STIFF_SPRING_FLAG = new FeatureFlag(
  "STIFF_SPRING_FLAG",
  FeatureFlagStage.Development
);
