import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BRAND_FONT } from "./models/Font";
import { AmplitudeAnalyticsService } from "./services/analytics/AmplitudeAnalyticsService";
import { AnalyticsServiceMultiDecorator } from "./services/analytics/AnalyticsServiceMultiDecorator";
import { ConsoleLogAnalyticsService } from "./services/analytics/ConsoleLogAnalyticsService";
import { GoogleAnalyticsService } from "./services/analytics/GoogleAnalyticsService";
import { loadFont } from "./utils/font/LoadFont";

// Pre-load the base website font.
loadFont(BRAND_FONT);

// Initialize the analytics service before doing any work.
const analyticsService =
  process.env.REACT_APP_USE_CONSOLE_LOG_ANALYTICS === "1"
    ? new ConsoleLogAnalyticsService()
    : new AnalyticsServiceMultiDecorator([
        new AmplitudeAnalyticsService(),
        new GoogleAnalyticsService(),
      ]);
analyticsService.initialize();

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <App analyticsService={analyticsService} />
    </BrowserRouter>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
