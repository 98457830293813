import "./RestaurantMenuPage.css";

import classNames from "classnames";
import { Fragment, MutableRefObject, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { RestaurantMenuHeader } from "./RestaurantMenuHeader";
import { RestaurantMenuPageVideoPromo } from "./RestaurantMenuPageVideoPromo";
import { RestaurantMenuReachOut } from "./RestaurantMenuReachOut";
import { RestaurantMenuSectionHeader } from "./RestaurantMenuSectionHeader";
import { RestaurantMenuSelectorDropdown } from "./RestaurantMenuSelectorDropdown";
import { RestaurantMenuSelectorModal } from "./RestaurantMenuSelectorModal";
import { RestaurantPageChildParams } from "../RestaurantPageChildParams";
import { CartPage } from "../cart/CartPage";
import { RestaurantFilterPage } from "../filter/RestaurantFilterPage";
import { RestaurantMenuItemDetailsPage } from "../item_details/RestaurantMenuItemDetailsPage";
import { RestaurantSearchPage } from "../search/RestaurantSearchPage";
import { BottomSheet } from "../../../components/bottom_sheet/BottomSheet";
import { CollapsingHeader } from "../../../components/collapsing_header/CollapsingHeader";
import { useDocumentScrollFade } from "../../../components/scroll_fade/ScrollFade";
import { ScrollingSectionSelectorBar } from "../../../components/scrolling_section_selector_bar/ScrollingSectionSelectorBar";
import {
  SwipeablePage,
  SwipeablePageChild,
} from "../../../components/swipeable_page/SwipeablePage";
import { SwipeHandlerBehavior } from "../../../components/swipe_handler/SwipeHandler";
import { RestaurantMenuItemList } from "../../../components/restaurant_menu_item_list/RestaurantMenuItemList";
import { VerticalScrollingContainer } from "../../../components/vertical_scrolling_container/VerticalScrollingContainer";
import { RestaurantMenu } from "../../../models/RestaurantMenu";
import { RestaurantMenuSection } from "../../../models/RestaurantMenuSection";
import { RestaurantMenuItem } from "../../../models/RestaurantMenuItem";
import {
  AnalyticsCartEventSource,
  AnalyticsEvent,
} from "../../../services/analytics/AnalyticsEvent";
import { useAnalyticsService } from "../../../services/analytics/AnalyticsServiceContext";
import { Cart } from "../../../state/Cart";
import { cartEnabled } from "../../../state/feature_flags/Cart";
import {
  EXPAND_HEADER_ON_SCROLL,
  INFINITE_SCROLL_FLAG,
  useFeatureFlag,
} from "../../../state/feature_flags/FeatureFlags";
import { onboardingSeenVideoPromoSettingV2 } from "../../../state/local_storage/OnboardingStorage";
import { useSmoothScroll } from "../../../utils/smooth_scroll/SmoothScroll";

// TODO: Avoid hardcoding.
const TOP_OVERLAY_HEIGHT = 52;

export interface RestaurantMenuPageProps extends RestaurantPageChildParams {
  canSelectItem: (item: RestaurantMenuItem) => boolean;
  scroll: number;
  setScroll: (scroll: number) => void;
}

export function RestaurantMenuPage(props: RestaurantMenuPageProps) {
  const [expandHeaderOnScrollOn] = useFeatureFlag(EXPAND_HEADER_ON_SCROLL);
  const [infiniteScrollOn] = useFeatureFlag(INFINITE_SCROLL_FLAG);

  const analyticsService = useAnalyticsService();

  const [isShowingVideoPromo, setIsShowingVideoPromo] = useState(false);
  useEffect(() => {
    const [seenVideoPromo] = onboardingSeenVideoPromoSettingV2();
    if (!seenVideoPromo) {
      setIsShowingVideoPromo(true);

      analyticsService.logEvent(AnalyticsEvent.shownVideoPromo());
    }
  }, []);
  function dismissVideoPromoPermanently() {
    const [, setSeenVideoPromo] = onboardingSeenVideoPromoSettingV2();
    setSeenVideoPromo(true);

    setIsShowingVideoPromo(false);
  }
  function onVideoPromoClick() {
    dismissVideoPromoPermanently();
  }

  // On appearance, scroll to the previous position.
  useEffect(() => {
    document.documentElement.scrollTop = Math.round(props.scroll);

    // Store the scroll to notify the main page on disappearance.
    let currentScroll = document.documentElement.scrollTop;
    function onScroll() {
      currentScroll = document.documentElement.scrollTop;
    }

    document.addEventListener("scroll", onScroll);
    return () => {
      props.setScroll(currentScroll);
      document.removeEventListener("scroll", onScroll);
    };
  }, []);

  const [selectedDetailsItem, setSelectedDetailsItem] =
    useState<RestaurantMenuItem | null>(null);

  const [isShowingCartModal, setIsShowingCartModal] = useState(false);

  const [isShowingMenuModal, setIsShowingMenuModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [clickedSection, setClickedSection] =
    useState<RestaurantMenuSection | null>(null);

  const topOverlayRef = useRef(null);

  function onItemSelected(item: RestaurantMenuItem) {
    dismissVideoPromoPermanently();
    props.setItem(item, false);
  }

  function createSectionView(
    section: RestaurantMenuSection
  ): SwipeablePageChild {
    return {
      key: section.id,
      component: (
        <VerticalScrollingContainer
          className="restaurant-menu-item-list-single-container"
          scrollPadding="24px 14px 24px 14px"
        >
          <RestaurantMenuItemList
            subsections={section.subsections}
            canSelectItem={props.canSelectItem}
            onItemSelected={onItemSelected}
            displaysOptions={(item) => item.addonText !== null}
            onOptionsSelected={(item) => setSelectedDetailsItem(item)}
            isInCart={props.cart.includes.bind(props.cart)}
            onAddToCart={onAddToCart}
            emptyStateText="No items in this section"
            compactBehavior={(item) => item.videoURLs.length > 0}
            firstItemPromo={[
              <RestaurantMenuPageVideoPromo
                shown={isShowingVideoPromo}
                onClick={onVideoPromoClick}
              />,
              isShowingVideoPromo,
            ]}
            bottomComponent={<RestaurantMenuReachOut />}
          />
        </VerticalScrollingContainer>
      ),
    };
  }

  const currentSectionView: SwipeablePageChild | null =
    props.section && createSectionView(props.section);

  const currentSectionIndex = props.menu.sections.findIndex(
    (section) => props.section?.id === section.id
  );
  const previousSection =
    currentSectionIndex > 0
      ? props.menu.sections[currentSectionIndex - 1]
      : null;
  const previousSectionView = previousSection
    ? createSectionView(previousSection)
    : null;
  const nextSection =
    currentSectionIndex < props.menu.sections.length - 1
      ? props.menu.sections[currentSectionIndex + 1]
      : null;
  const nextSectionView = nextSection ? createSectionView(nextSection) : null;

  const [sectionOffset, setSectionOffset] = useState(0);
  function onItemOffset(leftRightOffset: number, upDownOffset: number) {
    setSectionOffset(-leftRightOffset);
  }

  if (props.search !== null && props.searchedItems !== null) {
    return (
      <RestaurantSearchPage
        {...props}
        onSearchChanged={(newSearch) => props.updateSearch(newSearch, true)}
        onDismissSearch={props.exitSearch}
        search={props.search}
        searchedItems={props.searchedItems}
      />
    );
  }

  const filters = searchParams.get("filters");
  function showFilters() {
    setSearchParams({ filters: "" });
  }

  function dismissFilters() {
    setSearchParams({});
  }

  if (filters !== null) {
    return (
      <RestaurantFilterPage
        onDismissFilters={dismissFilters}
        onFilterClick={props.updateFilter}
        {...props}
      />
    );
  }

  function onAddToCart(item: RestaurantMenuItem) {
    props.addCartItem(item, AnalyticsCartEventSource.ListView);
  }

  function onSectionSelected(section: RestaurantMenuSection) {
    if (infiniteScrollOn) {
      setClickedSection(section);
    } else {
      props.setSection(section);
    }
  }

  const selectedSection = infiniteScrollOn
    ? clickedSection ?? props.section
    : props.section;
  return (
    <div className="restaurant-menu-page-container size-fill">
      <CollapsingHeader
        ref={topOverlayRef}
        enabled={expandHeaderOnScrollOn}
        maximumScrollOffset={TOP_OVERLAY_HEIGHT}
      >
        <RestaurantMenuHeader
          restaurant={props.restaurant}
          isFilterApplied={props.filters.length > 0}
          onSearchClick={() => props.updateSearch("", false)}
          onFilterClick={() => showFilters()}
        />
        {props.restaurant.menus.length > 1 && (
          <RestaurantMenuSelectorDropdown
            currentMenu={props.menu}
            onSelectorClicked={() => setIsShowingMenuModal(true)}
          />
        )}
        {selectedSection && (
          <div
            className={classNames({
              "restaurant-menu-top-stick-container": !expandHeaderOnScrollOn,
            })}
          >
            <ScrollingSectionSelectorBar
              sections={props.menu.sections}
              selectedSection={selectedSection}
              onSectionSelected={onSectionSelected}
              offset={sectionOffset}
              showScrollFade={true}
            />
          </div>
        )}
      </CollapsingHeader>
      {!infiniteScrollOn && currentSectionView && (
        <div className="restaurant-menu-section-list-container">
          <SwipeablePage
            item={currentSectionView}
            bottomItem={null}
            topItem={null}
            rightItem={nextSectionView}
            leftItem={previousSectionView}
            onBottomItem={() => {}}
            onTopItem={() => {}}
            onRightItem={() => nextSection && props.setSection(nextSection)}
            onLeftItem={() =>
              previousSection && props.setSection(previousSection)
            }
            onItemOffset={onItemOffset}
            swipeHandlerBehavior={SwipeHandlerBehavior.LeftRight}
            sortByIntKey={false}
          />
        </div>
      )}
      {infiniteScrollOn && (
        <RestaurantMenuInfiniteScroll
          menu={props.menu}
          clickedSection={clickedSection}
          clearClickedSection={() => setClickedSection(null)}
          cart={props.cart}
          isShowingVideoPromo={isShowingVideoPromo}
          onVideoPromoClick={onVideoPromoClick}
          onAddToCart={onAddToCart}
          canSelectItem={props.canSelectItem}
          onItemSelected={onItemSelected}
          onOptionsSelected={(item) => setSelectedDetailsItem(item)}
          setSection={props.setSection}
          setSectionOffset={setSectionOffset}
          topOverlayHeight={TOP_OVERLAY_HEIGHT}
          topOverlayRef={expandHeaderOnScrollOn ? topOverlayRef : null}
        />
      )}
      {infiniteScrollOn && !props.section && (
        <p className="restaurant-menu-page-empty-text">
          No items matching filters!
        </p>
      )}
      <BottomSheet
        in={selectedDetailsItem !== null}
        dismiss={() => setSelectedDetailsItem(null)}
      >
        {selectedDetailsItem && (
          <RestaurantMenuItemDetailsPage item={selectedDetailsItem} />
        )}
      </BottomSheet>
      <BottomSheet
        in={isShowingMenuModal}
        dismiss={() => setIsShowingMenuModal(false)}
      >
        <RestaurantMenuSelectorModal
          currentMenu={props.menu}
          menus={props.restaurant.menus}
          onMenuSelected={(menu) => {
            props.setMenu(menu);
            setIsShowingMenuModal(false);
          }}
        />
      </BottomSheet>
      {cartEnabled() && (
        <BottomSheet
          in={props.cart.items.length > 0}
          expanded={isShowingCartModal}
          onExpand={() => setIsShowingCartModal(true)}
          dismiss={() => setIsShowingCartModal(false)}
        >
          <CartPage {...props} expanded={isShowingCartModal} />
        </BottomSheet>
      )}
    </div>
  );
}

interface RestaurantMenuInfiniteScrollProps {
  menu: RestaurantMenu;
  clickedSection: RestaurantMenuSection | null;
  clearClickedSection: () => void;
  cart: Cart;
  isShowingVideoPromo: boolean;
  onVideoPromoClick: () => void;
  onAddToCart: (item: RestaurantMenuItem) => void;
  canSelectItem: (item: RestaurantMenuItem) => boolean;
  onItemSelected: (item: RestaurantMenuItem) => void;
  onOptionsSelected: (item: RestaurantMenuItem) => void;
  setSection: (section: RestaurantMenuSection) => void;
  setSectionOffset: (offset: number) => void;
  topOverlayHeight: number;
  topOverlayRef: MutableRefObject<HTMLDivElement | null> | null;
}

function RestaurantMenuInfiniteScroll(
  props: RestaurantMenuInfiniteScrollProps
) {
  const [expandHeaderOnScrollOn] = useFeatureFlag(EXPAND_HEADER_ON_SCROLL);

  const refs = useRef<Map<string, HTMLDivElement | null>>(new Map());
  function setRef(element: HTMLDivElement | null, sectionID: string) {
    refs.current.set(sectionID, element);
  }

  useEffect(() => {
    // Not state (doesn't need re-render), but needs to be tracked.
    let previousScroll = document.documentElement.scrollTop;

    function onScroll() {
      let currentSectionIndex = 0;
      for (let i = 0; i < props.menu.sections.length; i++) {
        const section = props.menu.sections[i];
        const element = refs.current.get(section.id);
        if (!element) {
          console.error(`Failed to find element for section ${section.id}`);
          continue;
        }
        const sectionTop = element.getBoundingClientRect().top;
        const topOverlayBottom =
          props.topOverlayRef?.current?.getBoundingClientRect().bottom ??
          props.topOverlayHeight;
        // Add just the slightest fudge, because scrolling to a rounded value
        // may fail to pass the test otherwise.
        if (sectionTop <= topOverlayBottom + 1) {
          currentSectionIndex = i;
        } else {
          break;
        }
      }
      const currentSection = props.menu.sections[currentSectionIndex];
      props.setSection(currentSection);

      const isScrollingUp =
        previousScroll !== null &&
        document.documentElement.scrollTop < previousScroll;
      const isScrollingDown =
        previousScroll !== null &&
        document.documentElement.scrollTop > previousScroll;

      if (props.clickedSection) {
        const clickedSection = props.clickedSection;
        const clickedSectionIndex = props.menu.sections.findIndex(
          (section) => section.id === clickedSection.id
        );
        if (clickedSectionIndex > currentSectionIndex && isScrollingUp) {
          // If scrolling away from the clicked section, clear it. Must have been manual.
          props.clearClickedSection();
        } else if (
          clickedSectionIndex <= currentSectionIndex &&
          isScrollingDown
        ) {
          // If scrolling away from the clicked section, clear it. Must have been manual.
          props.clearClickedSection();
        }
      }

      previousScroll = document.documentElement.scrollTop;
    }

    document.addEventListener("scroll", onScroll);

    return () => document.removeEventListener("scroll", onScroll);
  }, [
    props.clickedSection,
    props.menu.sections,
    props.setSection,
    props.clearClickedSection,
    props.topOverlayHeight,
    props.topOverlayRef?.current,
  ]);

  const scrollTo = useSmoothScroll();

  useEffect(() => {
    if (!props.clickedSection) {
      return;
    }

    // For each new clicked section, scroll to it.
    const element = refs.current.get(props.clickedSection.id);
    if (!element) {
      console.error(
        `Failed to find element for section ${props.clickedSection.id}`
      );
      return;
    }
    const sectionBounds = element.getBoundingClientRect();
    const sectionTop = sectionBounds.top;

    var clientTop = document.documentElement.clientTop;
    var scrollTop = document.documentElement.scrollTop;
    var sectionTopRelativeToDocument = sectionTop + scrollTop - clientTop;

    const needsToScrollUp =
      sectionTopRelativeToDocument > document.documentElement.scrollTop;
    const expectedTopOverlayBottom = needsToScrollUp
      ? props.topOverlayHeight
      : props.topOverlayRef?.current?.getBoundingClientRect().height ??
        props.topOverlayHeight;

    scrollTo(sectionTopRelativeToDocument - expectedTopOverlayBottom);
  }, [props.clickedSection]);

  const [topFade, , bottomFade] = useDocumentScrollFade({
    topOffset: TOP_OVERLAY_HEIGHT,
    widthPadding: 22,
  });

  return (
    <div className="restaurant-menu-section-infinite-list-container">
      {!expandHeaderOnScrollOn && topFade}
      {props.menu.sections.map((section, index) => (
        <div
          key={section.id}
          ref={(element) => setRef(element, section.id)}
          className="restaurant-menu-section-container"
        >
          <RestaurantMenuSectionHeader
            title={section.title}
            usesBottomPadding={section.subsections.some(
              (subsection) => !subsection.title
            )}
          />
          <RestaurantMenuItemList
            key={section.id}
            subsections={section.subsections}
            canSelectItem={props.canSelectItem}
            onItemSelected={props.onItemSelected}
            displaysOptions={(item) => item.addonText !== null}
            onOptionsSelected={props.onOptionsSelected}
            isInCart={props.cart.includes.bind(props.cart)}
            onAddToCart={props.onAddToCart}
            emptyStateText="No items in this section"
            compactBehavior={(item) => item.videoURLs.length > 0}
            firstItemPromo={
              index === 0
                ? [
                    <RestaurantMenuPageVideoPromo
                      shown={props.isShowingVideoPromo}
                      onClick={props.onVideoPromoClick}
                    />,
                    props.isShowingVideoPromo,
                  ]
                : undefined
            }
          />
        </div>
      ))}

      <RestaurantMenuReachOut />

      {bottomFade}
    </div>
  );
}
