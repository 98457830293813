import { AnalyticsEvent, AnalyticsEventProperties } from "./AnalyticsEvent";
import { AnalyticsService } from "./AnalyticsService";

export class NoOpAnalyticsService implements AnalyticsService {
  initialize() {}

  logEvent(event: AnalyticsEvent) {}

  setRestaurant(id: string | null, title: string | null) {}
}
