import { useCallback, useState } from "react";

import { Price } from "../models/Price";
import { RestaurantMenuItem } from "../models/RestaurantMenuItem";
import { devAssertionFailure } from "../utils/Assert";

export class CartItem {
  readonly price = (this.item.price ?? Price.fromTotalCents(0)).multiply(
    this.quantity
  );

  constructor(readonly item: RestaurantMenuItem, readonly quantity: number) {}
}

export class Cart {
  readonly totalPrice = this.items.reduce(
    (total, item) => total.add(item.price),
    Price.fromTotalCents(0)
  );

  readonly totalQuantity = this.items.reduce(
    (total, item) => total + item.quantity,
    0
  );

  constructor(readonly items: readonly CartItem[]) {}

  includes(item: RestaurantMenuItem) {
    return this.items.map((cartItem) => cartItem.item.id).includes(item.id);
  }
}

export const addMenuItemToCart = (cart: Cart, item: RestaurantMenuItem) => {
  const index = cart.items.findIndex(
    (cartItem) => cartItem.item.id === item.id
  );
  const newItems = cart.items.slice();
  if (index !== -1) {
    newItems[index] = new CartItem(item, cart.items[index].quantity + 1);
  } else {
    newItems.push(new CartItem(item, 1));
  }
  return new Cart(newItems);
};

export const removeMenuItemFromCart = (
  cart: Cart,
  item: RestaurantMenuItem
) => {
  const index = cart.items.findIndex(
    (cartItem) => cartItem.item.id === item.id
  );
  if (index === -1) {
    devAssertionFailure("Attempting to remove item not in cart!");
    return cart;
  }
  const newItems = cart.items.slice();
  if (newItems[index].quantity > 1) {
    newItems[index] = new CartItem(item, cart.items[index].quantity - 1);
  } else {
    newItems.splice(index, 1);
  }
  return new Cart(newItems);
};

/**
 * Returns [cart, addCartItem, removeCartItem].
 */
export function useCart(): [
  Cart,
  (item: RestaurantMenuItem) => void,
  (item: RestaurantMenuItem) => void
] {
  const [cart, setCart] = useState(new Cart([]));
  const addMenuItem = useCallback(
    (item: RestaurantMenuItem) => {
      const newCart = addMenuItemToCart(cart, item);
      setCart(newCart);
    },
    [cart]
  );
  const removeMenuItem = useCallback(
    (item: RestaurantMenuItem) => {
      const newCart = removeMenuItemFromCart(cart, item);
      setCart(newCart);
    },
    [cart]
  );
  return [cart, addMenuItem, removeMenuItem];
}
