import "./SwipeablePage.css";

import { ReactNode, useEffect, useState } from "react";
import { animated, config, useSpring } from "@react-spring/web";

import {
  SwipeHandler,
  SwipeHandlerBehavior,
} from "../swipe_handler/SwipeHandler";
import {
  STIFF_SPRING_FLAG,
  useFeatureFlag,
} from "../../state/feature_flags/FeatureFlags";

const X_SWIPE_THRESHOLD = 1;

const MAX_EDGE_ANIMATION = 150;

export interface SwipeablePageChild {
  key: string;
  component: ReactNode;
}

export interface SwipeablePageProps {
  item: SwipeablePageChild;
  bottomItem: SwipeablePageChild | null;
  topItem: SwipeablePageChild | null;
  rightItem: SwipeablePageChild | null;
  leftItem: SwipeablePageChild | null;

  onBottomItem: () => void;
  onTopItem: () => void;
  onRightItem: () => void;
  onLeftItem: () => void;

  // Each is normalized from -1 to 1.
  onItemOffset: (leftRightOffset: number, upDownOffset: number) => void;

  swipeHandlerBehavior?: SwipeHandlerBehavior;

  sortByIntKey: boolean;

  overlayElement?: ReactNode;
}

export function SwipeablePage(props: SwipeablePageProps) {
  function previousTransform(x: number, y: number) {
    return `translateY(-100%) translateY(${y}px) translateX(${x}px)`;
  }
  function currentTransform(x: number, y: number) {
    return `translateY(${y}px) translateX(${x}px)`;
  }
  function nextTransform(x: number, y: number) {
    return `translateY(100%) translateY(${y}px) translateX(${x}px)`;
  }
  function previousSectionTransform(x: number, y: number) {
    return `translateY(${y}px) translateX(-100%) translateX(${x}px)`;
  }
  function nextSectionTransform(x: number, y: number) {
    return `translateY(${y}px) translateX(100%) translateX(${x}px)`;
  }

  const [stiffSpringEnabled] = useFeatureFlag(STIFF_SPRING_FLAG);
  const springConfig = stiffSpringEnabled
    ? { mass: 1, tension: 240, friction: 28 }
    : config.default;
  const [previousAnimation, previousAPI] = useSpring(() => ({
    transform: previousTransform(0, 0),
    config: springConfig,
    immediate: true,
  }));
  const [currentAnimation, currentAPI] = useSpring(() => ({
    transform: currentTransform(0, 0),
    config: springConfig,
    immediate: true,
  }));
  const [nextAnimation, nextAPI] = useSpring(() => ({
    transform: nextTransform(0, 0),
    config: springConfig,
    immediate: true,
  }));
  const [previousSectionAnimation, previousSectionAPI] = useSpring(() => ({
    transform: previousSectionTransform(0, 0),
    config: springConfig,
    immediate: true,
  }));
  const [nextSectionAnimation, nextSectionAPI] = useSpring(() => ({
    transform: nextSectionTransform(0, 0),
    config: springConfig,
    immediate: true,
  }));

  const [isXSwipe, setIsXSwipe] = useState(false);

  function animateToOffset(
    x: number,
    y: number,
    immediate: boolean,
    onRest?: () => void,
    onStart?: () => void
  ) {
    previousAPI.start({ transform: previousTransform(x, y), immediate });
    currentAPI.start({
      transform: currentTransform(x, y),
      immediate,
      onRest: () => {
        setIsXSwipe(x > X_SWIPE_THRESHOLD || x < -X_SWIPE_THRESHOLD);
        onRest?.();
      },
      onStart,
    });
    nextAPI.start({ transform: nextTransform(x, y), immediate });
    previousSectionAPI.start({
      transform: previousSectionTransform(x, y),
      immediate,
    });
    nextSectionAPI.start({ transform: nextSectionTransform(x, y), immediate });
  }

  function onDrag(x: number, y: number) {
    animateToOffset(x, y, true);

    const documentWidth = document.documentElement.offsetWidth;
    props.onItemOffset(x / documentWidth, y / documentWidth);
  }

  function endDrag(
    x: number,
    y: number,
    velocityX: number,
    velocityY: number,
    forceUp: boolean,
    forceDown: boolean
  ) {
    let completion: (() => void) | null = null;

    // Assume the user will on average swipe with the same velocity for a second more.
    // TODO: Fine-tune.
    const velocityMultiplier = 1000;
    const naturalYEnd = y + velocityY * velocityMultiplier;
    const documentHeight = document.documentElement.clientHeight;
    let targetYDrag = 0;
    if (props.topItem && (forceUp || naturalYEnd > documentHeight / 2)) {
      completion = props.onTopItem;
      targetYDrag = documentHeight;
    }
    if (props.bottomItem && (forceDown || naturalYEnd < -documentHeight / 2)) {
      completion = props.onBottomItem;
      targetYDrag = -documentHeight;
    }

    const naturalXEnd = x + velocityX * velocityMultiplier;
    const documentWidth = document.documentElement.offsetWidth;
    let targetXDrag = 0;
    if (props.leftItem && naturalXEnd > documentWidth / 2) {
      completion = props.onLeftItem;
      targetXDrag = documentWidth;
    }
    if (props.rightItem && naturalXEnd < -documentWidth / 2) {
      completion = props.onRightItem;
      targetXDrag = -documentWidth;
    }

    props.onItemOffset(
      targetXDrag / documentWidth,
      targetYDrag / documentHeight
    );

    animateToOffset(targetXDrag, targetYDrag, false, () => {
      // TODO: Handle new swipe cancelling old animation.
      animateToOffset(0, 0, true, undefined, () => {
        props.onItemOffset(0, 0);
        completion !== null && completion();
      });
    });
  }

  // TODO: Figure out animation type.
  function animatedView(child: SwipeablePageChild, animation: any) {
    return (
      <animated.div
        key={child.key}
        className="swipeable-page"
        style={animation}
      >
        {child.component}
      </animated.div>
    );
  }

  const isTopAndLeftSameKey = props.leftItem?.key === props.topItem?.key;
  const isBottomAndRightSameKey =
    props.rightItem?.key === props.bottomItem?.key;

  const showLeftItem = !isTopAndLeftSameKey || isXSwipe;
  const showTopItem = !isTopAndLeftSameKey || !isXSwipe;
  const showRightItem = !isBottomAndRightSameKey || isXSwipe;
  const showBottomItem = !isBottomAndRightSameKey || !isXSwipe;

  const subviews: [SwipeablePageChild, any][] = [];
  if (showLeftItem && props.leftItem) {
    subviews.push([props.leftItem, previousSectionAnimation]);
  }
  if (showRightItem && props.rightItem) {
    subviews.push([props.rightItem, nextSectionAnimation]);
  }
  if (showTopItem && props.topItem) {
    subviews.push([props.topItem, previousAnimation]);
  }
  if (showBottomItem && props.bottomItem) {
    subviews.push([props.bottomItem, nextAnimation]);
  }
  subviews.push([props.item, currentAnimation]);

  return (
    <SwipeHandler
      onSwipeStart={() => {}}
      onSwipeMove={onDrag}
      onSwipeEnd={endDrag}
      behavior={props.swipeHandlerBehavior ?? SwipeHandlerBehavior.OneDirection}
      cancelWheel={true}
    >
      {
        // Sort elements so that re-ordering DOM manipulation is avoided.
        subviews
          .sort((one, two) => {
            if (!props.sortByIntKey) {
              return 0;
            }
            // TODO: Check for existance of parseInt for the given key.
            return parseInt(one[0].key) - parseInt(two[0].key);
          })
          .map((subview) => animatedView(subview[0], subview[1]))
      }
      {props.overlayElement}
    </SwipeHandler>
  );
}
