import { createContext, useContext } from "react";
import { useSearchParams } from "react-router-dom";

import { AnalyticsService } from "./AnalyticsService";
import { ConsoleLogAnalyticsService } from "./ConsoleLogAnalyticsService";
import { NoOpAnalyticsService } from "./NoOpAnalyticsService";

const NO_ANALYTICS_SEARCH_PARAM = "noanalytics";

const NO_OP_ANALYTICS_SERVICE = new NoOpAnalyticsService();

export const AnalyticsServiceContext = createContext<AnalyticsService>(
  new ConsoleLogAnalyticsService()
);

export function useAnalyticsService(): AnalyticsService {
  const analyticsService = useContext(AnalyticsServiceContext);

  // To prevent the monitoring script from logging analytics, allow it to
  // specify a param preventing analytics.
  // Note: this is not foolproof as most navigation obliterates search params.
  // But, it works for our use case.
  let [searchParams] = useSearchParams();
  const noAnalyticsParam = searchParams.get(NO_ANALYTICS_SEARCH_PARAM);
  if (noAnalyticsParam !== null) {
    return NO_OP_ANALYTICS_SERVICE;
  }

  return analyticsService;
}
