import "./RestaurantMenuSelectorDropdown.css";

import { RestaurantMenu } from "../../../models/RestaurantMenu";

interface RestaurantMenuSelectorDropdownProps {
  currentMenu: RestaurantMenu;
  onSelectorClicked: () => void;
}

export function RestaurantMenuSelectorDropdown(
  props: RestaurantMenuSelectorDropdownProps
) {
  return (
    <>
      <button
        className="restaurant-menu-selector"
        onClick={props.onSelectorClicked}
      >
        <div className="restaurant-menu-selector-container">
          <p className="restaurant-menu-selector-title">
            {props.currentMenu.title}
          </p>
          <svg
            className="restaurant-menu-selector-chevron"
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L5 6L9 1"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      <div className="restaurant-menu-selector-separator" />
    </>
  );
}
