import { booleanOrUndefinedLocalStorageSetting } from "./LocalStorage";

const FEATURE_FLAG_OVERRIDE_PREFIX = "FEATURE_FLAG_OVERRIDE_";

export class FeatureFlagOverrideStorage {
  overrideForFlag(
    featureFlagID: string
  ): [boolean | undefined, (newValue: boolean | undefined) => void] {
    return booleanOrUndefinedLocalStorageSetting(
      FEATURE_FLAG_OVERRIDE_PREFIX + featureFlagID
    );
  }
}
