import "./RestaurantMenuItemFooter.css";

import { RestaurantMenuItemNonVideoRow } from "./RestaurantMenuItemNonVideoRow";
import { RestaurantMenuItemFooterCartButton } from "./RestaurantMenuItemFooterCartButton";
import { RestaurantMenuItemListFooter } from "./RestaurantMenuItemListFooter";
import { AddToCartButton } from "../../../components/add_to_cart_button/AddToCartButton";
import { OptionsButton } from "../../../components/options_button/OptionsButton";
import { RestaurantMenuItem } from "../../../models/RestaurantMenuItem";
import { Cart } from "../../../state/Cart";
import { cartEnabled } from "../../../state/feature_flags/Cart";

export interface RestaurantMenuItemFooterProps {
  item: RestaurantMenuItem;
  cart: Cart;
  addCartItem: (item: RestaurantMenuItem) => void;
  onShowCart: () => void;
  onOptionsSelected: (() => void) | null;
  chevronText: string | null;
}

export function RestaurantMenuItemFooter(props: RestaurantMenuItemFooterProps) {
  return (
    <div className="restaurant-menu-item-footer-container">
      <div className="restaurant-menu-item-footer">
        <div className="restaurant-menu-item-footer-background" />
        <div className="restaurant-menu-item-footer-text-container">
          <RestaurantMenuItemNonVideoRow
            item={props.item}
            onAddonsTapped={props.onOptionsSelected}
          />
        </div>
      </div>
      {props.chevronText && (
        <RestaurantMenuItemListFooter footerText={props.chevronText} />
      )}
    </div>
  );
}
