import { devAssertionFailure } from "./Assert";

export enum Environment {
  Development = 1,
  Production = 2,
  Testing = 3,
}

export function currentEnvironment(): Environment {
  switch (process.env.NODE_ENV) {
    case "development":
      return Environment.Development;
    case "production":
      return Environment.Production;
    case "test":
      return Environment.Testing;
    default:
      devAssertionFailure(`Unexpected NODE_ENV: ${process.env.NODE_ENV}`);
      return Environment.Development;
  }
}
