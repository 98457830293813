import "./RestaurantMenuItemList.css";

import { Fragment, ReactNode, useRef, useState } from "react";

import { RestaurantMenuItemListSubsectionHeader } from "./RestaurantMenuItemListSubsectionHeader";
import { RestaurantMenuItemRow } from "./RestaurantMenuItemRow";
import { RestaurantMenuItem } from "../../models/RestaurantMenuItem";
import { RestaurantMenuSubsection } from "../../models/RestaurantMenuSubsection";
import { useScrollFade } from "../scroll_fade/ScrollFade";

export interface RestaurantMenuItemListProps {
  subsections: RestaurantMenuSubsection[];
  canSelectItem: (item: RestaurantMenuItem) => boolean;
  onItemSelected: ((item: RestaurantMenuItem) => void) | null;
  displaysOptions: (item: RestaurantMenuItem) => boolean;
  onOptionsSelected: ((item: RestaurantMenuItem) => void) | null;
  isInCart: (item: RestaurantMenuItem) => boolean;
  onAddToCart: (item: RestaurantMenuItem) => void;
  emptyStateText: string;
  compactBehavior:
    | "compactByDefault"
    | "notCompact"
    | ((item: RestaurantMenuItem) => boolean);
  firstItemPromo?: [ReactNode, boolean];
  bottomComponent?: ReactNode;
}

export function RestaurantMenuItemList(props: RestaurantMenuItemListProps) {
  const compactByDefault = props.compactBehavior === "compactByDefault";
  const [expandedItemID, setExpandedItemID] = useState<string | null>(null);

  function onItemSelected(item: RestaurantMenuItem) {
    props.onItemSelected?.(item);

    setExpandedItemID(item.id);
  }

  function isCompact(item: RestaurantMenuItem): boolean {
    if (props.compactBehavior === "compactByDefault") {
      return expandedItemID !== item.id;
    }

    if (props.compactBehavior === "notCompact") {
      return false;
    }

    return props.compactBehavior(item);
  }

  const allItems = props.subsections.flatMap((subsection) => subsection.items);
  let promoIndex: [number, number] | null = null;
  if (props.firstItemPromo) {
    for (let i = 0; i < props.subsections.length; i++) {
      for (let j = 0; j < props.subsections[i].items.length; j++) {
        const item = props.subsections[i].items[j];
        if (item.videoURLs.length > 0) {
          promoIndex = [i, j];
          break;
        }
      }
    }
  }

  return (
    <div className="restaurant-menu-item-list">
      {props.subsections.map((subsection, subsectionIndex) => (
        <Fragment key={subsection.id}>
          {subsection.title && subsection.title.length > 0 && (
            <RestaurantMenuItemListSubsectionHeader
              title={subsection.title}
              paddingTopOverride={subsectionIndex === 0 ? 8 : null}
            />
          )}

          <div className="restaurant-menu-subsection-items-container">
            {subsection.items.map((item, itemIndex) => (
              <RestaurantMenuItemRow
                key={item.id}
                item={item}
                onOptionsSelected={
                  props.displaysOptions(item) ? props.onOptionsSelected : null
                }
                isInCart={props.isInCart(item)}
                onItemSelected={
                  props.canSelectItem(item) || compactByDefault
                    ? onItemSelected
                    : null
                }
                onAddToCart={props.onAddToCart}
                isCompact={isCompact(item)}
                promo={
                  promoIndex &&
                  subsectionIndex === promoIndex[0] &&
                  itemIndex === promoIndex[1]
                    ? props.firstItemPromo
                    : undefined
                }
                showVideoThumbnail={itemIndex === 0}
              />
            ))}
          </div>
        </Fragment>
      ))}
      {allItems.length === 0 && props.emptyStateText && (
        <p className="restaurant-menu-page-empty-text">
          {props.emptyStateText}
        </p>
      )}
      {props.bottomComponent}
    </div>
  );
}
