import "./AddToCartButton.css";

import classNames from "classnames";
import { MouseEvent } from "react";

export interface AddToCartButtonProps {
  onClick: () => void;
  remove?: boolean;
  large?: boolean;
}

export function AddToCartButton(props: AddToCartButtonProps) {
  function handleClick(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    props.onClick();
  }

  return (
    <button
      className={classNames("restaurant-menu-item-row-cart-button", {
        "restaurant-menu-item-row-cart-button-small": !(props.large ?? false),
        "restaurant-menu-item-row-cart-button-large": props.large ?? false,
      })}
      onClick={handleClick}
    >
      {props.remove === true ? "-" : "+"}
    </button>
  );
}
