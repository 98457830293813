import "./SwipeHandler.css";

import {
  PointerEvent,
  ReactNode,
  WheelEvent,
  useEffect,
  useRef,
  useState,
} from "react";

import { devAssertionFailure } from "../../utils/Assert";

// The number of milliseconds to track touches for to calculate velocity.
const TOUCH_VELOCITY_TRACKING_MILLIS = 100;

// The distance that a swipe must move to be considered a swipe in a direction.
const MOVE_THRESHOLD = 10;

// If a swipe originates this far from the edge, avoid handling the swipe to
// avoid double action (swipe-to-go-back and touch handling).
// Experimentally determined (iPhone 12 Pro, iOS 15.3.1).
const EDGE_SWIPE_THRESHOLD = 32;

// The debounce timer for scrolling.
const SCROLL_DEBOUNCE_WAIT = 150;

// The threshold after which a scroll is completely ignored and considered to have completed.
const SCROLL_THRESHOLD_Y = 240;

// The amount to look backward to see if this is an inertial wheel event, which
// is when the scroll continues after the user has release the wheel/trackpad.
const REQUIRED_SCROLL_GAP = 200;

// If a scroll is more than this multiplier greater than any scroll within the
// REQUIRED_SCROLL_GAP.
const INERTIAL_SCROLL_MULTIPLIER_THRESHOLD = 2.5;

// The maximum value all values can have to be considered inertial.
const INERTIAL_SCROLL_MAX_VALUE = 10;

// The number of consecutive values greater than the multiplier times the
// median of the previous values needed to override an inertial scroll.
const INERTIAL_OVERRIDE_NUM_CONSECUTIVE_INCREASE = 3;

const SWIPE_HANDLER_CLASS_NAME = "swipe-handler";

class TouchState {
  constructor(
    readonly x: number,
    readonly y: number,
    readonly timestamp: number
  ) {}
}

class WheelState {
  constructor(readonly deltaY: number, readonly timestamp: number) {}
}

export enum SwipeHandlerBehavior {
  // Waits for the swipe to pass a certain threshold in one direction and then
  // locks the swipe to that direction.
  OneDirection,

  // Only allows left-right swipes.
  LeftRight,
}

export interface UseSwipeHandlerProps {
  onSwipeStart: () => void;
  /* The total movement. */
  onSwipeMove: (x: number, y: number) => void;
  onSwipeEnd: (
    x: number,
    y: number,
    velocityX: number,
    velocityY: number,
    forceUp: boolean,
    forceDown: boolean
  ) => void;
  behavior: SwipeHandlerBehavior;
}

export interface SwipeHandlingProps {
  onPointerDown: (event: PointerEvent) => void;
  onPointerMove: (event: PointerEvent) => void;
  onPointerUp: (event: PointerEvent) => void;
  onPointerCancel: (event: PointerEvent) => void;
  onPointerLeave: (event: PointerEvent) => void;
  onWheel: (event: WheelEvent) => void;
}

export function useSwipeHandler(
  props: UseSwipeHandlerProps
): SwipeHandlingProps {
  const [isPointerDown, setIsPointerDown] = useState(false);
  const [touchStates, setTouchStates] = useState<TouchState[]>([]);
  const [isXSwipe, setIsXSwipe] = useState(false);
  const [isYSwipe, setIsYSwipe] = useState(false);
  const [isEdgeSwipe, setIsEdgeSwipe] = useState(false);
  const [isHandlingScroll, setIsHandlingScroll] = useState(false);
  const [wheelStates, setWheelStates] = useState<WheelState[]>([]);

  function setInitialState(newX: number, newY: number) {
    setTouchStates([new TouchState(newX, newY, Date.now())]);
  }

  function updateState(newX: number, newY: number) {
    if ((!isPointerDown && !isHandlingScroll) || isEdgeSwipe) {
      return;
    }
    if (touchStates.length === 0) {
      devAssertionFailure("touchStates.length === 0");
      return;
    }
    const firstTouchState = touchStates[0];
    setTouchStates(
      touchStates.concat([new TouchState(newX, newY, Date.now())])
    );

    if (isXSwipe) {
      props.onSwipeMove(newX - firstTouchState.x, 0);
    } else if (isYSwipe) {
      if (props.behavior === SwipeHandlerBehavior.LeftRight) {
        return;
      }
      props.onSwipeMove(0, newY - firstTouchState.y);
    } else {
      // The touches may be far apart - check which one has moved more rather than prioritizing either X or Y.
      const isXMoveGreater =
        Math.abs(newX - firstTouchState.x) > Math.abs(newY - firstTouchState.y);
      if (
        Math.abs(newX - firstTouchState.x) > MOVE_THRESHOLD &&
        isXMoveGreater
      ) {
        setIsXSwipe(true);

        props.onSwipeStart();
      } else if (
        Math.abs(newY - firstTouchState.y) > MOVE_THRESHOLD &&
        !isXMoveGreater
      ) {
        setIsYSwipe(true);

        if (props.behavior === SwipeHandlerBehavior.LeftRight) {
          return;
        }
        props.onSwipeStart();
      }
    }
  }

  function sendSwipeEnd(forceUp: boolean = false, forceDown: boolean = false) {
    if (isEdgeSwipe) {
      return;
    }
    if (touchStates.length === 0 || (!isXSwipe && !isYSwipe)) {
      return;
    }
    if (isYSwipe && props.behavior === SwipeHandlerBehavior.LeftRight) {
      return;
    }
    const firstTouchState = touchStates[0];
    const lastTouchState = touchStates[touchStates.length - 1];
    const timestamp = Date.now();
    let velocityIndex =
      touchStates.findIndex(
        (state) => timestamp - state.timestamp < TOUCH_VELOCITY_TRACKING_MILLIS
      ) - 1;
    if (velocityIndex < 0) {
      velocityIndex = 0;
    }
    let velocityState = touchStates[velocityIndex];
    let velocityX = 0;
    let velocityY = 0;
    if (timestamp !== velocityState.timestamp) {
      velocityX =
        (lastTouchState.x - velocityState.x) /
        (timestamp - velocityState.timestamp);
      velocityY =
        (lastTouchState.y - velocityState.y) /
        (timestamp - velocityState.timestamp);
    }
    props.onSwipeEnd(
      isXSwipe ? lastTouchState.x - firstTouchState.x : 0,
      isYSwipe ? lastTouchState.y - firstTouchState.y : 0,
      isXSwipe ? velocityX : 0,
      isYSwipe ? velocityY : 0,
      forceUp,
      forceDown
    );
  }

  function cleanUpState() {
    setIsPointerDown(false);
    setIsEdgeSwipe(false);
    setTouchStates([]);
    setIsXSwipe(false);
    setIsYSwipe(false);
    setIsHandlingScroll(false);
    // Explicitly don't call setWheelStates([]), it is used to de-duplicate scrolls.
  }

  function preventEventsOnlyForSwipe(event: PointerEvent) {
    if (isXSwipe || isYSwipe) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  function onPointerDown(event: PointerEvent) {
    if (!event.isPrimary) {
      return;
    }
    setIsPointerDown(true);
    setIsEdgeSwipe(
      event.clientX < EDGE_SWIPE_THRESHOLD ||
        event.clientX >
          document.documentElement.offsetWidth - EDGE_SWIPE_THRESHOLD
    );
    setInitialState(event.clientX, event.clientY);
  }

  function onPointerMove(event: PointerEvent) {
    if (!event.isPrimary || !isPointerDown) {
      return;
    }
    updateState(event.clientX, event.clientY);
  }

  function onPointerUp(event: PointerEvent) {
    if (!event.isPrimary || !isPointerDown) {
      return;
    }

    cleanUpState();
    sendSwipeEnd();

    // Avoid "clicking" elements if swiping.
    preventEventsOnlyForSwipe(event);
  }

  function onPointerCancel(event: PointerEvent) {
    if (!event.isPrimary || !isPointerDown) {
      return;
    }

    cleanUpState();
    sendSwipeEnd();
  }

  function onPointerLeave(event: PointerEvent) {
    if (!event.isPrimary || !isPointerDown) {
      return;
    }

    cleanUpState();
    sendSwipeEnd();
  }

  function onWheel(event: WheelEvent) {
    setWheelStates(
      wheelStates.concat([new WheelState(event.deltaY, Date.now())])
    );
    if (!isHandlingScroll) {
      const wheelStatesLength = wheelStates.length;

      if (wheelStatesLength > 0) {
        if (
          wheelStatesLength <
          INERTIAL_OVERRIDE_NUM_CONSECUTIVE_INCREASE + 1
        ) {
          return;
        }

        const valuesToConsider = [Math.abs(event.deltaY)];
        for (
          let i = 0;
          i < INERTIAL_OVERRIDE_NUM_CONSECUTIVE_INCREASE - 1;
          i++
        ) {
          valuesToConsider.push(
            Math.abs(wheelStates[wheelStatesLength - 1 - i].deltaY)
          );
        }

        // Find the scroll values in the recent period.
        const values = [];
        for (
          let i = INERTIAL_OVERRIDE_NUM_CONSECUTIVE_INCREASE - 1;
          i < wheelStatesLength;
          i++
        ) {
          const state = wheelStates[wheelStatesLength - 1 - i];
          if (i > 0 && Date.now() - state.timestamp > REQUIRED_SCROLL_GAP) {
            break;
          }
          const value = Math.abs(state.deltaY);
          values.push(Math.abs(state.deltaY));
        }

        if (values.length === 0) {
          return;
        }

        // If the current scroll is more than the multiplier required, this is
        // considered a new scroll.
        const median = findMedian(values);
        let isValidInertialScroll = true;
        for (const valueToConsider of valuesToConsider) {
          if (valueToConsider < INERTIAL_SCROLL_MULTIPLIER_THRESHOLD * median) {
            isValidInertialScroll = false;
            break;
          }
        }
        if (isValidInertialScroll) {
          setWheelStates([new WheelState(event.deltaY, Date.now())]);
          setIsHandlingScroll(true);
          // Pretend touch is at 0, and deltas will be from there.
          setInitialState(0, 0);
        }

        return;
      }

      setIsHandlingScroll(true);
      // Pretend touch is at 0, and deltas will be from there.
      setInitialState(0, 0);
      return;
    }

    // TODO: Handle deltaX.
    const SCALE = -1;
    if (touchStates.length === 0) {
      devAssertionFailure("touchStates.length === 0");
      return;
    }
    const lastTouchState = touchStates[touchStates.length - 1];
    const newY = lastTouchState.y + event.deltaY * SCALE;
    updateState(0, newY);

    if (newY > SCROLL_THRESHOLD_Y || newY < -SCROLL_THRESHOLD_Y) {
      cleanUpState();
      sendSwipeEnd(newY > SCROLL_THRESHOLD_Y, newY < -SCROLL_THRESHOLD_Y);
    }
  }

  const lastScrollTimestamp =
    wheelStates.length > 0
      ? wheelStates[wheelStates.length - 1].timestamp
      : null;
  useEffect(() => {
    if (!lastScrollTimestamp) {
      return;
    }
    let wait = lastScrollTimestamp + SCROLL_DEBOUNCE_WAIT - Date.now();
    if (wait < 0) {
      wait = 0;
    }
    const timeout = setTimeout(() => {
      setWheelStates([]);
      if (isHandlingScroll) {
        cleanUpState();
        sendSwipeEnd();
      }
    }, wait);

    return () => clearTimeout(timeout);
  }, [lastScrollTimestamp, isHandlingScroll]);

  return {
    onPointerDown,
    onPointerMove,
    onPointerUp,
    onPointerCancel,
    onPointerLeave,
    onWheel,
  };
}

export interface SwipeHandlerProps extends UseSwipeHandlerProps {
  cancelWheel?: boolean;
  children: ReactNode;
}

export function SwipeHandler(props: SwipeHandlerProps) {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (props.cancelWheel !== true) {
      return;
    }
    const div = ref.current;
    if (!div) {
      return;
    }

    function cancelWheel(event: Event) {
      event.preventDefault();
    }

    div.addEventListener("wheel", cancelWheel, { passive: false });
    return () => div.removeEventListener("wheel", cancelWheel);
  }, [ref.current]);

  const swipeProps = useSwipeHandler(props);
  return (
    <div ref={ref} className={SWIPE_HANDLER_CLASS_NAME} {...swipeProps}>
      {props.children}
    </div>
  );
}

function findMedian(values: number[]): number {
  if (values.length === 0) {
    throw new Error("Need values to calculate median");
  }

  const sorted = [...values].sort((a, b) => a - b);
  if (sorted.length % 2 === 0) {
    return sorted[sorted.length / 2];
  }

  const half = Math.floor(sorted.length / 2);
  return (sorted[half - 1] + sorted[half]) / 2;
}
