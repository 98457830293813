import "./RestaurantMenuReachOut.css";

import { Link } from "react-router-dom";

import { AnalyticsEvent } from "../../../services/analytics/AnalyticsEvent";
import { useAnalyticsService } from "../../../services/analytics/AnalyticsServiceContext";

interface RestaurantMenuReachOutProps {}

export function RestaurantMenuReachOut(props: RestaurantMenuReachOutProps) {
  const analyticsService = useAnalyticsService();
  function onClick() {
    analyticsService.logEvent(AnalyticsEvent.reachOutClick());
  }

  return (
    <div className="restaurant-menu-reach-out-container">
      <p className="restaurant-menu-reach-out-text">
        Interested for videos in your menu?
      </p>
      <a
        className="restaurant-menu-reach-out-button"
        href="https://xe695j8732v.typeform.com/to/ue64GNE3"
        target="_blank"
        onClick={onClick}
      >
        Get in touch
      </a>
    </div>
  );
}
