import { useEffect } from "react";

import { RestaurantMetadata } from "../../models/RestaurantMetadata";
import { setCSSVars } from "../../utils/css_var/SetCSSVars";

export function useRestaurantStyle(
  metadata: RestaurantMetadata | null | undefined
) {
  useEffect(() => {
    const colorTheme = metadata?.colorTheme;
    if (!colorTheme) {
      return;
    }

    const newStyles = new Map<string, string>([
      ["--background-color-rgb", colorTheme.backgroundColor.cssPropertyString],
      ["--surface-color-rgb", colorTheme.surfaceColor.cssPropertyString],
      ["--border-color-rgb", colorTheme.borderColor.cssPropertyString],
      [
        "--header-background-color-rgb",
        // colorTheme.headerBackgroundColor.cssPropertyString,
        colorTheme.backgroundColor.cssPropertyString,
      ],
      [
        "--restaurant-title-color-rgb",
        // colorTheme.restaurantTitleColor.cssPropertyString,
        colorTheme.itemTitleColor.cssPropertyString,
      ],
      [
        "--search-filter-button-color-rgb",
        // colorTheme.searchFilterButtonColor.cssPropertyString,
        colorTheme.itemPriceColor.cssPropertyString,
      ],
      [
        "--section-bar-text-color-rgb",
        // colorTheme.sectionBarTextColor.cssPropertyString,
        colorTheme.itemPriceColor.cssPropertyString,
      ],
      [
        "--section-title-color-rgb",
        colorTheme.sectionTitleColor.cssPropertyString,
      ],
      [
        "--subsection-title-color-rgb",
        colorTheme.subsectionTitleColor.cssPropertyString,
      ],
      ["--item-title-color-rgb", colorTheme.itemTitleColor.cssPropertyString],
      ["--item-price-color-rgb", colorTheme.itemPriceColor.cssPropertyString],
      [
        "--item-description-color-rgb",
        colorTheme.itemDescriptionColor.cssPropertyString,
      ],
      [
        "--play-button-background-color-rgb",
        colorTheme.playButtonBackgroundColor.cssPropertyString,
      ],
    ]);

    return setCSSVars(newStyles);
  }, [metadata?.colorTheme]);
}
