import "./RestaurantSearchPage.css";

import classNames from "classnames";
import { useEffect, useState } from "react";

import { RestaurantSearchBar } from "./RestaurantSearchBar";
import { RestaurantPageChildParams } from "../RestaurantPageChildParams";
import { RestaurantMenuItemDetailsPage } from "../item_details/RestaurantMenuItemDetailsPage";
import { BottomSheet } from "../../../components/bottom_sheet/BottomSheet";
import { RestaurantMenuItemList } from "../../../components/restaurant_menu_item_list/RestaurantMenuItemList";
import { useDocumentScrollFade } from "../../../components/scroll_fade/ScrollFade";
import { VerticalScrollingContainer } from "../../../components/vertical_scrolling_container/VerticalScrollingContainer";
import { RestaurantMenuItem } from "../../../models/RestaurantMenuItem";
import { RestaurantMenuSubsection } from "../../../models/RestaurantMenuSubsection";
import {
  AnalyticsCartEventSource,
  AnalyticsEvent,
} from "../../../services/analytics/AnalyticsEvent";
import { useAnalyticsService } from "../../../services/analytics/AnalyticsServiceContext";
import {
  INFINITE_SCROLL_FLAG,
  useFeatureFlag,
} from "../../../state/feature_flags/FeatureFlags";

const SEARCH_LOG_DEBOUNCE_TIME = 1000;

export interface RestaurantSearchPageProps extends RestaurantPageChildParams {
  search: string;
  onSearchChanged: (newSearch: string) => void;
  onDismissSearch: () => void;
  searchedItems: RestaurantMenuItem[];
  canSelectItem: (item: RestaurantMenuItem) => boolean;
}

export function RestaurantSearchPage(props: RestaurantSearchPageProps) {
  const analyticsService = useAnalyticsService();

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      if (props.search !== "") {
        analyticsService.logEvent(AnalyticsEvent.search(props.search));
      }
    }, SEARCH_LOG_DEBOUNCE_TIME);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [props.search]);

  function onAddToCart(item: RestaurantMenuItem) {
    props.addCartItem(item, AnalyticsCartEventSource.Search);
  }

  const [selectedDetailsItem, setSelectedDetailsItem] =
    useState<RestaurantMenuItem | null>(null);

  const [infiniteScrollOn] = useFeatureFlag(INFINITE_SCROLL_FLAG);
  const [topFade, , bottomFade] = useDocumentScrollFade({
    topOffset: 93,
    widthPadding: 0,
  });

  return (
    <div className="restaurant-search-container">
      <div className="restaurant-search-header-container">
        <div className="restaurant-search-bar-container">
          <RestaurantSearchBar
            search={props.search}
            onSearchChanged={props.onSearchChanged}
          />
        </div>
        <button
          className="restaurant-search-close"
          onClick={props.onDismissSearch}
        >
          Close
        </button>
      </div>
      {infiniteScrollOn && topFade}
      <VerticalScrollingContainer
        className={classNames("restaurant-search-item-container", {
          "zero-height": !infiniteScrollOn,
        })}
        scrollPadding="12px 14px 24px 14px"
      >
        <RestaurantMenuItemList
          subsections={[
            new RestaurantMenuSubsection("", null, props.searchedItems),
          ]}
          canSelectItem={props.canSelectItem}
          onItemSelected={(item) => props.setItem(item, false)}
          displaysOptions={(item) => item.addonText !== null}
          onOptionsSelected={(item) => setSelectedDetailsItem(item)}
          isInCart={props.cart.includes.bind(props.cart)}
          onAddToCart={onAddToCart}
          emptyStateText="No matching items"
          compactBehavior={(item) => item.videoURLs.length > 0}
        />
      </VerticalScrollingContainer>
      {infiniteScrollOn && bottomFade}
      <BottomSheet
        in={selectedDetailsItem !== null}
        dismiss={() => setSelectedDetailsItem(null)}
      >
        {selectedDetailsItem && (
          <RestaurantMenuItemDetailsPage item={selectedDetailsItem} />
        )}
      </BottomSheet>
    </div>
  );
}
