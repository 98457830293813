export function setCSSVars(vars: Map<string, string>): () => void {
  const previousStyles = new Map<string, string>();
  vars.forEach((value, key) => {
    const previousStyle = document.documentElement.style.getPropertyValue(key);
    if (previousStyle) {
      previousStyles.set(key, previousStyle);
    }

    document.documentElement.style.setProperty(key, value);
  });

  return () => {
    vars.forEach((value, key) => {
      const previousStyle = previousStyles.get(key);
      if (previousStyle) {
        document.documentElement.style.setProperty(key, previousStyle);
      } else {
        document.documentElement.style.removeProperty(key);
      }
    });
  };
}
