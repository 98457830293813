import "./RestaurantMenuHeader.css";

import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

import { NoErrorImage } from "../../../components/no_error_image/NoErrorImage";
import { Restaurant } from "../../../models/Restaurant";

export interface RestaurantMenuHeaderProps {
  restaurant: Restaurant;
  isFilterApplied: boolean;
  onSearchClick: () => void;
  onFilterClick: () => void;
}

export function RestaurantMenuHeader(props: RestaurantMenuHeaderProps) {
  return (
    <div className="restaurant-menu-header-container">
      <div className="restaurant-menu-header">
        <Link to="..">
          <div className="restaurant-menu-restaurant-images-container">
            <NoErrorImage
              className="restaurant-menu-header-preview-image"
              src={`https://menutest1storage.blob.core.windows.net/restaurantpreviews/${props.restaurant.id}.jpg`}
              alt={`${props.restaurant.title} restaurant preview`}
            />
            <NoErrorImage
              className="restaurant-menu-header-image"
              src={`https://menutest1storage.blob.core.windows.net/restaurantlogos/${props.restaurant.id}.jpg`}
              alt={`${props.restaurant.title} restaurant logo`}
              fallback={
                <div className="restaurant-menu-header-image-fallback" />
              }
            />
          </div>
        </Link>
        <div className="restaurant-menu-header-title-row">
          <h1 className="restaurant-menu-header-title">
            {props.restaurant.title}
          </h1>
          <div className="restaurant-menu-header-actions">
            <button
              className="restaurant-menu-header-action"
              aria-label="Search"
              onClick={props.onSearchClick}
            >
              <Icon
                className="restaurant-menu-header-search-icon"
                icon="bi:search"
              />
            </button>
            <div className="restaurant-menu-header-action-separator" />
            <button
              className="restaurant-menu-header-action"
              aria-label="Filter"
              onClick={props.onFilterClick}
            >
              <Icon
                className="restaurant-menu-header-filter-icon"
                icon="mi:filter"
              />
              {props.isFilterApplied && (
                <div className="restaurant-menu-header-filter-indicator" />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
