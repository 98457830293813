import {
  booleanLocalStorageSetting,
  objectLocalStorageSetting,
} from "./LocalStorage";

export function deprecated_onboardingSeenVideoPromoSetting(): [
  boolean,
  (newValue: boolean) => void
] {
  return booleanLocalStorageSetting("ONBOARDING_SEEN_VIDEO_PROMO");
}

export function onboardingSeenVideoPromoSettingV2(): [
  boolean,
  (newValue: boolean) => void
] {
  return booleanLocalStorageSetting("ONBOARDING_SEEN_VIDEO_PROMO_V2");
}

export function onboardingSeenSwipeUpSetting(): [
  boolean,
  (newValue: boolean) => void
] {
  return booleanLocalStorageSetting("ONBOARDING_SEEN_SWIPE_UP");
}

export function onboardingSeenSwipeLeftSetting(): [
  boolean,
  (newValue: boolean) => void
] {
  return booleanLocalStorageSetting("ONBOARDING_SEEN_SWIPE_LEFT");
}

export function onboardingTotalUserSwipes(): [
  number,
  (newValue: number) => void
] {
  return objectLocalStorageSetting("ONBOARDING_TOTAL_USER_SWIPES", 0);
}
