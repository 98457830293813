import { ForwardedRef, forwardRef } from "react";

export interface ScrimProps {
  className: string;
  onClick: () => void;
}

export const Scrim = forwardRef(
  (props: ScrimProps, ref: ForwardedRef<HTMLDivElement>) => (
    <div ref={ref} className={props.className} onClick={props.onClick} />
  )
);
