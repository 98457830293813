import { AnalyticsEvent, AnalyticsEventProperties } from "./AnalyticsEvent";
import { AnalyticsService } from "./AnalyticsService";

export class ConsoleLogAnalyticsService implements AnalyticsService {
  restaurantID: string | null = null;
  restaurantTitle: string | null = null;

  initialize() {}

  logEvent(event: AnalyticsEvent) {
    const properties: { [key: string]: any } = { ...event.properties };
    if (this.restaurantID) {
      properties.restaurantID = this.restaurantID;
    }
    if (this.restaurantTitle) {
      properties.restaurantTitle = this.restaurantTitle;
    }
    console.log(event.action, properties);
  }

  setRestaurant(id: string | null, title: string | null) {
    this.restaurantID = id;
    this.restaurantTitle = title;
  }
}
