import "./Overlay.css";

import classNames from "classnames";
import {
  MutableRefObject,
  ReactNode,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { CSSTransition } from "react-transition-group";
import { Icon } from "@iconify/react";

const TRANSITION_DURATION = 250;

export interface OverlayTextIconRequest {
  icon: string | ReactNode;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  color?: string;
  durationMillis: number;
  lastRequestDate: number | null;
}

export interface OverlayChildRequest {
  child: ReactNode;
  durationMillis: number;
  lastRequestDate: number | null;
}

export enum OverlayStyle {
  Card = 1,
  CardAutodismiss = 2,
  FullScreen = 3,
}

export type OverlayRequest = OverlayTextIconRequest | OverlayChildRequest;

export interface OverlayProps {
  request: OverlayRequest;
  backgroundColor?: string;
  style?: OverlayStyle;
}

export function Overlay(props: OverlayProps) {
  const [lastDismissDate, setLastDismissDate] = useState<number | null>(null);
  const [isShowingOverlay, setIsShowingOverlay] = useState(false);
  useEffect(() => {
    if (!props.request.lastRequestDate) {
      setIsShowingOverlay(false);
      return;
    }

    if (lastDismissDate && lastDismissDate > props.request.lastRequestDate) {
      setIsShowingOverlay(false);
      return;
    }

    const remainingTime =
      props.request.lastRequestDate + props.request.durationMillis - Date.now();
    if (remainingTime < 0) {
      setIsShowingOverlay(false);
      return;
    }

    setIsShowingOverlay(true);
    const timeoutID = setTimeout(
      () => {
        setIsShowingOverlay(false);
      },
      // https://stackoverflow.com/a/51586380 - max allowed timeout is 32-bit signed max.
      Math.min(remainingTime, 2147483647)
    );

    return () => {
      clearTimeout(timeoutID);
    };
  }, [props.request, lastDismissDate]);

  function dismiss(event?: MouseEvent) {
    event?.preventDefault();
    event?.stopPropagation();

    setLastDismissDate(Date.now());
  }

  switch (props.style ?? OverlayStyle.Card) {
    case OverlayStyle.Card:
    case OverlayStyle.CardAutodismiss:
      return (
        <CardOverlayChild
          request={props.request}
          shown={isShowingOverlay}
          backgroundColor={props.backgroundColor}
          onPointerDown={
            props.style === OverlayStyle.CardAutodismiss ? dismiss : undefined
          }
          onDismiss={props.style === OverlayStyle.Card ? dismiss : undefined}
        />
      );
    case OverlayStyle.FullScreen:
      return (
        <FullScreenOverlayChild
          request={props.request}
          shown={isShowingOverlay}
          backgroundColor={props.backgroundColor}
          onClick={dismiss}
        />
      );
  }
}

export interface CardOverlayChildProps {
  request: OverlayRequest;
  shown: boolean;
  backgroundColor?: string;
  onPointerDown: ((event: MouseEvent) => void) | undefined;
  onDismiss: (() => void) | undefined;
}

function isTextIconRequest(
  object: OverlayRequest
): object is OverlayTextIconRequest {
  return "icon" in object && "title" in object;
}

function isIconIconRequest(icon: string | ReactNode): icon is string {
  return typeof icon === "string";
}

export function CardOverlayChild(props: CardOverlayChildProps) {
  let child: ReactNode;
  if (isTextIconRequest(props.request)) {
    child = (
      <>
        {props.onDismiss !== undefined ? (
          <button className="overlay-close-icon" aria-label="Close">
            x
          </button>
        ) : null}
        {isIconIconRequest(props.request.icon) ? (
          <Icon
            icon={props.request.icon}
            className="overlay-icon"
            width="36"
            height="36"
          />
        ) : (
          props.request.icon
        )}
        <p className="overlay-text">{props.request.title}</p>
      </>
    );
  } else {
    child = props.request.child;
  }

  const ref = useRef(null);
  const style: { backgroundColor?: string; color?: string } = {
    ...(props.backgroundColor
      ? { backgroundColor: props.backgroundColor }
      : {}),
  };
  if (isTextIconRequest(props.request) && props.request.color) {
    style.color = props.request.color;
  }
  return (
    <CSSTransition
      nodeRef={ref}
      in={props.shown}
      mountOnEnter
      unmountOnExit
      timeout={TRANSITION_DURATION}
      classNames="overlay-fade-in"
    >
      <div
        ref={ref}
        className={classNames("overlay", {
          "overlay-no-tap-dismiss": props.onPointerDown === undefined,
        })}
        onPointerDown={props.onPointerDown}
      >
        <div
          className={classNames("overlay-content-container", {
            "overlay-tap-dismiss": props.onDismiss !== undefined,
          })}
          style={style}
          onClick={props.onDismiss}
        >
          {child}
        </div>
      </div>
    </CSSTransition>
  );
}

export interface FullScreenOverlayChildProps {
  request: OverlayRequest;
  shown: boolean;
  backgroundColor?: string;
  onClick: ((event: MouseEvent) => void) | undefined;
}

export function FullScreenOverlayChild(props: FullScreenOverlayChildProps) {
  let child: ReactNode;
  if (isTextIconRequest(props.request)) {
    child = (
      <>
        {isIconIconRequest(props.request.icon) ? (
          <div className="full-screen-overlay-icon-container">
            <Icon
              icon={props.request.icon}
              className="full-screen-overlay-icon"
              width="64"
              height="64"
            />
          </div>
        ) : (
          props.request.icon
        )}

        <p className="full-screen-overlay-title">{props.request.title}</p>
        <p className="full-screen-overlay-subtitle">{props.request.subtitle}</p>
      </>
    );
  } else {
    child = props.request.child;
  }

  const ref = useRef(null);
  const style: { backgroundColor?: string; color?: string } = {
    ...(props.backgroundColor
      ? { backgroundColor: props.backgroundColor }
      : {}),
  };
  if (isTextIconRequest(props.request) && props.request.color) {
    style.color = props.request.color;
  }
  return (
    <CSSTransition
      nodeRef={ref}
      in={props.shown}
      mountOnEnter
      unmountOnExit
      timeout={TRANSITION_DURATION}
      classNames="overlay-fade-in"
    >
      <div
        ref={ref}
        className="full-screen-overlay overlay"
        onClick={props.onClick}
      >
        <div className="full-screen-overlay-content-container" style={style}>
          {child}
        </div>
      </div>
    </CSSTransition>
  );
}
