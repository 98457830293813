import { Component, ErrorInfo, ReactNode } from "react";

import { ErrorView } from "../error_view/ErrorView";
import { AnalyticsEvent } from "../../services/analytics/AnalyticsEvent";
import { AnalyticsServiceContext } from "../../services/analytics/AnalyticsServiceContext";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  error: Error | undefined;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static contextType = AnalyticsServiceContext;

  public state: ErrorBoundaryState = { error: undefined };

  public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const analyticsService = this.context;
    analyticsService.logEvent(
      AnalyticsEvent.renderError(error.message, errorInfo.componentStack)
    );
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.error) {
      return <ErrorView errorDetails={this.state.error.message} />;
    }

    return this.props.children;
  }
}
