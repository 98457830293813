import { Environment, currentEnvironment } from "./Environment";

export function devAssert(condition: () => boolean, message: string) {
  if (currentEnvironment() === Environment.Development && !condition()) {
    throw new Error(message);
  }
}

export function devAssertionFailure(message: string) {
  devAssert(() => false, message);
}
