import "./RestaurantMenuItemPage.css";

import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";

import { RestaurantMenuItemPageContents } from "./RestaurantMenuItemPageContents";
import {
  generateViewModel,
  RestaurantMenuSectionViewModels,
  RestaurantMenuItemViewModel,
} from "./RestaurantMenuItemPageViewModel";
import { RestaurantMenuItemScrollIndicator } from "./RestaurantMenuItemScrollIndicator";
import {
  RestaurantMenuItemSwipeUpPromoIcon,
  RestaurantMenuItemSwipeLeftPromoIcon,
} from "./RestaurantMenuItemSwipePromo";
import { RestaurantPageChildParams } from "../RestaurantPageChildParams";
import { CartPage } from "../cart/CartPage";
import { RestaurantMenuItemDetailsPage } from "../item_details/RestaurantMenuItemDetailsPage";
import { BottomSheet } from "../../../components/bottom_sheet/BottomSheet";
import {
  Overlay,
  OverlayRequest,
  OverlayStyle,
} from "../../../components/overlay/Overlay";
import { ScrollingSectionSelectorBar } from "../../../components/scrolling_section_selector_bar/ScrollingSectionSelectorBar";
import { RestaurantMenuItem } from "../../../models/RestaurantMenuItem";
import { RestaurantMenuSection } from "../../../models/RestaurantMenuSection";
import {
  AnalyticsCartEventSource,
  AnalyticsEvent,
  AnalyticsItemEventSwipeDirection,
} from "../../../services/analytics/AnalyticsEvent";
import { useAnalyticsService } from "../../../services/analytics/AnalyticsServiceContext";
import { cartEnabled } from "../../../state/feature_flags/Cart";
import {
  onboardingSeenSwipeLeftSetting,
  onboardingSeenSwipeUpSetting,
  onboardingTotalUserSwipes,
} from "../../../state/local_storage/OnboardingStorage";

const ADDED_OVERLAY_DURATION = 2000;

export interface RestaurantMenuItemPageProps extends RestaurantPageChildParams {
  shouldShownSwipeLeftPrompt: boolean;
}

export function RestaurantMenuItemPage(props: RestaurantMenuItemPageProps) {
  const [isShowingCart, setIsShowingCart] = useState(false);
  const onShowCart = useCallback(() => setIsShowingCart(true), []);

  const [addedOverlayRequestDate, setAddedOverlayRequestDate] = useState<
    number | null
  >(null);
  const overlayRequest: OverlayRequest = {
    icon: "akar-icons:check",
    title: "Added to your order",
    durationMillis: ADDED_OVERLAY_DURATION,
    lastRequestDate: addedOverlayRequestDate,
  };

  const [swipeUpOverlayRequestDate, setSwipeUpOverlayRequestDate] = useState<
    number | null
  >(null);
  const [swipeLeftOverlayRequestDate, setSwipeLeftOverlayRequestDate] =
    useState<number | null>(null);

  const [selectedDetailsItem, setSelectedDetailsItem] =
    useState<RestaurantMenuItem | null>(null);

  const viewModel = generateViewModel(props.menu, props.searchedItems);
  const [sectionIndex, viewModelIndex] = viewModel.findItemIndex(props.item);

  // Set the section on the first appearance.
  useEffect(() => {
    const currentSectionIndex = props.menu.sections.findIndex(
      (section) => section.id === props.section?.id
    );
    if (currentSectionIndex !== sectionIndex) {
      props.setSection(props.menu.sections[sectionIndex]);
    }
  }, []);

  function setNewItemIndex(index: [number, number]) {
    props.setSection(props.menu.sections[index[0]]);
    props.setItem(
      viewModel.sections[index[0]].viewModels[index[1]].firstItem,
      true
    );
  }

  const [
    sectionIndexToRecentViewedItemIndex,
    setSectionIndexToRecentViewedItemIndex,
  ] = useState<Map<number, number>>(new Map([[sectionIndex, viewModelIndex]]));
  useEffect(() => {
    sectionIndexToRecentViewedItemIndex.set(sectionIndex, viewModelIndex);
    setSectionIndexToRecentViewedItemIndex(
      new Map(sectionIndexToRecentViewedItemIndex)
    );
  }, [sectionIndex, viewModelIndex]);

  const item = viewModel.sections[sectionIndex].viewModels[viewModelIndex];

  const nextItemIndex = viewModel.findNextItemIndex(
    sectionIndex,
    viewModelIndex
  );
  const nextItem =
    nextItemIndex !== null
      ? viewModel.sections[nextItemIndex[0]].viewModels[nextItemIndex[1]]
      : null;

  const previousItemIndex = viewModel.findPreviousItemIndex(
    sectionIndex,
    viewModelIndex
  );
  const previousItem =
    previousItemIndex !== null
      ? viewModel.sections[previousItemIndex[0]].viewModels[
          previousItemIndex[1]
        ]
      : null;

  const nextSectionItemIndex = viewModel.findNextSectionItemIndex(
    sectionIndex,
    viewModelIndex,
    sectionIndexToRecentViewedItemIndex
  );
  const nextSectionItem =
    nextSectionItemIndex !== null
      ? viewModel.sections[nextSectionItemIndex[0]].viewModels[
          nextSectionItemIndex[1]
        ]
      : null;

  const previousSectionItemIndex = viewModel.findPreviousSectionItemIndex(
    sectionIndex,
    viewModelIndex,
    sectionIndexToRecentViewedItemIndex
  );
  const previousSectionItem =
    previousSectionItemIndex !== null
      ? viewModel.sections[previousSectionItemIndex[0]].viewModels[
          previousSectionItemIndex[1]
        ]
      : null;

  const onAddToCart = useCallback((item: RestaurantMenuItem) => {
    props.addCartItem(item, AnalyticsCartEventSource.ItemView);

    setAddedOverlayRequestDate(Date.now());
  }, []);

  const analyticsService = useAnalyticsService();
  const [pageViewStart, setPageViewStart] = useState(Date.now());

  function onNextItem() {
    if (!nextItemIndex || !nextItem) {
      return;
    }
    // Dismiss the swipe up or down prompt if necessary.
    setSwipeUpOverlayRequestDate(null);
    // Increment the total amount of user swipes.
    const [userSwipes, setUserSwipes] = onboardingTotalUserSwipes();
    setUserSwipes(userSwipes + 1);

    setNewItemIndex(nextItemIndex);

    analyticsService.logEvent(
      analyticsEventForSwipe(
        AnalyticsItemEventSwipeDirection.Up,
        Date.now() - pageViewStart,
        item,
        nextItem
      )
    );
    setPageViewStart(Date.now());
  }

  function onPreviousItem() {
    if (!previousItemIndex || !previousItem) {
      return;
    }
    // Dismiss the swipe up or down prompt if necessary.
    setSwipeUpOverlayRequestDate(null);
    // Increment the total amount of user swipes.
    const [userSwipes, setUserSwipes] = onboardingTotalUserSwipes();
    setUserSwipes(userSwipes + 1);

    setNewItemIndex(previousItemIndex);

    analyticsService.logEvent(
      analyticsEventForSwipe(
        AnalyticsItemEventSwipeDirection.Down,
        Date.now() - pageViewStart,
        item,
        previousItem
      )
    );
    setPageViewStart(Date.now());
  }

  function onNextSectionItem() {
    if (!nextSectionItemIndex || !nextSectionItem) {
      return;
    }
    // Dismiss the swipe left or right prompt if necessary.
    setSwipeLeftOverlayRequestDate(null);
    // Increment the total amount of user swipes.
    const [userSwipes, setUserSwipes] = onboardingTotalUserSwipes();
    setUserSwipes(userSwipes + 1);

    setNewItemIndex(nextSectionItemIndex);

    analyticsService.logEvent(
      analyticsEventForSwipe(
        AnalyticsItemEventSwipeDirection.Right,
        Date.now() - pageViewStart,
        item,
        nextSectionItem
      )
    );
    setPageViewStart(Date.now());
  }

  function onPreviousSectionItem() {
    if (!previousSectionItemIndex || !previousSectionItem) {
      return;
    }
    // Dismiss the swipe up or down prompt if necessary.
    setSwipeLeftOverlayRequestDate(null);
    // Increment the total amount of user swipes.
    const [userSwipes, setUserSwipes] = onboardingTotalUserSwipes();
    setUserSwipes(userSwipes + 1);

    setNewItemIndex(previousSectionItemIndex);

    analyticsService.logEvent(
      analyticsEventForSwipe(
        AnalyticsItemEventSwipeDirection.Left,
        Date.now() - pageViewStart,
        item,
        previousSectionItem
      )
    );
    setPageViewStart(Date.now());
  }

  const currentItemRef = useRef(item);
  useEffect(() => {
    currentItemRef.current = item;
  }, [item]);

  useEffect(
    () => {
      const [toItemID, toItemTitle, toItemIDs, toItemTitles] =
        analyticsInfoForViewModel(item);
      analyticsService.logEvent(
        AnalyticsEvent.viewItem(toItemID, toItemTitle, toItemIDs, toItemTitles)
      );

      return () => {
        const [fromItemID, fromItemTitle, fromItemIDs, fromItemTitles] =
          analyticsInfoForViewModel(currentItemRef.current);
        analyticsService.logEvent(
          AnalyticsEvent.exitItem(
            Date.now() - pageViewStart,
            fromItemID,
            fromItemTitle,
            fromItemIDs,
            fromItemTitles
          )
        );
      };
    },
    [] // Only log for page entrances/exits.
  );

  const [sectionOffset, setSectionOffset] = useState(0);
  function onItemOffset(leftRightOffset: number, upDownOffset: number) {
    if (
      upDownOffset < 0 &&
      nextItemIndex &&
      nextItemIndex[0] !== sectionIndex
    ) {
      setSectionOffset(-upDownOffset);
    } else if (
      upDownOffset > 0 &&
      previousItemIndex &&
      previousItemIndex[0] !== sectionIndex
    ) {
      setSectionOffset(-upDownOffset);
    } else if (
      leftRightOffset < 0 &&
      nextSectionItemIndex &&
      nextSectionItemIndex[0] !== sectionIndex
    ) {
      setSectionOffset(-leftRightOffset);
    } else if (
      leftRightOffset > 0 &&
      previousSectionItemIndex &&
      previousSectionItemIndex[0] !== sectionIndex
    ) {
      setSectionOffset(-leftRightOffset);
    } else {
      setSectionOffset(0);
    }
  }

  function onSectionSelected(section: RestaurantMenuSection) {
    const newSectionIndex = props.menu.sections.findIndex(
      (menuSection) => menuSection.id === section.id
    );
    const index = viewModel.findBestItemIndexForSection(newSectionIndex);
    setNewItemIndex(index);
  }

  useEffect(() => {
    const [seenSwipeUp, setSeenSwipeUp] = onboardingSeenSwipeUpSetting();
    if (!seenSwipeUp) {
      setSwipeUpOverlayRequestDate(Date.now());
      setSeenSwipeUp(true);
      analyticsService.logEvent(AnalyticsEvent.shownSwipeUpPromo());
      return;
    }

    const [seenSwipeLeft, setSeenSwipeLeft] = onboardingSeenSwipeLeftSetting();
    const isShowingSwipeUpPromo = swipeUpOverlayRequestDate !== null;
    const canSwipeLeft =
      nextSectionItemIndex !== null || previousSectionItemIndex !== null;
    if (
      !seenSwipeLeft &&
      !isShowingSwipeUpPromo &&
      canSwipeLeft &&
      props.shouldShownSwipeLeftPrompt
    ) {
      setSwipeLeftOverlayRequestDate(Date.now());
      setSeenSwipeLeft(true);
      analyticsService.logEvent(AnalyticsEvent.shownSwipeLeftPromo());
    }
  }, [props.item, props.shouldShownSwipeLeftPrompt]);

  const swipeUpOverlayRequest: OverlayRequest = {
    icon: <RestaurantMenuItemSwipeUpPromoIcon />,
    title: "Swipe up or down to view more items",
    subtitle: "Tap anywhere to dismiss",
    color: "white",
    durationMillis: Number.MAX_SAFE_INTEGER,
    lastRequestDate: swipeUpOverlayRequestDate,
  };
  const swipeLeftOverlayRequest: OverlayRequest = {
    icon: <RestaurantMenuItemSwipeLeftPromoIcon />,
    title: "Swipe left or right to change sections",
    subtitle: "Tap anywhere to dismiss",
    color: "white",
    durationMillis: Number.MAX_SAFE_INTEGER,
    lastRequestDate: swipeLeftOverlayRequestDate,
  };

  const overlayElement = (
    <>
      <Overlay request={overlayRequest} style={OverlayStyle.CardAutodismiss} />
      <Overlay
        request={swipeUpOverlayRequest}
        style={OverlayStyle.FullScreen}
      />
      <Overlay
        request={swipeLeftOverlayRequest}
        style={OverlayStyle.FullScreen}
      />
    </>
  );

  return (
    <div className="full-page restaurant-menu-item-page">
      <RestaurantMenuItemPageContents
        item={item}
        nextItem={nextItem}
        previousItem={previousItem}
        nextSectionItem={nextSectionItem}
        previousSectionItem={previousSectionItem}
        onNextItem={onNextItem}
        onPreviousItem={onPreviousItem}
        onNextSectionItem={onNextSectionItem}
        onPreviousSectionItem={onPreviousSectionItem}
        onItemOffset={onItemOffset}
        cart={props.cart}
        addCartItem={onAddToCart}
        onShowCart={onShowCart}
        displaysOptions={(item) => item.addonText !== null}
        onOptionsSelected={(item) => setSelectedDetailsItem(item)}
        totalUserNumberOfSwipes={onboardingTotalUserSwipes()[0]}
        overlayElement={overlayElement}
      />

      <div className="restaurant-menu-item-header-background">
        <div className="restaurant-menu-item-header-container">
          <button aria-label="Back" onClick={() => props.setItem(null, false)}>
            <Icon
              className="restaurant-menu-icon-back-button"
              icon="akar-icons:chevron-left"
            />
          </button>
          {props.search !== null ? (
            <p className="restaurant-menu-item-search-heading">
              Results for "{props.search}"
            </p>
          ) : (
            props.section !== null && (
              <ScrollingSectionSelectorBar
                sections={props.menu.sections}
                selectedSection={props.section}
                onSectionSelected={onSectionSelected}
                offset={sectionOffset}
                showScrollFade={false}
                widthReduction={50}
              />
            )
          )}
        </div>
      </div>
      <RestaurantMenuItemScrollIndicator
        numberOfItems={viewModel.sections[sectionIndex].viewModels.length}
        selectedIndex={viewModelIndex}
      />
      <BottomSheet
        in={selectedDetailsItem !== null}
        dismiss={() => setSelectedDetailsItem(null)}
      >
        {selectedDetailsItem && (
          <RestaurantMenuItemDetailsPage item={selectedDetailsItem} />
        )}
      </BottomSheet>
      {cartEnabled() && (
        <BottomSheet
          in={isShowingCart}
          expanded={isShowingCart}
          onExpand={() => {}}
          dismiss={() => setIsShowingCart(false)}
        >
          <CartPage {...props} expanded={true} />
        </BottomSheet>
      )}
    </div>
  );
}

function analyticsEventForSwipe(
  swipeDirection: AnalyticsItemEventSwipeDirection,
  pageViewTime: number,
  fromViewModel: RestaurantMenuItemViewModel,
  toViewModel: RestaurantMenuItemViewModel
) {
  const [fromItemID, fromItemTitle, fromItemIDs, fromItemTitles] =
    analyticsInfoForViewModel(fromViewModel);
  const [toItemID, toItemTitle, toItemIDs, toItemTitles] =
    analyticsInfoForViewModel(toViewModel);

  return AnalyticsEvent.swipeItem(
    swipeDirection,
    pageViewTime,
    fromItemID,
    fromItemTitle,
    fromItemIDs,
    fromItemTitles,
    toItemID,
    toItemTitle,
    toItemIDs,
    toItemTitles
  );
}

function analyticsInfoForViewModel(
  viewModel: RestaurantMenuItemViewModel
): [
  string | undefined,
  string | undefined,
  string[] | undefined,
  string[] | undefined
] {
  switch (viewModel.type) {
    case "items":
      return [
        undefined,
        undefined,
        viewModel.items().map((item) => item.id),
        viewModel.items().map((item) => item.title),
      ];
    case "video":
      return [viewModel.item.id, viewModel.item.title, undefined, undefined];
  }
}
