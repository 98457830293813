import "./ScrollFade.css";

import classNames from "classnames";
import {
  MutableRefObject,
  ReactNode,
  UIEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

const SCROLL_FADE_SHOW_THRESHOLD = 1;

/** Returns onScroll, top, right, bottom, left fades to use. */
export function useScrollFade(
  ref: MutableRefObject<HTMLElement | null>,
  isHeader: boolean,
  dependencyList: any[] = []
): [(event: UIEvent) => void, ReactNode, ReactNode, ReactNode, ReactNode] {
  const [topFadeShown, setTopFadeShown] = useState(false);
  const [rightFadeShown, setRightFadeShown] = useState(false);
  const [bottomFadeShown, setBottomFadeShown] = useState(false);
  const [leftFadeShown, setLeftFadeShown] = useState(false);

  function setFadeState(element: HTMLElement) {
    const showTopFade = element.scrollTop > SCROLL_FADE_SHOW_THRESHOLD;
    if (showTopFade !== topFadeShown) {
      setTopFadeShown(showTopFade);
    }

    const showRightFade =
      element.scrollLeft + element.clientWidth <
      element.scrollWidth - SCROLL_FADE_SHOW_THRESHOLD;
    if (showRightFade !== rightFadeShown) {
      setRightFadeShown(showRightFade);
    }

    const showBottomFade =
      element.scrollTop + element.clientHeight <
      element.scrollHeight - SCROLL_FADE_SHOW_THRESHOLD;
    if (showBottomFade !== bottomFadeShown) {
      setBottomFadeShown(showBottomFade);
    }

    const showLeftFade = element.scrollLeft > SCROLL_FADE_SHOW_THRESHOLD;
    if (showLeftFade !== leftFadeShown) {
      setLeftFadeShown(showLeftFade);
    }
  }

  function onScroll(event: UIEvent) {
    const target = event.target as HTMLElement;
    setFadeState(target);
  }

  useLayoutEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }
    setFadeState(element);
  }, [ref.current].concat(dependencyList));

  return [
    onScroll,
    <div
      className={classNames("fade-overlay scroll-top-fade", {
        "fade-active": topFadeShown,
        header: isHeader,
      })}
    ></div>,
    <div
      className={classNames("fade-overlay scroll-right-fade", {
        "fade-active": rightFadeShown,
        header: isHeader,
      })}
    ></div>,
    <div
      className={classNames("fade-overlay scroll-bottom-fade", {
        "fade-active": bottomFadeShown,
        header: isHeader,
      })}
    ></div>,
    <div
      className={classNames("fade-overlay scroll-left-fade", {
        "fade-active": leftFadeShown,
        header: isHeader,
      })}
    ></div>,
  ];
}

export interface UseDocumentScrollFadeProps {
  topOffset: number;
  widthPadding: number;
}

export function useDocumentScrollFade(
  props: UseDocumentScrollFadeProps
): [ReactNode, ReactNode, ReactNode, ReactNode] {
  const topFadeRef = useRef<HTMLDivElement>(null);
  const [topFadeShown, setTopFadeShown] = useState(false);
  const [rightFadeShown, setRightFadeShown] = useState(false);
  const [bottomFadeShown, setBottomFadeShown] = useState(false);
  const [leftFadeShown, setLeftFadeShown] = useState(false);

  function setFadeStateFromDocument() {
    // CAUTION: Avoid using xFadeShown variables here without adding them to
    // the `useEffect` dependency list.
    const element = document.documentElement;
    const topFade = topFadeRef.current;
    const topFadeLocation = topFade?.getBoundingClientRect().top ?? 0;
    const showTopFade =
      element.scrollTop > SCROLL_FADE_SHOW_THRESHOLD &&
      topFadeLocation < props.topOffset + 1;
    setTopFadeShown(showTopFade);

    const showRightFade =
      element.scrollLeft + element.clientWidth <
      element.scrollWidth - SCROLL_FADE_SHOW_THRESHOLD;
    setRightFadeShown(showRightFade);

    const showBottomFade =
      element.scrollTop + element.clientHeight <
      element.scrollHeight - SCROLL_FADE_SHOW_THRESHOLD;
    setBottomFadeShown(showBottomFade);

    const showLeftFade = element.scrollLeft > SCROLL_FADE_SHOW_THRESHOLD;
    setLeftFadeShown(showLeftFade);
  }

  function onScroll() {
    setFadeStateFromDocument();
  }

  useEffect(() => {
    document.addEventListener("scroll", onScroll);
    return () => document.removeEventListener("scroll", onScroll);
  }, []);

  return [
    <div
      ref={topFadeRef}
      className={classNames("fade-overlay scroll-top-fade sticky-top", {
        "fade-active": topFadeShown,
      })}
      style={{
        top: `${props.topOffset}px`,
        marginLeft: `-${props.widthPadding}px`,
        marginRight: `-${props.widthPadding}px`,
      }}
    ></div>,
    <div
      className={classNames("fade-overlay scroll-right-fade sticky-right", {
        "fade-active": rightFadeShown,
      })}
    ></div>,
    <div
      className={classNames("fade-overlay scroll-bottom-fade sticky-bottom", {
        "fade-active": bottomFadeShown,
      })}
      style={{
        marginLeft: `-${props.widthPadding}px`,
        marginRight: `-${props.widthPadding}px`,
      }}
    ></div>,
    <div
      className={classNames("fade-overlay scroll-left-fade sticky-left", {
        "fade-active": leftFadeShown,
      })}
    ></div>,
  ];
}
