import { RestaurantMenuFilter, filterMenuItems } from "./RestaurantMenuFilter";
import { RestaurantMenuItem } from "./RestaurantMenuItem";

export class RestaurantMenuSubsection {
  constructor(
    readonly id: string,
    readonly title: string | null,
    readonly items: RestaurantMenuItem[]
  ) {}

  applyingFilters(filters: RestaurantMenuFilter[]): RestaurantMenuSubsection {
    return new RestaurantMenuSubsection(
      this.id,
      this.title,
      filterMenuItems(filters, this.items)
    );
  }
}
