import "./RestaurantMenuItemNonVideoList.css";

import { RestaurantMenuItemNonVideoRow } from "./RestaurantMenuItemNonVideoRow";
import { RestaurantMenuItem } from "../../../models/RestaurantMenuItem";

interface RestaurantMenuItemNonVideoListProps {
  items: RestaurantMenuItem[];
  hasAddons: (item: RestaurantMenuItem) => boolean;
  onAddonsTapped: ((item: RestaurantMenuItem) => void) | null;
}

export function RestaurantMenuItemNonVideoList(
  props: RestaurantMenuItemNonVideoListProps
) {
  return (
    <div className="restaurant-menu-item-nonvideo-list">
      {props.items.map((item) => (
        <RestaurantMenuItemNonVideoRow
          key={item.id}
          item={item}
          onAddonsTapped={props.hasAddons(item) ? props.onAddonsTapped : null}
        />
      ))}
    </div>
  );
}
