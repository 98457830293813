import amplitude, { AmplitudeClient } from "amplitude-js";

import {
  AnalyticsEvent,
  AnalyticsCartEventProperties,
  AnalyticsEventAction,
} from "./AnalyticsEvent";
import { AnalyticsService } from "./AnalyticsService";

export class AmplitudeAnalyticsService implements AnalyticsService {
  instance: AmplitudeClient | null = null;

  restaurantID: string | null = null;
  restaurantTitle: string | null = null;

  appVersion: string | null = null;

  initialize() {
    const apiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
    if (!apiKey) {
      throw new Error("Required Amplitude API key not set!");
    }

    const appVersion = process.env.REACT_APP_VERSION;
    if (!appVersion) {
      throw new Error("Required app version not set!");
    }
    this.appVersion = appVersion;

    this.instance = amplitude.getInstance();
    // @types/amplitude-js hates this one little trick!!!
    this.instance.init(apiKey, undefined, { transport: "beacon" } as any);
  }

  logEvent(event: AnalyticsEvent) {
    const instance = this.instance;
    if (!instance) {
      throw new Error(
        "Amplitude analytics service not initialized before logging!"
      );
    }

    const properties: { [key: string]: any } = { ...event.properties };
    if (this.restaurantID) {
      properties.restaurantID = this.restaurantID;
    }
    if (this.restaurantTitle) {
      properties.restaurantTitle = this.restaurantTitle;
    }
    properties.appVersion = this.appVersion;

    instance.logEvent(event.action, properties);

    switch (event.action) {
      case AnalyticsEventAction.AddToCart: {
        const properties = event.properties as AnalyticsCartEventProperties;
        if (!properties.itemPrice) {
          throw new Error("Unexpected properties for add to cart analytics");
        }
        const eventProperties = {
          itemTitle: properties.itemTitle,
          totalCartValue: properties.totalCartValue,
        };
        const revenue = new amplitude.Revenue()
          .setProductId(properties.itemID)
          .setPrice(properties.itemPrice / 100)
          .setQuantity(properties.itemQuantity)
          .setEventProperties(eventProperties);
        instance.logRevenueV2(revenue);
        break;
      }
      case AnalyticsEventAction.RemoveFromCart: {
        const properties = event.properties as AnalyticsCartEventProperties;
        if (!properties.itemPrice) {
          throw new Error("Unexpected properties for add to cart analytics");
        }
        const eventProperties = {
          itemTitle: properties.itemTitle,
          totalCartValue: properties.totalCartValue,
        };
        const revenue = new amplitude.Revenue()
          .setProductId(properties.itemID)
          .setPrice(-properties.itemPrice / 100)
          .setQuantity(properties.itemQuantity)
          .setEventProperties(eventProperties);
        instance.logRevenueV2(revenue);
        break;
      }
      default:
        break;
    }
  }

  setRestaurant(id: string | null, title: string | null) {
    this.restaurantID = id;
    this.restaurantTitle = title;
  }
}
