import classNames from "classnames";
import {
  DetailedHTMLProps,
  ForwardedRef,
  ImgHTMLAttributes,
  ReactElement,
  ReactNode,
  forwardRef,
  useState,
} from "react";

export interface NoErrorImageProps
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  fallback?: ReactNode;
  container?: (children: ReactNode, hidden: boolean) => ReactElement | null;
}

// Accept any props that `img` would.
export const NoErrorImage = forwardRef(
  (props: NoErrorImageProps, ref: ForwardedRef<HTMLImageElement>) => {
    const [isShowing, setIsShowing] = useState(true);

    const { container, ...imgProps } = props;
    const contents = (
      <>
        {!isShowing && props.fallback}
        <img
          ref={ref}
          {...imgProps}
          className={classNames(props.className, { hidden: !isShowing })}
          onError={() => {
            setIsShowing(false);
          }}
        />
      </>
    );

    if (container) {
      return container(contents, !isShowing);
    } else {
      return contents;
    }
  }
);
