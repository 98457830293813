import { useLocation } from "react-router";

import { AnalyticsEvent } from "../../services/analytics/AnalyticsEvent";
import { useAnalyticsService } from "../../services/analytics/AnalyticsServiceContext";

export function PageViewAnalyticsPage(props: {}) {
  const location = useLocation();
  const analyticsService = useAnalyticsService();
  analyticsService.logEvent(
    AnalyticsEvent.viewPage(
      location.pathname,
      location.search,
      window.location.hostname
    )
  );

  return <></>;
}
