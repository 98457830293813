import "./RestaurantFilterItemRow.css";

import classNames from "classnames";
import { Icon } from "@iconify/react";

import { RestaurantMenuFilter } from "../../../models/RestaurantMenuFilter";

export interface RestaurantFilterItemRowProps {
  filter: RestaurantMenuFilter;
  onFilterClick: (filter: RestaurantMenuFilter) => void;
  isSelected: boolean;
}

export function RestaurantFilterItemRow(props: RestaurantFilterItemRowProps) {
  return (
    <button
      onClick={() => props.onFilterClick(props.filter)}
      className="restaurant-filters-item"
    >
      <p className="restaurant-filters-item-title">{props.filter.title}</p>
      <div
        className={classNames("restaurant-filters-item-checkbox", {
          "restaurant-filters-item-checkbox-checked": props.isSelected,
        })}
      >
        {props.isSelected && (
          <Icon
            icon="akar-icons:check"
            className="restaurant-filters-item-checkbox-icon"
          />
        )}
      </div>
    </button>
  );
}
